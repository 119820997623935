import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Dimmer, Form, Header, Loader, Modal } from 'semantic-ui-react';
import { UserRoles, validateHasValue, validateString, toHumanDate, toHumanTime } from 'common-lib';
import { createUser, deleteUser, fetchUser, updateUser, updateUserPassword } from '~store/actions/structureActions';
import { signAsUser } from '~store/actions/loginActions';
import { fetchUserAuths } from '~store/actions/userStatsActions';
import { DataTable, EditForm, ErrorSegment, FlexGrid } from '~components';
import { addError } from '~store/actions/errorActions';

const TABLE_STORE_KEY = '$$Mon.Admin.UserEdit';

const USER_ROLE_OPTIONS = [
	{
		key: UserRoles.ADMIN,
		text: `Администратор (${UserRoles.ADMIN})`,
		value: UserRoles.ADMIN,
	},
	{
		key: UserRoles.SUPPORT,
		text: `Саппорт (${UserRoles.SUPPORT})`,
		value: UserRoles.SUPPORT,
	},
	{
		key: UserRoles.ROBOT,
		text: `Робот (${UserRoles.ROBOT})`,
		value: UserRoles.ROBOT,
	},
	{
		key: UserRoles.CHIEF,
		text: `Директор по торговле (${UserRoles.CHIEF})`,
		value: UserRoles.CHIEF,
	},
	{
		key: UserRoles.MANAGER,
		text: `Менеджер / ДМ (${UserRoles.MANAGER})`,
		value: UserRoles.MANAGER,
	},
];

const MIN_PASSWORD_LENGTH = 5;

interface Props {
	me: any;
	history: any;
	addError: any;
	match: any;
	location: any;
	structure: any;
	createUser: any;
	fetchUser: any;
	updateUser: any;
	deleteUser: any;
	updateUserPassword: any;
	signAsUser: any;
}

interface State {
	error?: string;
	isNewItem?: boolean;
	editId?: number;
	isRequesting?: boolean;
	isShowEditPasswordModal?: boolean;
	userData?: any;
	passwordData?: any;
	userAuthTableData?: any;
	metaFields?: any;
}

class AdminUserEditPage extends React.Component<Props, State>
{
	static propTypes = {
		me: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		structure: PropTypes.object.isRequired,
		createUser: PropTypes.func.isRequired,
		updateUser: PropTypes.func.isRequired,
		deleteUser: PropTypes.func.isRequired,
		updateUserPassword: PropTypes.func.isRequired,
		signAsUser: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		let editId = props.match.params.userId;
		const isNewItem = editId === '+';
		if (!isNewItem) {
			editId = Number(editId);
			if (String(editId) !== props.match.params.userId) {
				this.state = { error: 'Номер записи указан с ошибкой' };
				return;
			}
		}
		this.state = {
			isRequesting: true,
			isShowEditPasswordModal: false,
			passwordData: {},
			isNewItem,
			editId,
		};
	}

	async componentDidMount() {
		if (this.state.error) return undefined;
		if (this.state.isNewItem) return this.setState({
			isRequesting: false,
			...this.initMeta(null),
		});
		await Promise.all([
			this.props.fetchUser(this.state.editId, true),
			fetchUserAuths(this.state.editId),
		])
			.then(data => {
				const [userData, userAuths] = data;
				// маппинг userAuths
				const userAuthTableData = userAuths && userAuths.map(row => {
					const date = new Date(row.createdAt);
					return {
						_date: toHumanDate(date),
						_time: toHumanTime(date),
						...row,
					};
				});
				this.setState({
					isRequesting: false,
					userAuthTableData,
					...this.initMeta(userData),
				});
			});
	}

	initMeta = (userData: any) => {
		const { me } = this.props;
		const readOnly = me.currentUser.role !== UserRoles.ADMIN && me.currentUser.role !== UserRoles.SUPPORT;
		const { editId, isNewItem } = this.state;
		if (!isNewItem) {
			if (!userData || userData.id !== editId) {
				this.setState({ error: 'Запись для редактирования не существует' });
				return;
			}
		}
		const metaFields: any[] = [];
		if (!isNewItem) {
			metaFields.push({
				key: 'id',
				title: 'Id (только чтение)',
				readOnly: true,
			});
		}
		// руководитель и магазин если есть
		const { authCredential, ...rawUserData } = userData || {};
		return {
			userData: {
				...rawUserData,
				username: authCredential?.username,
			},
			metaFields: [
				...metaFields,
				{
					key: 'externalId',
					title: 'Внешний ID',
					readOnly,
				},
				{
					key: 'lastName',
					title: 'Фамилия',
					readOnly,
				},
				{
					key: 'firstName',
					title: <label>Имя <small>(обязательное)</small></label>,
					validate: validateString(2, 50),
					placeholder: 'Длина от 2 до 50',
					readOnly,
				},
				{
					key: 'middleName',
					title: 'Отчество',
					readOnly,
				},
				{
					key: 'role',
					title: <label>Роль <small>(обязательное)</small></label>,
					type: 'select',
					options: USER_ROLE_OPTIONS,
					validate: validateHasValue(),
					onChangeFieldValue: values => {
						delete values._tmSupervisorId;
						delete values._upfSupervisorId;
						delete values._shopId;
						return values;
					},
					readOnly,
				},
				{
					key: 'username',
					title: <label>Логин <small>(обязательное)</small></label>,
					validate: validateString(3, 50),
					placeholder: 'Длина от 3 до 50',
					readOnly,
				},
				{
					key: '$key',
					condition: () => !isNewItem && !readOnly,
					content: 'Обновить пароль',
					type: 'button',
					onClick: this.handleShowEditPasswordModal,
				},
				{
					key: 'key',
					condition: () => isNewItem,
					title: <label>Пароль <small>(обязательное)</small></label>,
					validate: validateString(MIN_PASSWORD_LENGTH, 50),
					placeholder: `Длина от ${MIN_PASSWORD_LENGTH} до 50`,
					readOnly,
				},
				{
					key: 'email',
					title: 'E-mail',
					icon: 'at',
					readOnly,
				},
				{
					key: 'phone',
					title: 'Телефон',
					icon: 'phone',
					readOnly,
				},
				{
					key: 'comment',
					title: 'Комментарий',
					readOnly,
				},
			],
		};
	}

	userAuthTableHeaders = {
		createdAt: {
			title: 'Время входа',
			viewFunc: row => (
				<span>
					{row._date}
					&nbsp;
					<small>
						{row._time}
                    </small>
                </span>
			),
		},
		ipAddress: {
			title: 'IP адрес',
		},
	};

	// TODO разобраться что тут происходит
	handleChange = (values, fieldMeta) => {
	  if (fieldMeta.key === 'role') {
	    delete values._upfSupervisorId;
	    delete values._tmSupervisorId;
	    delete values._shopId;
	  }
	}

	handleShowEditPasswordModal = () => {
		this.setState({ isShowEditPasswordModal: true });
	}

	handleEditPassword = (e, { name, value }: any) => {
		this.setState(({ passwordData }) => {
			passwordData[name] = value;
			passwordData.valid = passwordData.password1 && passwordData.password1 === passwordData.password2
				&& passwordData.password1.length >= MIN_PASSWORD_LENGTH;
			return { passwordData };
		});
	}

	handleSavePassword = () => {
		this.setState({ isRequesting: true });
		this.props.updateUserPassword(this.state.editId, this.state.passwordData.password1)
			.finally(() => this.setState({
				isRequesting: false,
				isShowEditPasswordModal: false,
			}));
	}

	handleSave = formData => {
		const { isNewItem, userData } = this.state;
		const { users, shops } = this.props.structure;
		const newUserData = Object.assign({}, userData, formData);
		// TODO: need validate formData
		this.setState({ isRequesting: true }, async () => {
			const promise = isNewItem
				? this.props.createUser(newUserData)
				: this.props.updateUser(userData.id, newUserData);
			await promise
				.then(() => this.gotoUsers())
				.catch(error => {
					const args = error.getArgs?.();
					if (args.error) {
						this.props.addError({ error: args.error, canClose: true });
					} else {
						this.props.addError({
							error: 'Ошибка сохранения пользователя: ' + JSON.stringify(args || error),
							canClose: true,
						});
					}
					this.setState({ isRequesting: false });
				});
		});
	}

	handleDelete = async () => {
		const { userData } = this.state;
		await this.props.deleteUser(userData.id);
		this.gotoUsers();
	}

	handleSignAsUser = async () => {
		this.props.signAsUser({ secondaryUserId: this.props.match.params.userId * 1, lastUrl: this.props.location.pathname })
			.then(() => window.location.replace('/'));
	}

	gotoUsers = () => this.props.history.push('/admin/users');

	render() {
		const { isNewItem } = this.state;
		const title = isNewItem ? 'Создание пользователя' : 'Редактирование пользователя';
		const { error } = this.state;
		const hasError = !!error;
		const readOnly = this.props.me.currentUser.role !== UserRoles.ADMIN
			&& this.props.me.currentUser.role !== UserRoles.SUPPORT;
		if (hasError) {
			return (
				<>
					<Header as="h3" icon="user" content={title} />
					{hasError && <ErrorSegment>{error}</ErrorSegment>}
				</>
			);
		}
		const { userAuthTableData } = this.state;
		const isNotMe = this.props.match.params.userId * 1 !== this.props.me.currentUser.userId;
		return (
			<>
				{this.state.isRequesting ? (
					<Dimmer active inverted>
						<Loader inverted>Загрузка данных...</Loader>
					</Dimmer>
				) : null}
				{this.state.metaFields ? (
					<FlexGrid>
						<FlexGrid.Item>
							<EditForm
								title={title}
								icon="user"
								readOnly={readOnly}
								metaFields={this.state.metaFields}
								itemData={this.state.userData}
								onChange={this.handleChange}
								onSave={this.handleSave}
								onCancel={this.gotoUsers}
								onDelete={!isNewItem ? this.handleDelete : undefined}
							/>
						</FlexGrid.Item>
						{!isNewItem && (
							<FlexGrid.Item>
								<div style={{ marginBottom: '20px' }}>
									<Button
										positive
										onClick={() => this.handleSignAsUser()}
										disabled={!isNotMe}
										title={isNotMe ? null : 'Вы не можете войти повторно под своей же УЗ'}
									>
										Войти под пользователем
									</Button>
								</div>
								{userAuthTableData ? (
									<>
										Выводится последние 100 записей.
										<DataTable
											storeKey={TABLE_STORE_KEY}
											title="История входов в систему"
											icon="history"
											tableHeaders={this.userAuthTableHeaders}
											tableData={userAuthTableData}
											defaultSortColumn="createdAt"
											defaultSortDirection="descending"
										/>
									</>
								) : (
									<>
										Нет данных об истории авторизации пользователя.
									</>
								)}
							</FlexGrid.Item>
						)}
					</FlexGrid>
				) : null}
				<Modal
					open={this.state.isShowEditPasswordModal}
					size="small"
					closeIcon
					onClose={() => this.setState({ isShowEditPasswordModal: false })}
				>
					<Modal.Header>
						<h2>Укажите новый пароль</h2>
					</Modal.Header>
					<Modal.Content>
						<Form loading={!!this.state.isRequesting} error={!!this.state.error} onSubmit={this.handleSavePassword}>
							<p>
								Минимальная длина пароля должна быть не менее&nbsp;
								{MIN_PASSWORD_LENGTH}
								&nbsp;символов.
							</p>
							<Form.Input label="Новый пароль" type="password" name="password1" onChange={this.handleEditPassword} />
							<Form.Input label="Еще раз" type="password" name="password2" onChange={this.handleEditPassword} />
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<Form.Button positive onClick={this.handleSavePassword} disabled={!this.state.passwordData.valid}>
							Обновить пароль
						</Form.Button>
					</Modal.Actions>
				</Modal>
			</>
		);
	}
}

export default connect(
	({ me, structure }: any) => ({ me, structure }),
	{
		createUser,
		fetchUser,
		updateUser,
		deleteUser,
		updateUserPassword,
		signAsUser,
		addError,
	},
)(AdminUserEditPage);
