import React, { useState } from 'react';
import { PageInfo } from 'common-lib';
import { DataTableWithPageInfo, Spinner } from '~components';
import IncidentTypesFetcher from '~api/IncidentTypesFetcher';
import { fillIncidentTypes, INCIDENT_TYPES_TABLE_HEADERS } from '../incidentsAttrs';

const incidentTypesFetcher = new IncidentTypesFetcher();

type Props = {
	history: any
	location: any
}

export default function AdminIncidentTypesTablePage(props: Props) {
	const [{ tableData, checkQueryString, isRequesting }, setTableData]: any = useState({});

	const pageInfo = PageInfo.parseFromString(props.location.search, { order: 'id:desc' });

	if (tableData === undefined || pageInfo.toQueryString() !== checkQueryString) {
		if (!isRequesting) {
			setTableData({ tableData, checkQueryString, isRequesting: true });
			incidentTypesFetcher.getList(pageInfo)
				.then(fillIncidentTypes)
				.then((tableData: any) => setTableData({
					tableData: tableData || null,
					checkQueryString: pageInfo.toQueryString(),
				}));
		}
	}

	return (
		<>
			{ tableData ? (
				<DataTableWithPageInfo
					searchable
					icon="wrench"
					title="Типы инцидентов"
					headers={ INCIDENT_TYPES_TABLE_HEADERS }
					rows={ tableData.data }
					pageInfo={ tableData.pageInfo }
					onClickItem={ itemId => props.history.push(`${ props.location.pathname }/${ itemId }`) }
					onAddItem={ () => props.history.push(`${ props.location.pathname }/+`) }
					onChangePageOptions={ options => {
						const pageInfo1 = PageInfo.parseFromString(props.location.search);
						pageInfo1.update(options);
						props.history.push(`${ props.location.pathname }?${ pageInfo1.toQueryString() }`);
					} }
				/>
			) : tableData !== undefined ? (
				<h3>Нет данных</h3>
			) : null }
			{ isRequesting ? <Spinner /> : null }
		</>
	);
}
