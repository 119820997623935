import { fetchGetThenJson, fetchPostThenJson, fetchPut } from '~tools/fetchBackend';

/* create task */
export async function createChecklistTask(itemData) {
	return fetchPostThenJson('checklist_tasks', itemData);
}

/* read analytics list */
export async function loadChecklistTasksAnalyticsData() {
	return fetchGetThenJson('checklist_tasks');
}

/* read task list */
export async function getTChecklistasksList(shopId) {
	return fetchGetThenJson(shopId ? `checklist_tasks?shopId=${shopId}` : 'checklist_tasks');
}

/* read task */
export async function loadChecklistTaskItemData(checklistTaskId, shopId) {
	return fetchGetThenJson(shopId ? `checklist_tasks/${checklistTaskId}?shopId=${shopId}` : `checklist_tasks/${checklistTaskId}`);
}

/* update - execute task */
export async function execChecklistTask(checklistTaskId, data) {
	return fetchPut(`checklist_tasks/execute/${checklistTaskId}`, data);
}

/* update - reject task */
export async function rejectChecklistTask(checklistTaskId, data) {
	return fetchPut(`checklist_tasks/reject/${checklistTaskId}`, data);
}
