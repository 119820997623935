import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { UserRoles, PageInfo } from 'common-lib';
import { DataTableWithPageInfo, Spinner } from '~components';
import { getUsersList } from '~api/usersApi';
import { fillTableData, TABLE_HEADERS } from '../usersAttrs';

export default function AdminUsersTablePage(props) {
	const [{ tableData, checkQueryString, isTableDataRequesting }, setTableData] = useState({} as any);
	const meConfig = useSelector((state: any) => state.me.currentUser);

	const pageInfo = PageInfo.parseFromString(props.location.search);
	pageInfo.meta = new Set(['username', 'shoproles']);

	if (!tableData || pageInfo.toQueryString() !== checkQueryString) {
		if (!isTableDataRequesting) {
			setTableData({ tableData, checkQueryString, isTableDataRequesting: true });
			getUsersList(pageInfo).then(json => {
				const newTableData = fillTableData(json);
				setTableData({
					tableData: newTableData,
					checkQueryString: pageInfo.toQueryString(),
				});
			});
		}
	}

	return (
		<>
			{tableData ? (
				<DataTableWithPageInfo
					searchable
					icon="users"
					title="Пользователи"
					headers={TABLE_HEADERS}
					rows={tableData.rows}
					pageInfo={tableData.pageInfo}
					onClickItem={itemId => props.history.push(`${props.location.pathname}/${itemId}`)}
					onAddItem={[UserRoles.ADMIN, UserRoles.SUPPORT].includes(meConfig.role) ? () => {
						props.history.push(`${props.location.pathname}/+`)
					} : null}
					onChangePageOptions={options => {
						const pageInfo1 = PageInfo.parseFromString(props.location.search);
						pageInfo1.update(options);
						props.history.push(`${props.location.pathname}?${pageInfo1.toQueryString()}`);
					}}
				/>
			) : null}
			{isTableDataRequesting ? <Spinner /> : null}
		</>
	)
}

AdminUsersTablePage.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
}
