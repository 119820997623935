import BaseFetcher from './BaseFetcher';

export default class InfoMessagesFetcher extends BaseFetcher
{
	constructor() {
		super('infomessages');
	}

	async setStatusToView(infoMessageId) {
		if (!infoMessageId) {
			throw new Error(`Параметр infoMessageId не может быть пустым при вызове ${this.constructor.name}.setStatusToView`);
		}
		return this.fetchPut(`${this.basePath}/one/${infoMessageId}/set-viewed`);
	}
}
