import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PageInfo } from 'common-lib';
import * as ConfigApi from '~api/featureConfigurationsApi';
import { DataTableWithPageInfo } from '~components';
import { fillTableData, TABLE_HEADERS } from './infoFeatureConfigurationAttrs';
import { Spinner } from '~components/smallComponents';

const AdminConfigurationListPage = () => {
	const [{ tableData, checkQueryString, isTableDataRequesting }, setTableData] =
		React.useState<any>({});

	const history = useHistory();
	const location = useLocation();

	const pageInfo = PageInfo.parseFromString(location.search, { order: 'id:asc' });

	if (!tableData || pageInfo.toQueryString() !== checkQueryString) {
		if (!isTableDataRequesting) {
			setTableData({ tableData, checkQueryString, isTableDataRequesting: true });
			ConfigApi.fetchFeatureConfigurationList(pageInfo).then((res) => {
				const newTableData = fillTableData(res);
				setTableData({
					tableData: newTableData,
					checkQueryString: pageInfo.toQueryString(),
				});
			});
		}
	}

	return (
		<>
			{tableData ? (
				<DataTableWithPageInfo
					searchable
					icon="setting"
					title="Конфигуратор бета-функционала"
					headers={TABLE_HEADERS}
					rows={tableData.rows}
					pageInfo={tableData.pageInfo}
					onClickItem={(itemId) => history.push(`${location.pathname}/${itemId}`)}
					onChangePageOptions={(options) => {
						const pageInfo1 = PageInfo.parseFromString(location.search, { pageSize: 10 });
						pageInfo1.update(options);
						history.push(`${location.pathname}?${pageInfo1.toQueryString()}`);
					}}
				/>
			) : null}
			{isTableDataRequesting ? <Spinner /> : null}
		</>
	);
};

export default AdminConfigurationListPage;
