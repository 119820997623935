import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NoauthUserContentWrapper, UserContentWrapper } from '~containers';
import { Button, Modal } from 'semantic-ui-react';
import moment from 'moment';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';

const ArrowButtonStyled = styled.button`
	background-repeat: no-repeat;
	background-position: center;
	background-color: inherit;
	height: 60px;
	min-width: 70px;
	max-width: 170px;
	border: 0;
	transition: transform 0.2s ease-in-out;
	cursor: pointer;

	&:disabled {
		opacity: 0;
		cursor: auto;
	}

	&.left {
		position: fixed;
		left: 50px;
		top: 50%;
		transform: translateY(-50%);
		background-image: url(/assets/img/ui/gallery-left-arrow.svg);

		&:hover {
			transform: translate(-5px, -50%);
		}
	}

	&.right {
		position: fixed;
		right: 50px;
		top: 50%;
		transform: translateY(-50%);
		background-image: url(/assets/img/ui/gallery-right-arrow.svg);

		&:hover {
			transform: translate(5px, -50%);
		}
	}`;

const IconStyled = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 3;
	border-top: 1px solid #BFBFC7;
	text-align: center;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;

	table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		text-align: center;

		@media screen and (orientation: portrait) {
			height: 50px;

			.icons {
				width: 25px;
				height: 25px;
			}
		}
		@media screen and (orientation: landscape) {
			height: 50px;

			.icons {
				width: 20px;
				height: 20px;
			}
		}

		@media screen and (min-width: 960px) {
			height: 67px;
			.icons {
				width: 35px;
				height: 35px;
			}
		}

		td {
			width: 25%;
		}
	}

	.icons {
		width: 35px;
		height: 35px;
		margin: 10px 0;
		cursor: pointer;
	}
`

const CarouselWindowDesktop = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: calc(100% - 135px);
	width: 100%;
	overflow: hidden;

	.window {
		height: 100%;
		width: 100%;
	}

	.all-images-container {
		height: 100%;
		display: flex;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		transition: transform .3s ease;

		img {
			object-fit: contain;
			max-width: 100%;
			min-width: 100%;
		}
	}
`
const CarouselWindowMobile = styled.div`
	position: fixed;
	top: 68px;
	left: 0;
	height: calc(100% - 119px);
	width: 100%;
	overflow: hidden;

	.window {
		height: 100%;
		width: 100%;
	}

	.all-images-container {
		height: 100%;
		display: flex;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		transition: transform .3s ease;

		img {
			object-fit: contain;
			max-width: 100%;
			min-width: 100%;
		}
	}
`

const HiddenArrow = styled.button`
	position: fixed;
	width: 10%;
	top: 20%;
	bottom: 20%;
	background: inherit;
	border: 0;

	&.left {
		left: 0;
	}

	&.right {
		right: 0;
	}
`;

interface Props
{
	selectedIndex: number;
	imageList: {
		fileId: number;
		title: string;
		subTitle?: string;
		src: string;
	}[];
	isMobile: boolean;
	onClose: () => void;
	onDownload?: (fileId: number) => void;
	onDelete?: (fileId: number) => void;
}

const MAX_WIDTH = 100;

const UserPhotoPreview = (props: Props) => {
	const [selectedItem, setSelectedItem] = useState<number>(props.selectedIndex)
	const [isShowConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
	const [offset, setOffset] = useState<number>(selectedItem * -MAX_WIDTH);
	const [touchObj] = useState({
		start: { x: -1, y: -1 },
		finish: { x: -1, y: -1 },
	})
	const isFirst = 0;
	const isLast = props.imageList.length - 1;
	const isLastOffset = -(MAX_WIDTH * (props.imageList.length - 1));
	const file = props.imageList[selectedItem];

	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	const handleLeftArrowClick = () => {
		if (selectedItem !== isFirst) {
			const newSelItem = selectedItem - 1;
			setSelectedItem(newSelItem);
			setOffset(newSelItem * -MAX_WIDTH);
			resetTouchObj();
		}
	}

	const handleRightArrowClick = () => {
		if (selectedItem !== isLast) {
			const newSelItem = selectedItem + 1;
			setSelectedItem(newSelItem);
			setOffset(newSelItem * -MAX_WIDTH);
			resetTouchObj();
		}
	}

	function resetTouchObj() {
		touchObj.start = { x: -1, y: -1 };
		touchObj.finish = { x: -1, y: -1 };
	}

	// свайп фотографий
	const handleTouchStart = (e) => {
		const firstTouch = e.touches[0];
		touchObj.start.x = firstTouch.clientX;
		touchObj.start.y = firstTouch.clientY;
	}

	const handleTouchMove = (e) => {
		const touchFin = e.touches[0];
		touchObj.finish.x = touchFin.clientX;
		touchObj.finish.y = touchFin.clientY;
		// check for swipe
		if (touchObj.start.x < 0) return;
		// ограничения чтобы первый и последний кадр не мотались за экран
		const dx = Math.max(-60, Math.min(60, touchObj.finish.x - touchObj.start.x));
		setOffset(selectedItem * -MAX_WIDTH + dx);
		// при достаточном смещении пальцем будем менять кадр
		if (dx < -40) {
			handleRightArrowClick();
		} else if (dx > 40) {
			handleLeftArrowClick();
		}
	}

	const handleTouchEnd = () => {
		if (touchObj.start.x >= 0) {
			// смена кадра  не случилась, сброс offset
			setOffset(selectedItem * -MAX_WIDTH);
		}
		resetTouchObj();
	}

	return (
		<NoauthUserContentWrapper title={moment(file.title, 'DD.MM').locale('ru').format('DD MMMM')}
		                    subTitle={file.subTitle}
		                    onBackClick={props.onClose}>
			{!props.isMobile ? (
				<>
					<CarouselWindowDesktop>
						<div className={'window'}>
							<div className="all-images-container"
							     style={{ transform: `translate3d(${offset}%, 0, 0)` }}>
								{props.imageList.map(img => (
									<img key={img.fileId} src={img.src} alt="" />
								))}
							</div>
						</div>
					</CarouselWindowDesktop>
					<ArrowButtonStyled className="left"
					                   disabled={offset === isFirst}
					                   onClick={handleLeftArrowClick} />
					<ArrowButtonStyled className="right"
					                   disabled={offset === isLastOffset}
					                   onClick={handleRightArrowClick} />
					<IconStyled>
						<table>
							<tbody>
								<tr>
									<td>
                                    <span style={{ marginRight: '20px' }}>
                                        {props.onDownload ? (
											<img
												src="/assets/img/user-photo-preview/download-icon.svg"
												className="icons"
												draggable="false" // запрет на премещение иконок
												onClick={() => (props.onDownload ? props.onDownload(file.fileId) : null)}
												alt="" />
                                        ) : null}
                                    </span>
										<span style={{ marginLeft: '20px' }}>
											{props.onDelete ? (
												<img
													src="/assets/img/user-photo-preview/trash-icon.svg"
													className="icons"
													draggable="false" // запрет на премещение иконок
													onClick={() => setShowConfirmDelete(true)}
													alt="" />
											) : null}
                                    </span>
									</td>
								</tr>
							</tbody>
						</table>
					</IconStyled>
				</>
			) : (
				<>
					<CarouselWindowMobile>
						<div className="window">
							<div
								className="all-images-container"
								style={{ transform: `translate3d(${offset}%, 0, 0)` }}
								onTouchStart={handleTouchStart}
								onTouchMove={handleTouchMove}
								onTouchEnd={handleTouchEnd}>
								{props.imageList.map(img => (
									<img key={img.fileId} src={img.src} alt="" />
								))}
							</div>
						</div>
					</CarouselWindowMobile>
					<HiddenArrow
						className="left"
						disabled={offset === isFirst}
						onClick={handleLeftArrowClick} />
					<HiddenArrow
						className="right"
						disabled={offset === isLastOffset}
						onClick={handleRightArrowClick} />
					<IconStyled>
						<table>
							<tbody>
								<tr>
									<td>
										<img
											src="/assets/img/user-photo-preview/arrow-left.png"
											className="icons"
											style={offset === isFirst ? { opacity: 0.2 } : {}}
											draggable="false"
											onClick={handleLeftArrowClick}
											alt="" />
									</td>
									<td>
										<img
											src="/assets/img/user-photo-preview/arrow-right.png"
											className="icons"
											style={offset === isLastOffset ? { opacity: 0.2 } : {}}
											draggable="false"
											onClick={handleRightArrowClick}
											alt="" />
									</td>
									<td>
										{props.onDownload ? (
                                            <img
                                                src="/assets/img/user-photo-preview/download-icon.svg"
                                                className="icons"
                                                draggable="false"
                                                onClick={() => (props.onDownload ? props.onDownload(file.fileId) : null)}
                                                alt="" />
                                        ) : null}
									</td>
									<td>
										{props.onDelete ? (
                                            <img
                                                src="/assets/img/user-photo-preview/trash-icon.svg"
                                                className="icons"
                                                draggable="false"
                                                onClick={() => setShowConfirmDelete(true)}
                                                alt="" />
                                        ) : null}
									</td>
								</tr>
							</tbody>
						</table>
					</IconStyled>
				</>
			)}

			{isShowConfirmDelete ? (
				<Modal open
				       size="tiny"
				       closeOnEscape
				       closeOnDimmerClick
				       onClose={() => setShowConfirmDelete(false)}>
					<Modal.Content>
						<p>
							Удалить фотографию?
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button negative
						        onClick={() => setShowConfirmDelete(false)}
						        content="Нет" />
						<Button positive
						        onClick={() => (props.onDelete ? props.onDelete(file.fileId) : null)}
						        content="Да" />
					</Modal.Actions>
				</Modal>) : null}
		</NoauthUserContentWrapper>
	);
};

export default UserPhotoPreview;
