export enum ResponseErrorEnum {
	ERROR_CODE_PROCESSING = 'PROCESSING',
	ERROR_CODE_AUTH_TOKEN_NOT_FOUND = 'AUTH_TOKEN_NOT_FOUND',
	ERROR_CODE_AUTH_USER_NOT_FOUND = 'AUTH_USER_NOT_FOUND',
	ERROR_CODE_USER_HAS_NO_ACCESS = 'USER_HAS_NO_ACCESS',
	ERROR_CODE_NOT_FOUND = 'NOT_FOUND',
	ERROR_CODE_ROUTE_NOT_FOUND = 'ROUTE_NOT_FOUND',
	ERROR_CODE_SERVER_ERROR = 'SERVER_ERROR',
	ERROR_CODE_DATABASE_READ_ERROR = 'DATABASE_READ_ERROR',
	ERROR_CODE_DATABASE_WRITE_ERROR = 'DATABASE_WRITE_ERROR',
	ERROR_CODE_DATABASE_UNIQUE_CONSTRAINT_ERROR = 'DATABASE_UNIQUE_CONSTRAINT_ERROR',
	ERROR_CODE_PARSE_INPUT_DATA_EXCEPTION = 'PARSE_INPUT_DATA_EXCEPTION',
	ERROR_CODE_UNHANDLED_EXCEPTION = 'UNHANDLED_EXCEPTION',
	ERROR_CODE_USER_HAS_NO_ACCESS_TO_CHAGE_VALUE = 'USER_HAS_NO_ACCESS_TO_CHAGE_VALUE',
	ERROR_CODE_HAVE_NO_MAIL_TEMPLATE_FOR_INPUT = 'HAVE_NO_MAIL_TEMPLATE_FOR_INPUT',
	ERROR_CODE_NOT_ALL_REQUIRED_FIELDS = 'NOT_ALL_REQUIRED_FIELDS',
	ERROR_CODE_VALIDATION_INPUT = 'VALIDATION_INPUT',
	ERROR_CODE_BAD_REQUEST = 'BAD_REQUEST',
	ERROR_UNIQUE_CONSTRAINT_ERROR = 'UNIQUE_CONSTRAINT_ERROR',
	ERROR_CODE_CSV_FILE_REQUIRED = 'CSV_FILE_REQUIRED',
	ERROR_CODE_REQUESTED_ITEM_NOT_FOUND = 'REQUESTED_ITEM_NOT_FOUND',
	ERROR_CODE_USER_HAS_NO_ACCESS_TO_CHANGE_VALUE = 'USER_HAS_NO_ACCESS_TO_CHANGE_VALUE',
	ERROR_CODE_AUTH_AS_CURRENT_USER = 'AUTH_AS_CURRENT_USER',
	ERROR_CODE_USER_NOT_FOUND = 'USER_NOT_FOUND',
	ERROR_CODE_USER_ROLE_NOT_FOUND = 'USER_ROLE_NOT_FOUND',
	ERROR_CODE_DF_NOT_FOUND = 'DF_NOT_FOUND',
	ERROR_CODE_TM_NOT_FOUND = 'TM_NOT_FOUND',
	ERROR_CODE_UPF_NOT_FOUND = 'UPF_NOT_FOUND',
	ERROR_CODE_SHOP_NOT_FOUND = 'SHOP_NOT_FOUND',
	ERROR_CODE_FILE_NOT_FOUND = 'FILE_NOT_FOUND',
	ERROR_CONNECTION_ERROR = 'CONNECTION_ERROR',
}
