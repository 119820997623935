import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { UserRoles, PageInfo } from 'common-lib';
import { DataTableWithPageInfo, Spinner } from '~components';
import { getShopsList } from '~api/shopsApi';
import { fillTableData, TABLE_HEADERS } from '../shopsAttrs';

type State = {
	tableData: any
	checkQueryString: string
	isTableDataRequesting: boolean
}

export default function AdminShopsTablePage(props) {
	const [{
		tableData,
		checkQueryString,
		isTableDataRequesting,
	}, setTableData] = useState<Partial<State>>({});
	const { role } = useSelector((state: any) => state.me.currentUser);

	const pageInfo = PageInfo.parseFromString(props.location.search);
	pageInfo.meta = new Set(['users']);

	if (!tableData || pageInfo.toQueryString() !== checkQueryString) {
		if (!isTableDataRequesting) {
			setTableData({ tableData, checkQueryString, isTableDataRequesting: true });
			getShopsList(pageInfo).then(json => {
				const newTableData = fillTableData(json);
				setTableData({
					tableData: newTableData,
					checkQueryString: pageInfo.toQueryString(),
				});
			});
		}
	}

	return (
		<>
			{tableData ? (
				<DataTableWithPageInfo
					searchable
					icon="warehouse"
					title="Магазины"
					headers={TABLE_HEADERS}
					rows={tableData.rows}
					pageInfo={tableData.pageInfo}
					onClickItem={itemId => props.history.push(`${props.location.pathname}/${itemId}`)}
					onAddItem={[UserRoles.ADMIN, UserRoles.SUPPORT].includes(role) ? () => {
						props.history.push(`${props.location.pathname}/+`);
					} : null}
					onChangePageOptions={options => {
						const pageInfo1 = PageInfo.parseFromString(props.location.search);
						pageInfo1.update(options);
						props.history.push(`${props.location.pathname}?${pageInfo1.toQueryString()}`);
					}}
				/>
			) : null}
			{isTableDataRequesting ? <Spinner /> : null}
		</>
	)
}

AdminShopsTablePage.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
}
