import { PageInfo } from 'common-lib';
import { fetchDelete, fetchGetThenJson, fetchPost, fetchPostThenJson } from '~tools/fetchBackend';

export async function fetchQuizList(page: number = 1, showPerPage: number = 10, order: string = 'id:desc'): Promise<any> {
	return fetchGetThenJson(`questionnaires/list?page=${page}:${showPerPage}&order=${order}`);
}

export async function fetchQuiz(id: number): Promise<any> {
	return fetchGetThenJson(`questionnaires/one/${id}`);
}

export async function createQuiz(payload: any): Promise<any> {
	return fetchPostThenJson('questionnaires/create', payload);
}

export async function createQuizDuplicate(id: number): Promise<any> {
	return fetchPostThenJson(`questionnaires/duplicate/${id}`);
}

export async function enableQuiz(id: number): Promise<any> {
	return fetchPost(`questionnaires/enable/${id}`);
}

export async function disableQuiz(id: number): Promise<any> {
	return fetchPost(`questionnaires/disable/${id}`);
}

export async function deleteQuiz(id: number): Promise<any> {
	return fetchDelete(`questionnaires/one/${id}`);
}

export async function fetchReportsList(pageInfo: PageInfo): Promise<any> {
	const query = pageInfo.toQueryString();
	return fetchGetThenJson(`questionnaires/reports/list${query ? `?${query}` : ''}`);
}

export async function fetchRunningQuizList(): Promise<any> {
	return fetchGetThenJson('questionnaires/running');
}
export async function fetchRunningQuizListForManager(): Promise<any> {
	return fetchGetThenJson('questionnaires_for_manager/running');
}

/**
 * Запрос данных статистики по опросам для текущего менеджера
 */
export async function fetchQuizCurrentManagerStats(pageInfo?: PageInfo): Promise<any> {
	return fetchGetThenJson(`questionnaires/manager-stats`);
}

/**
 * Запрос списка опросов подчиненного магазина из ЛК менеджера
 */

export async function fetchRunningSubShopList(shopId: number): Promise<any> {
	return fetchGetThenJson(`questionnaires/sub-shop/${shopId}`);
}

/**
 * Запрос списка опросов подчиненного менеджера
 */
export async function fetchQuizListForSelectedManager(managerId: number): Promise<any> {
	return fetchGetThenJson(`questionnaires/sub-user/${managerId}`);
}

export async function answerQuiz(id: number, payload: any): Promise<any> {
	return fetchPost(`questionnaires/answer/${id}`, payload);
}

export async function answerTest(id: number, payload: any, result: any): Promise<any> {
	return fetchPost(`testing/answer/${id}`, { payload, result });
}

export async function getPublicQuizReportResult(quizHash: string): Promise<any> {
	return fetchGetThenJson(`public/questionnaires/result/${quizHash}`);
}

export async function getStatReportId(id: number): Promise<any> {
	return fetchGetThenJson(`questionnaires/statistic/one/${id}`);
}

export async function createReportByShops(execId: number, shops: number[], selectedFilter: number): Promise<any> {
	return fetchPostThenJson(`questionnaires/create_report/${execId}`, { shopIds: shops, selectedFilter});
}

export async function createReportByManagers(execId: number, managers: number[], selectedFilter: number): Promise<any> {
	return fetchPostThenJson(`questionnaires/create_report_by_managers/${execId}`, { managerIds: managers, selectedFilter});
}

export async function fetchPhotoApplication(data: any): Promise<any> {
	return fetchPostThenJson('questionnaires/photo_application', data);
}