import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { UiBadge } from '~components';

const ListStyled = styled.div`
  padding-top: 10px;
  padding-right: 10px;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;

  // скрываем скроллбар (только на девайсах), но оставляем возможность скроллить контейнер
  @media only screen and (max-device-width: 736px) {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-height: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ListItemStyled = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 1px solid #BFBFBF;
  border-radius: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;

  &.add {
    border: none;
    background-color: #7B7FEF;
    background-image: url(/assets/img/ui/photo-add.svg);
    background-size: initial;
  }

  &:not(.add) {
    position: relative;
    background-image: url(${p => p.src});
  }
`;

const DeletedStyled = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/assets/img/ui/photo-delete.svg);
`;

const InfoStyled = styled.div`
  color: #8790A6;
  font-size: 12px;
  font-weight: 400;
`;

const BackStyled = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 16px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShowImageStyled = styled.div`
  position: relative;

  img {
    min-width: 300px;
    max-width: 100%;
    min-height: 200px;
    border: 1px solid #BFBFBF;
    border-radius: 16px;
  }
`;

const CloseStyled = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: center;
  background-image: url(/assets/img/ui/photo-close.svg);
  cursor: pointer;
`;

const ButtonsStyled = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
`;

const ArrowButtonStyled = styled.button`
  background-color: ${p => p.theme.buttonAcceptBg};
  background-repeat: no-repeat;
  background-position: center;
  height: 60px;
  min-width: 70px;
  max-width: 170px;
  width: 100%;
  border: 0;
  border-radius: 16px;
  cursor: pointer;

  &:disabled {
    background-color: silver;
  }

  &.left {
    background-image: url(/assets/img/ui/gallery-left-arrow.svg);
    margin-right: 8px;
  }

  &.right {
    background-image: url(/assets/img/ui/gallery-right-arrow.svg);
    margin-left: 8px;
  }
`;

type Props = {
	photos: string[]
	infoText?: string
	readOnly?: boolean
	isLimit?: boolean
	onAddPhoto?: Function
	onRemovePhoto?: Function
}

export default function UiPhotoGallery(props: Props) {
	const [showIndex, setShowIndex] = useState<number>();
	const listRef = useRef<HTMLDivElement>();

	function onShowPhoto(index) {
		setShowIndex(index);
	}

	function onAddPhoto(newPhoto) {
		props.onAddPhoto?.(newPhoto);
		listRef.current?.scroll({
			top: 0,
			left: listRef.current?.scrollWidth,
			behavior: 'auto',
		});
	}

	function onRemove(index) {
		props.onRemovePhoto?.(index);
	}

	return (
		<>
			<ListStyled ref={listRef}>
				{props.photos.map((photoSrc, index) => (
					<ListItemStyled key={`photo-${index}`} src={photoSrc}
					                onClick={() => onShowPhoto(index)}>
						{!props.readOnly || !props.isLimit ? (
							<DeletedStyled onClick={e => { e.stopPropagation(); onRemove(index); }} />
						) : null}
					</ListItemStyled>
				))}
				{!props.readOnly || props.isLimit ? (
					<ListItemStyled className="add"
					                onClick={() => selectPhoto(true, onAddPhoto)} />
				) : null}
			</ListStyled>
			{props.infoText ? <InfoStyled>{props.infoText}</InfoStyled> : null}
			{showIndex !== undefined ? (
				<BackStyled onClick={() => onShowPhoto(undefined)}>
					<div onClick={e => e.stopPropagation()}>
						<UiBadge bigPaddings>
							<ShowImageStyled>
								<img src={props.photos[showIndex]} alt="" />
								<CloseStyled onClick={() => onShowPhoto(undefined)} />
							</ShowImageStyled>
							<ButtonsStyled>
								<ArrowButtonStyled className="left" disabled={showIndex === 0}
								                   onClick={() => onShowPhoto(showIndex - 1)} />
								<ArrowButtonStyled className="right"
								                   disabled={showIndex === props.photos.length - 1}
								                   onClick={() => onShowPhoto(showIndex + 1)} />
							</ButtonsStyled>
						</UiBadge>
					</div>
				</BackStyled>
			) : null}
		</>
	);
}

function selectPhoto(isCapture, callback, maxSquareSize = 500) {
	const input = document.createElement('input'); // создаем input, сразу кликаем чтобы выбрать фотку
	input.setAttribute('type', 'file');
	input.setAttribute('accept', 'image/*');
	if (isCapture) {
		input.setAttribute('capture', 'camera');
	}
	input.style.display = 'none';
	document.body.appendChild(input); // в safari тег input обязательно нужно добавить в DOM
	input.addEventListener('change', () => { // пользователь выбрал фотку
		if (input.files && input.files[0]) {
			const reader = new FileReader(); // загружаем картинку через ридер
			reader.onload = e => {
				const image = document.createElement('img'); // рисуем файлик в <img/>
				image.onload = () => {
					const { width: imw, height: imh } = image;
					const squareSize = Math.min(imw, imh); // будем обрезать картинку до квадрата
					const dx = Math.abs(imw - squareSize) / 2;
					const dy = Math.abs(imh - squareSize) / 2;
					const distSquareSize = Math.min(squareSize, maxSquareSize); // макс размер картинки
					const canvas = document.createElement('canvas');
					canvas.width = distSquareSize;
					canvas.height = distSquareSize;
					const context = canvas.getContext('2d'); // скейлим и рисуем картинку на <canvas/>
					context?.drawImage(image, dx, dy, squareSize, squareSize, 0, 0, distSquareSize, distSquareSize);
					const data = canvas.toDataURL('image/png');
					callback(data);
					document.body.appendChild(input);
				};
				image.setAttribute('src', e?.target?.result as string);
			};
			reader.readAsDataURL(input.files[0]);
		}
	});
	input.click();
}
