import {fetchDelete, fetchGetThenJson, fetchPostThenJson} from '~tools/fetchBackend';


export async function fetchPhotoGalleryList(): Promise<any> {
	return fetchGetThenJson(`photo_gallery/list`);
}

export async function deletePhoto(fileId: number): Promise<any> {
	return fetchDelete(`photo_gallery/one/${fileId}`);
}

export async function uploadPhoto(data): Promise<any> {
	return fetchPostThenJson('photo_gallery/upload', data);
}
