import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { AdminMenu, VersionInfo } from '~components';
import { mainTheme } from './brandColors';

export default class AdminUserLayout extends React.Component
{
	static propTypes = {
		children: PropTypes.node.isRequired,
	}

	render() {
		return (
			<ThemeProvider theme={mainTheme}>
				<AdminMenu />
				<div style={{ padding: '4rem 1rem' }}>
					{this.props.children}
				</div>
				<VersionInfo />
			</ThemeProvider>
		);
	}
}
