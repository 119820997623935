import React, { ReactNode, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getRoleName } from 'common-lib';
import { logout } from '~store/actions/loginActions';
import { FaceByText, UiContainer, UiOverMenu } from '~components';
import { TRANSPARENT_1PX } from '~components/ui/images';
import { UiContainerContentBreadCrumbs } from '~components/ui/UiContainer';
import UserMenu3 from '~components/menu/UserMenu3';
import { FiltersWizardOption, SearchWizardOptions } from '~components/ui/SearchAndFiltersWizard';
import { setSwipeRefreshLayoutEnabled } from "~tools/androidFunctions";
import { EVENT_CHECK_BACK_HANDLE, UserLayout2Context } from '../layouts/UserLayout2';

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 18px 16px 18px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E4E6EB;

  .title {
    font-size: 16px;
    font-weight: 400;
  }

  .title2 {
    font-size: 12px;
    color: #9697A6;
  }
`;

const ExitImg = styled.img`
  width: 42px;
  height: 42px;
  background-image: url(/assets/img/exit-button.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50px;
  cursor: pointer;

  // для веба разрешаем менять цвет текста и иконок при наведении
  @media (min-width: 900px) {
    &:hover {
      background-color: ${p => p.theme.badgeButtonActiveBg};
    }
  }
`;

const CrossImg = styled.img`
  width: 42px;
  height: 42px;
  background-image: url(/assets/img/cross.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50px;
  cursor: pointer;

  // для веба разрешаем менять цвет текста и иконок при наведении
  @media (min-width: 900px) {
    &:hover {
      background-color: ${p => p.theme.badgeButtonActiveBg};
    }
  }
`;

type Props = {
	title: string;
	isPreview?: boolean;
	subTitle?: string;
	backLinkTo?: string;
	onBackClick?: () => void;
	searchOptions?: SearchWizardOptions;
	filterOptions?: FiltersWizardOption[];
	children: ReactNode;
	overMenu?: ReactNode;         // контент для UiOverMenu
	breadCrumbs?: UiContainerContentBreadCrumbs; // крошки
	onPlusButtonClick?: Function; // обработчик клика по кнопке "плюс"
}

export default function UserContentWrapper(props: Props) {
	const meConfig: any = useSelector<any>(state => state.me.currentUser);
	const context = useContext(UserLayout2Context);
	const history = useHistory();

	const [showHamburgerContainer, setShowHamburgerContainer] = useState<boolean>(false);

	// если нужно показать контент гамбургера, то основной скролл надо скрывать
	// это делается на тот случай если скролл будет во контенте гамбургера
	// также будем просить Android приложение включать/отключать релоад страницы по свайпу
	const isMainScrollShow = document.body.style.overflow !== 'hidden';
	if (!props.isPreview && isMainScrollShow === showHamburgerContainer) {
		if (showHamburgerContainer) {
			document.body.style.overflow = 'hidden';
			setSwipeRefreshLayoutEnabled(false);
		} else {
			document.body.style.overflow = '';
			setSwipeRefreshLayoutEnabled(true);
		}
	}

	return (
		<UiContainer>
			<UiContainer.Header
				isPreview={props.isPreview}
				title={props.title}
				subTitle={props.subTitle}
				hamburger={!props.isPreview}
				backLinkTo={props.backLinkTo}
				onBackClick={props.onBackClick}
				onHamburgerClick={() => setShowHamburgerContainer(true)}
			/>
			<UiContainer.Content
				breadCrumbs={props.breadCrumbs}
				onPlusButtonClick={props.onPlusButtonClick}
				searchOptions={props.searchOptions}
				filtersOptions={props.filterOptions}
			>
				{props.children}
			</UiContainer.Content>
			{props.overMenu ? <UiOverMenu>{props.overMenu}</UiOverMenu> : null}
			{showHamburgerContainer ? (
				<UiContainer.HamburgerContent
					onClickDimmer={() => setShowHamburgerContainer(false)}>
					{renderHamburgerContent()}
					<UserMenu3 />
				</UiContainer.HamburgerContent>
			) : null}
		</UiContainer>
	);

	// TODO нужен ли клик назад?
	function onBackClick() {
		// проверяет, можно ли вернуться назад
		const result = context.dispatchEvent(EVENT_CHECK_BACK_HANDLE);
		if (result) {
			if (result.some(b => !b)) { // какой-то из детей запретил переходить назад
				return;
			}
		}
		history.goBack();
	}

	function renderHamburgerContent() {
		return (
			<HeaderStyled>
				<div style={{ marginRight: '10px' }}>
					<FaceByText faceFullName={meConfig.fullName} />
				</div>
				<div style={{ flex: 1 }}>
					<div className="title">{meConfig.fullName}</div>
					<div className="title2">{getRoleName(meConfig.role)}</div>
				</div>
				<div>
					<ExitImg src={TRANSPARENT_1PX} onClick={() => {
						logout()
							.then(({ redirectUrl }) => {
								// сервер может попросить перейти по другому адресу
								window.location.replace(redirectUrl || '/');
							})
							.catch(err => {
								console.error('[Ошибка разлогина]', err);
								window.location.replace('/');
							})
					}} />
				</div>
				<div>
					<CrossImg src={TRANSPARENT_1PX}
					          onClick={() => setShowHamburgerContainer(false)} />
				</div>
			</HeaderStyled>
		);
	}
}
