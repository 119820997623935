import React, { useEffect, useState } from 'react';
import moment from 'moment';
import isMobile from 'is-mobile';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { UserContentWrapper } from '~containers';
import * as PhotoGalleryApi from '~api/photoGalleryApi';
import { UserPhotoPreview, UiBadge, UiPhotoGallery2, ErrorSegment, Spinner } from '~components';
import {ButtonStyled, FloatButtonStyled} from '~components/ui/SearchAndFiltersWizard';

const CheckBoxStyled = styled.div`
	width: 18px;
	height: 18px;
	border: 1px solid #BFBFC7;
	border-radius: 5px;
	background: #ffffff center no-repeat;
	background-image: ${p => p.checked ? 'url(/assets/img/checker-checked.svg)' : 'none'};
	margin-right: 5px;
`;

const PhotoTitleStyled = styled.div`
	display: inline-flex;
	align-items: center;
	margin-bottom: 5px;
	cursor: default;
`;

const DownloadPanel = styled.div`
	.download {
		display: flex;
		min-width: 330px;
		align-items: center;
		justify-content: center;
	}

	.footer_text,
	.downloading {
		font-size: 20px;
		color: #ffffff;
		font-family: 'SF Compact Display', 'Helvetica', 'Arial', sans-serif;
	}

	.downloading {
		padding-top: 8.5px;
		padding-bottom: 8.5px;
	}

	.hiddenDownload {
		display: none;
	}

	.icon {
		width: 16px;
		height: 16px;
		margin-top: 10px;
		margin-bottom: 10px;
		margin-right: 10px;
	}
`;

interface GalleryDataItem {
	fileId: number;
	createdAt: string;
}

const UserPhotoGalleryPage = () => {
	const [isRequesting, setRequesting] = useState<boolean>(true);
	const [error, setError] = useState<string>();
	const [galleryData, setGalleryData] = useState<Map<string, GalleryDataItem[]>>();
	const [showFileId, setShowFileId] = useState<number>();
	const [selectedItems, setSelectedItems] = useState<Record<string, number[]>>({});
	const [isDownloadInProgress, setDownloadInProgress] = useState(false);

	const history = useHistory();
	const location = useLocation();

	// обновление списка фотографий
	function reloadGalleryList() {
		if (!isRequesting) setRequesting(true);
		PhotoGalleryApi.fetchPhotoGalleryList()
			.then(res => {
				const fileGroupMap = new Map<string, any[]>();
				res.data.forEach(({ createdAt, fileId }) => {
					const date = moment(createdAt).utcOffset(300).format('YYYY-MM-DD');
					if (!fileGroupMap.has(date)) {
						fileGroupMap.set(date, []);
					}
					fileGroupMap.get(date)?.push({
						createdAt,
						fileId,
					});
				});
				setGalleryData(fileGroupMap);
			})
			.catch(err => {
				console.error(err);
				setError(`Ошибка при загрузке списка фотографий: ${String(err?.message || err)}`);
			})
			.finally(() => setRequesting(false));
	}

	useEffect(() => {
		reloadGalleryList();
	}, []);

	useEffect(() => {
		if (showFileId) {
			if (location.hash !== '#preview') {
				location.hash = '#preview';
				history.push(location.pathname + location.hash);
			}
			window.addEventListener('popstate', onBack, false);
		}
	}, [showFileId]);

	function onBack(e) {
		e.preventDefault();
		setShowFileId(undefined);
		window.removeEventListener('popstate', onBack);
	}

	// добавление фото в галерею
	function onAddPhoto(newPhoto: string): void {
		setRequesting(true);
		PhotoGalleryApi.uploadPhoto({ data: newPhoto })
			.then(() => {
				reloadGalleryList();
			})
			.catch(err => {
				console.error(err);
				setError(`Ошибка при загрузке фотографии на сервер: ${String(err?.message || err)}`);
			})
			.finally(() => setRequesting(false));
	}

	// выбор фотографии для загрузки
	function selectPhoto(isCapture: boolean, callback: (data: string) => void, maxPhotoSize = 1200) {
		const input = document.createElement('input'); // создаем input, сразу кликаем чтобы выбрать фотку
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		if (isCapture) {
			input.setAttribute('capture', 'camera');
		}
		input.style.display = 'none';
		document.body.appendChild(input); // Для IOS в safari тег input обязательно нужно добавить в DOM
		input.addEventListener('change', () => { // пользователь выбрал фотку
			if (input.files && input.files[0]) {
				const reader = new FileReader(); // загружаем картинку через ридер
				reader.onload = e => {
					const image = document.createElement('img'); // рисуем файлик в <img/>
					image.onload = () => {
						const { width: imw, height: imh } = image;
						const maxSizeSide = Math.max(imw, imh); // ищем максимальную сторону картинки
						const ratioForHorizontal = imw / imh; // соотношение сторон горизонтальных image
						const ratioForVertical = imh / imw; // соотношение сторон вертикальных image
						let outputWidth = imw;
						let outputHeight = imh;
						const dx = 0;
						const dy = 0;
						// если размер меньше допустимого, передаём как есть
						if (imw < maxPhotoSize && imh < maxPhotoSize) {
							outputWidth = imw;
							outputHeight = imh;
						}
						// если размер один к одному
						if ((imw === imh) && imw > maxPhotoSize && imh > maxPhotoSize) {
							outputWidth = maxPhotoSize;
							outputHeight = maxPhotoSize;
						}
						// если стороны разные
						if (imw === maxSizeSide) {
							outputWidth = maxPhotoSize;
							outputHeight = outputWidth / ratioForHorizontal;
						} else if (imh === maxSizeSide) {
							outputHeight = maxPhotoSize;
							outputWidth = outputHeight / ratioForVertical;
						}
						const canvas = document.createElement('canvas');
						canvas.width = outputWidth;
						canvas.height = outputHeight;
						const context = canvas.getContext('2d'); // рисуем картинку на <canvas/>
						context?.drawImage(image, dx, dy, outputWidth, outputHeight);
						const data = canvas.toDataURL('image/png');
						callback(data);
						document.body.removeChild(input);
					};
					image.setAttribute('src', e?.target?.result as string);
				};
				reader.readAsDataURL(input.files[0]);
			}
		});
		input.click();
	}

	function clickImage(fileId: number) {
		setShowFileId(fileId);
		// reset photo checks
		setSelectedItems({});
	}

	// скачивание фотографии
	const downloadFile = (fileId: number) => {
		const a = document.createElement('a');
		a.setAttribute('style', 'display: none');
		document.body.appendChild(a);
		a.setAttribute('href', `/api/file/download/${fileId}?size=middle`);
		a.setAttribute('download', String(fileId));
		a.setAttribute('type', 'image/png');
		a.click();
		setTimeout(() => document.body.removeChild(a));
	};

	// удаление фотографии
	const deleteFile = (fileId: number) => {
		setRequesting(true);
		PhotoGalleryApi.deletePhoto(fileId)
			.then(() => {
				setShowFileId(undefined);
				reloadGalleryList();
			})
			.catch(err => {
				console.error(err);
				setError(`Ошибка при удалении фотографии: ${String(err?.message || err)}`);
			})
			.finally(() => setRequesting(false));
	};

	// отображение превью
	if (galleryData && showFileId) {
		const fileGroup = Array.from(galleryData.values())
			.find(group => group.some(f => f.fileId === showFileId));
		if (!fileGroup) {
			setError('Ошибка формирования группы данных');
			return null;
		}
		const selectedIndex = fileGroup.findIndex(f => f.fileId === showFileId);
		const previewImageList = fileGroup.map(f => {
			const date = moment(f.createdAt).utcOffset(300);
			const day = date.format('DD.MM');
			const time = date.format('HH:mm');
			return {
				fileId: f.fileId,
				title: day,
				subTitle: time,
				src: `/api/file/download/${f.fileId}?size=middle`,
			};
		});
		return <UserPhotoPreview
			selectedIndex={selectedIndex}
			imageList={previewImageList}
			isMobile={isMobile()}
			onDownload={(fileId: number) => downloadFile(fileId)}
			onDelete={(fileId: number) => deleteFile(fileId)}
			onClose={() => {
				setShowFileId(undefined);
				history.replace('/photo-gallery');
			}} />;
	}

	function selectItem(dateKey: string, fileId: number) {
		if (galleryData == undefined) return;
		const dateChecks = selectedItems[dateKey];
		// check unselect
		if (dateChecks?.indexOf(fileId) >= 0) {
			setSelectedItems({
				...selectedItems,
				[dateKey]: dateChecks.filter(i => i !== fileId),
			});
		} else {
			setSelectedItems({
				...selectedItems,
				[dateKey]: (dateChecks || []).concat(fileId),
			});
		}
		setDownloadInProgress(false);
	}

	function getSelectedFileIds(): number[] {
		return Object.keys(selectedItems).reduce((acc, dateKey) => {
			return acc.concat(...(selectedItems[dateKey] || []));
		}, [] as number[]);
	}

	function downloadSelectedPhotos() {
		// Скачивание выбранных фото через 400 мс
		const fileIds = getSelectedFileIds();
		setDownloadInProgress(true);
		fileIds.forEach((p, i) => setTimeout(() => downloadFile(p), 400 * (i + 1)));
		setTimeout(() => setDownloadInProgress(false), fileIds.length * 500);
	}

	function selectedAllForDate(dateKey: string) {
		if (galleryData == undefined) return;
		const dateChecks = selectedItems[dateKey];
		const dateFiles = galleryData.get(dateKey);
		// check unselect
		if (dateChecks?.length === dateFiles?.length) {
			setSelectedItems({
				...selectedItems,
				[dateKey]: [],
			});
			setDownloadInProgress(false);
			return;
		}
		// select all at date
		setSelectedItems({
			...selectedItems,
			[dateKey]: dateFiles?.map(i => i.fileId) || [],
		});
		setDownloadInProgress(false);
	}

	function renderGallery() {
		if (!galleryData) return null;
		const dates = Array.from(galleryData.keys()).sort().reverse();
		return dates.map(date => {
			const fileObjs = galleryData.get(date);
			if (fileObjs === undefined) {
				return (<div>Необработанная ошибка</div>);
			}
			const photoSrcs = fileObjs.map(fileObj => {
				return {
					...fileObj,
					src: `/api/file/download/${fileObj.fileId}?size=tiny`,
				};
			});
			const checks = selectedItems[date] || [];
			const checkedAll = checks.length === photoSrcs.length;
			// текущая дата
			const nowDate = moment().format('YYYY-MM-DD');

			return (
				<div key={date}>
					{!isMobile()
						? (
						<PhotoTitleStyled onClick={() => selectedAllForDate(date)}>
							<CheckBoxStyled checked={checkedAll} />
							{nowDate === date ? 'Сегодня' : moment(date).locale('ru').format('DD MMMM')}
						</PhotoTitleStyled>
						) : (
						<div>
							{nowDate === date ? 'Сегодня' : moment(date).locale('ru').format('DD MMMM')}
						</div>
						)}
					<UiBadge bigPaddings>
						<UiPhotoGallery2
							photos={photoSrcs}
							checks={checks}
							onClickImage={clickImage}
							onSelect={(fileId: number) => { selectItem(date, fileId); }}
							select={true}
							readOnly
							/>
					</UiBadge>
				</div>
			);
		});
	}

	const plusBtnClickHandler = isMobile() ? () => selectPhoto(true, onAddPhoto) : undefined;

	const selectedCount = getSelectedFileIds().length;

	return (
		<UserContentWrapper title="Галерея" onPlusButtonClick={plusBtnClickHandler}>
			{error ? <ErrorSegment>{error}</ErrorSegment> : null}
			{isRequesting ? <Spinner /> : null}
			{renderGallery()}
			{!isMobile() && selectedCount > 0 ? (
				<FloatButtonStyled style={{
					display: 'flex',
					justifyContent: 'center'
				}}>
					{isDownloadInProgress ? (
						<ButtonStyled style={{
							minWidth: '330px',
							padding: '0px',
							background: 'rgba(10, 10, 10, 0.7)',
							transform: 'none',
							cursor: 'default'
						}}>
							<DownloadPanel>
								<div className="downloading">
									Происходит загрузка...
								</div>
							</DownloadPanel>
						</ButtonStyled>
					) : (
						<ButtonStyled style={{ minWidth: '330px', padding: '0px', transform: 'none' }}>
							<DownloadPanel>
								<div className="download" onClick={downloadSelectedPhotos}>
									<img
										src="/assets/img/user-photo-preview/download-icon-white.svg"
										className="icon"
										draggable="false" // запрет на премещение иконок
										alt="" />
									<div className="footer_text">
										Скачать выбранные ({selectedCount})
									</div>
								</div>
							</DownloadPanel>
						</ButtonStyled>
					)}
				</FloatButtonStyled>
			) : null}
		</UserContentWrapper>
	);
};

export default UserPhotoGalleryPage;
