import React from 'react';
import styled from 'styled-components';
import FaceImage from './FaceImage';
import Speedometer from './Speedometer';
import UiBadge from './UiBadge';
import { FaceByText } from '~components/index';

const FlexedLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    
    &.face {
      flex: 0 1 48px;
      line-height: 0;
    }

    &.title {
      flex: 1 1;
      padding: 0 10px;
    }

    &.progress {
      flex: 0 1 60px;
      text-align: right;
      line-height: 0;
    }
  }
`;

const TitleStyle = styled.div`
  font-size: 16px;
  color: #2C2E4E;
`;

const SubTitleStyle = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: #9E9EAC;
`;

type ProgressType = {
	value: number
	max: number
}

type Props = {
	faceUrl?: string        // ссылка на картинку (отменяет faceByText)
	faceFullName?: string   // рисует фейс по тексту (не работает если указан faceUrl)
	title: string           // заголовок
	subTitle?: string       // подзаголовок
	progress?: ProgressType // параметры спидометра
	linkTo?: string         // ссылка для перехода
	onClick?: Function      // обработчик клика
}

export default function UiFaceProgressBadge(props: Props) {
	return (
		<UiBadge linkTo={props.linkTo} onClick={props.onClick}>
			<FlexedLink>
				{props.faceUrl ? (
					<div className="face">
						<FaceImage url={props.faceUrl} />
					</div>
				) : props.faceFullName ? (
					<div className="face">
						<FaceByText faceFullName={props.faceFullName} />
					</div>
				) : null}
				<div className="title">
					<TitleStyle>{props.title}</TitleStyle>
					{props.subTitle ? (
						<SubTitleStyle>{props.subTitle}</SubTitleStyle>
					) : null}
				</div>
				{props.progress ? (
					<div className="progress">
						<Speedometer
							width={60} height={46}
							value={props.progress.value}
							max={props.progress.max} />
					</div>
				) : null}
			</FlexedLink>
		</UiBadge>
	);
}
