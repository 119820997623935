import React, { useState, useEffect } from 'react';
import * as QuizApi from '~api/quizApi';
import styled from 'styled-components';
import { NoauthUserContentWrapper } from '~containers';
import { Spinner, UiBadge, UiBadge2, UiPhotoGallery2, UserPhotoPreview } from '~components';
import moment from 'moment/moment';
import { Input } from 'semantic-ui-react';
import isMobile from 'is-mobile';
import { useHistory } from 'react-router-dom';
import { getServerUrl, QuestionnaireQuestionTypeEnum, UserRoles } from 'common-lib';
import { useSelector } from 'react-redux';

const QuestionList = styled.div`
	& > div {
		margin-bottom: 1rem;

		&.invalid {
			background: rgba(255, 220, 220, 0.5);
			border-radius: 8px;
			padding: 8px;
			border: 2px solid pink;
		}
	}

	span.__spark {
		margin-left: 0.3rem;
		color: red;
	}

	div.__radioList {
		div {
			cursor: default;
			padding: 0.3rem 0 0.3rem 2rem;
			background-image: url(/assets/img/ui/radio_empty.svg);
			background-repeat: no-repeat;
			background-size: initial;
			background-position: left center;

			&.active {
				background-image: url(/assets/img/ui/radio_checked.svg);
			}
		}
	}

	div.__checkboxList {
		div {
			cursor: default;
			padding: 0.3rem 0 0.3rem 2rem;
			background-image: url(/assets/img/ui/checkbox_empty.svg);
			background-repeat: no-repeat;
			background-size: initial;
			background-position: left center;

			&.active {
				background-image: url(/assets/img/ui/checkbox_checked.svg);
			}
		}
	}
`;

interface QuizData {
	quizName: string;
	quizQuestions: any[];
	respondingAt: Date;
	respondingData: any[];
	isSupervisor: boolean;
	shopExternalId?: string;
	shopName?: string;
	userName: string;
}

type AnswerPhotoType = {
	questionIndex: number,
	fileId: number | string,
};

export default function NoauthUserQuizViewPage(props) {
	const quizReportHashToken: string = props.match.params.quizReportHashToken;
	const subRole: string | undefined = props.match.params?.subRole;
	const subPath: string | undefined = props.match.params?.subPath;
	let serverUrl = '';
	if (subRole) {
		serverUrl = getServerUrl();
	}

	const [quizData, setQuizData] = useState<QuizData>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [showQuestionFileId, setShowQuestionFileId] = useState<number | string>();
	const [showAnswerFileId, setShowAnswerFileId] = useState<AnswerPhotoType>();
	const [answers, setAnswers] = useState<Map<number, any>>();

	const history = useHistory();

	// проверка пользователя
	const meConfig = useSelector((state: any) => state.me?.currentUser);
	const isAdmin = meConfig?.role === UserRoles.ADMIN;
	const isDMmanager = meConfig?.shopManagerRoles !== undefined;

	useEffect(() => {
		setLoading(true);
		QuizApi.getPublicQuizReportResult(quizReportHashToken)
			.then((res) => {
				const answersMap = new Map<number, any>();
				res.data.respondingData.forEach(i => answersMap.set(i.questionIndex, i.value));
				setAnswers(answersMap);
				setQuizData(res.data);
			});
		setLoading(false);
	}, []);

	useEffect(() => {
		if (showQuestionFileId || showAnswerFileId) {
			if (location.hash !== '#preview') {
				location.hash = '#preview';
				history.push(location.pathname + location.hash);
			}
			window.addEventListener('popstate', onBack, false);
		}
	}, [showQuestionFileId, showAnswerFileId]);

	function onBack(e) {
		e.preventDefault();
		setShowQuestionFileId(undefined);
		setShowAnswerFileId(undefined);
		window.removeEventListener('popstate', onBack);
	}

	// клик на фото
	function clickQuestionImage(fileId: number | string) {
		setShowQuestionFileId(fileId);
	}

	function clickAnswerImage(questionIndex: number, fileId: number | string): void {
		setShowAnswerFileId({questionIndex, fileId});
	}

	// скачивание фотографии
	const downloadFile = (fileId: number) => {
		const a = document.createElement('a');
		a.setAttribute('style', 'display: none');
		document.body.appendChild(a);
		a.setAttribute('href', `/api/file/download/${fileId}?size=middle`);
		a.setAttribute('download', String(fileId));
		a.setAttribute('type', 'image/png');
		a.click();
		setTimeout(() => document.body.removeChild(a));
	};

	if(showAnswerFileId && answers && quizData) {
		const { questionIndex, fileId } = showAnswerFileId;
		const answerPhotosIds = answers.get(questionIndex);
		const answerPhotoIndex = answerPhotosIds?.findIndex(p => p === fileId);

		const answerPhotos = answerPhotosIds.map(i => ({
			fileId: i,
			title: moment(quizData.respondingAt).format('DD.MM'),
			subTitle: moment(quizData.respondingAt).format('HH:mm'),
			src: `/api/file/download/${i}?size=middle`,
		}));

		return <UserPhotoPreview
			selectedIndex={Number(answerPhotoIndex)}
			imageList={answerPhotos || []}
			isMobile={isMobile()}
			onDownload={(fileId: number) => downloadFile(fileId)}
			onClose={() => {
				setShowAnswerFileId(undefined);
			}}
		/>;
	}

	if(showQuestionFileId && quizData) {
		const questionPhotoIds = quizData.quizQuestions
			.filter(i => i.photos?.includes(showQuestionFileId))
			.map(i => i.photos)
			.flat();
		const questionIndex = questionPhotoIds.findIndex(p => p === showQuestionFileId);

		const questionPhotos = questionPhotoIds.map(p => {
			return {
				fileId: p,
				title: moment(quizData.respondingAt).format('DD.MM'),
				subTitle: moment(quizData.respondingAt).format('HH:mm'),
				src: `/api/file/download/${p}?size=middle`,
			};
		});

		return <UserPhotoPreview
			selectedIndex={Number(questionIndex)}
			imageList={questionPhotos}
			isMobile={isMobile()}
			onDownload={(fileId: number) => downloadFile(fileId)}
			onClose={() => {
				setShowQuestionFileId(undefined);
			}}
		/>;
	}

	return (
		<>
			{isLoading ? (
				<Spinner onpage text={<div>Загрузка<br />результата опроса...</div>} />
			) : (
				<>
					{quizData && answers ? (
						<NoauthUserContentWrapper
							title={quizData.isSupervisor && subRole !== 'shop' ? `${quizData.userName}` : `${quizData.shopExternalId}  ${quizData.shopName}`}
							subTitle={`Опрос: ${quizData.quizName}`}
							backToSubShop={!isAdmin && !isDMmanager ? (subRole === 'shop' ? `${serverUrl}/quiz-subshop/${subPath}` : `${serverUrl}/quiz-managers/${subPath}`) : undefined}
							copyLink>
							<UiBadge bigPaddings>
								<UiBadge2.Values values={[{
									key: 'Выполнил:',
									value: quizData.userName,
								}]} />
								<UiBadge2.Values values={[{
									key: 'Дата:',
									value: moment(quizData.respondingAt).format('DD.MM.YYYY HH:mm'),
								}]} />
							</UiBadge>
							<UiBadge bigPaddings>
								<h1>{quizData.quizName}</h1> <br />
								<QuestionList>
									{quizData.quizQuestions.map((item, index) => {
										const photoAnswerSrcs: any[] = [];
										const photoQuestSrcs = item.photos?.map(p => {
											return ({
												fileId: p,
												src: `/api/file/download/${p}?size=middle`,
											});
										});
										if (answers.get(index) instanceof Array) {
											answers.get(index)?.forEach(p => {
												return photoAnswerSrcs.push({
													fileId: p,
													src: `/api/file/download/${p}?size=middle`,
												});
											});
										}

										return (
											<div
												key={item.text}
												className={item.isInvalid ? 'invalid' : ''}
												hidden={item.isHidden}
											>
												<h1>{item.text}{item.isRequired ?
													<span className="__spark">*</span> : null}</h1>
												{item.photos?.length ? (
													<div style={{ marginBottom: '5px' }}>
														<UiPhotoGallery2 photos={photoQuestSrcs || []}
														                 onClickImage={clickQuestionImage}
														                 readOnly />
													</div>
												) : null}
												{item.type === QuestionnaireQuestionTypeEnum.STRING &&
                                                    <Input
                                                        fluid
                                                        type="text"
                                                        disabled
                                                        value={answers.get(index)}
                                                        style={{ opacity: 1 }}
                                                    />
												}

												{item.type === QuestionnaireQuestionTypeEnum.FLOAT &&
                                                    <Input
                                                        fluid
                                                        value={answers.get(index)}
                                                        disabled
                                                        type="number"
                                                        style={{ opacity: 1 }}
                                                    />
												}

												{item.type === QuestionnaireQuestionTypeEnum.RADIO ? (
													<div className="__radioList">
														{item.options?.map((option, i) => {
															const checked = answers.get(index) === i.toString();
															return (
																<div
																	key={i}
																	className={checked ? 'active' : ''}
																	>{option}</div>
															);
														})}
													</div>
												) : null}

												{item.type === QuestionnaireQuestionTypeEnum.CHECKERS ? (
													<div className="__checkboxList">
														{item.options?.map((option, i) => {
															const checked = answers.get(index)?.includes(i);
															return (
																<div
																	className={checked ? 'active' : ''}
																	>{option}</div>
															);
														})}
													</div>
												) : null}

												{item.type === QuestionnaireQuestionTypeEnum.PHOTO && (
													<div style={{ marginTop: '10px' }}>
														<UiBadge2.Values values={[{
															key: 'Ответ:',
															value: photoAnswerSrcs.length ? '' : 'Отсутствует',
														}]} />
														<UiPhotoGallery2 photos={photoAnswerSrcs}
														                 readOnly
														                 onClickImage={fileId => clickAnswerImage(index, fileId)} />
													</div>
												)}
											</div>
										);
									})}
								</QuestionList>
							</UiBadge>
						</NoauthUserContentWrapper>
					) : null}
				</>
			)}
		</>
	);
}
