import React, { useState, useEffect } from 'react';
import * as QuizApi from '~api/quizApi';
import styled from 'styled-components';
import { NoauthUserContentWrapper } from '~containers';
import { Spinner, UiBadge, UiBadge2, UiPhotoGallery2, UserPhotoPreview } from '~components';
import moment from 'moment/moment';
import { Input } from 'semantic-ui-react';
import isMobile from 'is-mobile';
import { useHistory } from 'react-router-dom';
import { getServerUrl, QuestionnaireQuestionTypeEnum, UserRoles } from 'common-lib';
import generateId from '~tools/generateId';
import { useSelector } from 'react-redux';

const QuestionList = styled.div`
	& > div {
		margin-bottom: 1rem;

		&.invalid {
			background: rgba(255, 220, 220, 0.5);
			border-radius: 8px;
			padding: 8px;
			border: 2px solid pink;
		}
	}

	span.__spark {
		margin-left: 0.3rem;
		color: red;
	}
`;

const HeaderViewPageStyle = styled.div`
    display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.result {
		white-space: nowrap;
		margin-left: 0.3rem;

		span {
            color: #9696A7;
		}
	}
`;

const InputAnswerStyle = styled.div`
    input {
        border-color: ${p => p.correctAnswer ? '#66D168' : '#D17666'} !important;
    }
`;

const RadioAnswerStyle = styled.div`
    display: flex;
    align-items: center;

    div {
        cursor: default;
        padding: 0.3rem 0 0.3rem 2rem;
        background-image: ${p => p.correctAnswer ? 'url(/assets/img/ui/correct_radio_empty.svg)' : 'url(/assets/img/ui/incorrect_radio_empty.svg)'};
        background-repeat: no-repeat;
        background-size: initial;
        background-position: left center;

        &.active {
            background-image: ${p => p.correctAnswer ? 'url(/assets/img/ui/correct_radio_checked.svg)' : 'url(/assets/img/ui/incorrect_radio_checked.svg)'};
        }
    }
`;

const CheckersAnswerStyle = styled.div`
    display: flex;
    align-items: center;

    div {
        cursor: default;
        padding: 0.3rem 0 0.3rem 2rem;
        background-image: ${p => p.correctAnswer ? 'url(/assets/img/ui/correct_checkbox_empty.svg)' : 'url(/assets/img/ui/incorrect_checkbox_empty.svg)'};
        background-repeat: no-repeat;
        background-size: initial;
        background-position: left center;

        &.active {
            background-image: ${p => p.correctAnswer ? 'url(/assets/img/ui/correct_checkbox_checked.svg)' : 'url(/assets/img/ui/incorrect_checkbox_checked.svg)'};
        }
    }
`;

interface QuizData {
	quizName: string;
	quizType: string;
	quizQuestions: any[];
	respondingAt: Date;
	respondingData: any[];
	respondingResult: number;
	isSupervisor: boolean;
	shopExternalId?: string;
	shopName?: string;
	userName: string;
}

export default function NoauthUserTestViewPage(props) {
	const testReportHashToken: string = props.match.params.testReportHashToken;
	const subRole: string | undefined = props.match.params?.subRole;
	const subPath: string | undefined = props.match.params?.subPath;
	// проверка пользователя
	const meConfig = useSelector((state: any) => state.me?.currentUser);
	const isAdmin = meConfig?.role === UserRoles.ADMIN;
	const isDMmanager = meConfig?.shopManagerRoles !== undefined;
	let serverUrl = '';
	if (subRole) {
		serverUrl = getServerUrl();
	}
 	const [quizData, setQuizData] = useState<QuizData>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [showQuestionFileId, setShowQuestionFileId] = useState<number | string>();
	const [answers, setAnswers] = useState<Map<number, any>>();

	const history = useHistory();

	useEffect(() => {
		setLoading(true);
		QuizApi.getPublicQuizReportResult(testReportHashToken)
			.then((res) => {
				const answersMap = new Map<number, any>();
				res.data.respondingData.forEach(i => answersMap.set(i.questionIndex, i.value));
				setAnswers(answersMap);
				setQuizData(res.data);
			});
		setLoading(false);
	}, []);

	useEffect(() => {
		if (showQuestionFileId) {
			if (location.hash !== '#preview') {
				location.hash = '#preview';
				history.push(location.pathname + location.hash);
			}
			window.addEventListener('popstate', onBack, false);
		}
	}, [showQuestionFileId]);

	function onBack(e) {
		e.preventDefault();
		setShowQuestionFileId(undefined);
		window.removeEventListener('popstate', onBack);
	}

	// клик на фото
	function clickQuestionImage(fileId: number | string) {
		setShowQuestionFileId(fileId);
	}


	// скачивание фотографии
	const downloadFile = (fileId: number) => {
		const a = document.createElement('a');
		a.setAttribute('style', 'display: none');
		document.body.appendChild(a);
		a.setAttribute('href', `/api/file/download/${fileId}?size=middle`);
		a.setAttribute('download', String(fileId));
		a.setAttribute('type', 'image/png');
		a.click();
		setTimeout(() => document.body.removeChild(a));
	};

	if(showQuestionFileId && quizData) {
		const questionPhotoIds = quizData.quizQuestions
			.filter(i => i.photos?.includes(showQuestionFileId))
			.map(i => i.photos)
			.flat();
		const questionIndex = questionPhotoIds.findIndex(p => p === showQuestionFileId);

		const questionPhotos = questionPhotoIds.map(p => {
			return {
				fileId: p,
				title: moment(quizData.respondingAt).format('DD.MM'),
				subTitle: moment(quizData.respondingAt).format('HH:mm'),
				src: `/api/file/download/${p}?size=middle`,
			};
		});

		return <UserPhotoPreview
			selectedIndex={Number(questionIndex)}
			imageList={questionPhotos}
			isMobile={isMobile()}
			onDownload={(fileId: number) => downloadFile(fileId)}
			onClose={() => {
				setShowQuestionFileId(undefined);
			}}
		/>;
	}

	return (
		<>
			{isLoading ? (
				<Spinner onpage text={<div>Загрузка<br />результата теста...</div>} />
			) : (
				<>
					{quizData && answers ? (
						<NoauthUserContentWrapper
							title={quizData.isSupervisor && subRole !== 'shop' ? `${quizData.userName}` : `${quizData.shopExternalId}  ${quizData.shopName}`}
							subTitle={`Тест: ${quizData.quizName}`}
							backToSubShop={!isAdmin && !isDMmanager ? (subRole === 'shop' ? `${serverUrl}/quiz-subshop/${subPath}` : `${serverUrl}/quiz-managers/${subPath}`) : undefined}
							copyLink>
							<UiBadge bigPaddings>
								<HeaderViewPageStyle>
									<div>
										<UiBadge2.Values values={[{
											key: 'Выполнил:',
											value: quizData.userName,
										}]} />
										<UiBadge2.Values values={[{
											key: 'Дата:',
											value: moment(quizData.respondingAt).format('DD.MM.YYYY HH:mm'),
										}]} />
									</div>
									<div className="result">
										<span>Результат:</span> {quizData.respondingResult}%
									</div>
								</HeaderViewPageStyle>
							</UiBadge>
							<UiBadge bigPaddings>
								<h1>{quizData.quizName}</h1> <br />
								<QuestionList>
									{quizData.quizQuestions.map((item, index) => {
										const photoAnswerSrcs: any[] = [];
										const photoQuestSrcs = item.photos?.map(p => {
											return ({
												fileId: p,
												src: `/api/file/download/${p}?size=middle`,
											});
										});
										if (answers.get(index) instanceof Array) {
											answers.get(index)?.forEach(p => {
												return photoAnswerSrcs.push({
													fileId: p,
													src: `/api/file/download/${p}?size=middle`,
												});
											});
										}

										return (
											<div
												key={`${generateId()}-${item.text}`}
												className={item.isInvalid ? 'invalid' : ''}
											>
												<h1>{item.text}</h1>
												{item.photos?.length ? (
													<div style={{ marginBottom: '5px' }}>
														<UiPhotoGallery2 photos={photoQuestSrcs || []}
														                 onClickImage={clickQuestionImage}
														                 readOnly />
													</div>
												) : null}

												{item.type === QuestionnaireQuestionTypeEnum.FLOAT &&
													<>
														<span style={{ marginBottom: '3px' }}>Правильный ответ: {item.correctAnswer}</span>
														<InputAnswerStyle  correctAnswer={answers.get(index) === item.correctAnswer}>
	                                                        <Input
	                                                            fluid
	                                                            value={answers.get(index)}
	                                                            disabled
	                                                            type="number"
	                                                            style={{ opacity: 1 }}
	                                                        />
														</InputAnswerStyle>
													</>
												}

												{item.type === QuestionnaireQuestionTypeEnum.RADIO ? (
													<div className="__radioList">
														{item.options?.map((option, i) => {
															const checked = answers.get(index) === option.value.toString();
															return (
																<RadioAnswerStyle key={`${option.value}-${generateId()}-${i}`} correctAnswer={option.correctAnswer}>
																	<div className={checked ? 'active' : ''}>
																		{option.value}
																	</div>
																</RadioAnswerStyle>
															);
														})}
													</div>
												) : null}

												{item.type === QuestionnaireQuestionTypeEnum.CHECKERS ? (
													<div className="__checkboxList">
														{item.options?.map((option, i) => {
															const indexAnswers = answers.get(index)?.map(a => a.answerIndex);
															const checked = indexAnswers?.includes(option.index);
															return (
																<CheckersAnswerStyle key={`${option.value}-${generateId()}-${i}`} correctAnswer={option.correctAnswer}>
																	<div className={checked ? 'active' : ''}>
																			{option.value}
																	</div>
																</CheckersAnswerStyle>
															);
														})}
													</div>
												) : null}

											</div>
										);
									})}
								</QuestionList>
							</UiBadge>
						</NoauthUserContentWrapper>
					) : null}
				</>
			)}
		</>
	);
}
