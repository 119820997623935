import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import appReducers from './reducers';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function getStore() {
	const store = createStore(
		combineReducers({ ...appReducers }),
		{},
		composeEnhancer(applyMiddleware(thunk)),
	);
	if (module.hot) {
		module.hot.accept('./reducers', () => {
			const nextRootReducer = require('./reducers');
			store.replaceReducer(() => nextRootReducer);
		});
	}
	return store;
}

export default getStore();
