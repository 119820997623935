import { throwIfExcessKeys } from 'common-lib';
import { fetchGetThenJson } from '~tools/fetchBackend';

const LIST_AVAIL_KEYS = ['page', 'order', 'search', 'meta'];

/**
 * Запрос истории импорта инцидентов.
 * @returns {Promise<unknown>}
 */
export async function getDashboardIncidentImportList(pageInfo) {
	throwIfExcessKeys(pageInfo.toQueryObject(), LIST_AVAIL_KEYS, 'getDashboardIncidentImportList.pageInfo');
	const query = pageInfo.toQueryString();
	return fetchGetThenJson(`dashboard/incident/import${query ? `?${query}` : ''}`);
}

/**
 * Запрос истории импорта структуры.
 * @returns {Promise<unknown>}
 */
export async function getDashboardStructureImportList(pageInfo) {
	throwIfExcessKeys(pageInfo.toQueryObject(), LIST_AVAIL_KEYS, 'getDashboardStructureImportList.pageInfo');
	const query = pageInfo.toQueryString();
	return fetchGetThenJson(`dashboard/structure/import${query ? `?${query}` : ''}`);
}
