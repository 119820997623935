import { PageInfo } from 'common-lib';
import BaseFetcher from './BaseFetcher';

export default class TasksFetcher extends BaseFetcher
{
	constructor() {
		super('tasks');
	}

	/**
	 * Запрос списка задач для магазина
	 * @param shopId
	 * @param pageInfo
	 */
	async getListByShopId(shopId: number, pageInfo?: PageInfo) {
		if (!shopId) {
			throw new Error(`Параметр shopId не может быть пустым при вызове ${this.constructor.name}.getListByShopId`);
		}
		const query = pageInfo?.toQueryString();
		return this.fetchGetThenJson(`${this.basePath}/list?shopId=${shopId}${query ? `&${query}` : ''}`);
	}

	/**
	 * Запрос задачи для магазина
	 * @param id
	 * @param shopId
	 */
	async getOneByShopId(id: number, shopId: number) {
		if (!id) {
			throw new Error(`Параметр id не может быть пустым при вызове ${this.constructor.name}.getOneByShopId`);
		}
		if (!shopId) {
			throw new Error(`Параметр shopId не может быть пустым при вызове ${this.constructor.name}.getOneByShopId`);
		}
		return this.fetchGetThenJson(`${this.basePath}/one/${id}?shopId=${shopId}`);
	}
}
