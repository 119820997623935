import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ChecklistTaskStatusEnum, TaskStatusEnum } from 'common-lib';
import moment from 'moment';

const BadgeStyled = styled.div`
	display: block;
	padding: 5px 8px;
	margin-bottom: 8px;
	border-radius: 16px;
	color: inherit;
	text-decoration: none;
	background-color: white;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	cursor: inherit;

	&.big-paddings {
		padding: 13px 16px;
	}

	${p => p.color ? `border-left: 10px solid ${p.color};` : ''}
	&:hover {
		color: inherit;
		text-decoration: none;
	}

	&.clickable {
		cursor: pointer;

		// для веба разрешаем менять цвет текста и иконок при наведении
		@media (min-width: 900px) {
			&:hover {
				box-shadow: 0 1px 3px 1px rgba(20, 20, 20, 0.5);
			}
		}
	}

	h1 {
		color: #2C2E4E;
		margin-bottom: 3px;
	}

	div.description {
		color: #9696A7;
	}
`;

type Props = {
	linkTo?: string     // ссылка для перехода
	onClick?: Function  // обработчик клика
	children: ReactNode
	color?: string      // цвет ярлыка
	bigPaddings?: boolean
}

export default function UiBadge(props: Props) {
	const cls = [
		props.onClick || props.linkTo ? 'clickable' : undefined,
		props.bigPaddings ? 'big-paddings' : undefined,
	].filter(i => !!i).join(' ') || undefined;
	return (
		<BadgeStyled as={props.linkTo ? Link : undefined}
		             to={props.linkTo}
		             onClick={props.onClick}
		             className={cls}
		             color={props.color}>
			{props.children}
		</BadgeStyled>
	);
}

const KeyValueStyled = styled.div`
	display: flex;
	flex-direction: column;

	.key {
		display: inline-block;
		min-width: 66px;
		margin-right: 10px;
		color: #9696A7;
		font-size: 13px;
		line-height: 16px;
	}

	.value {
		display: inline-block;
		font-size: 13px;
		line-height: 16px;
	}
`;

type UiBadgeValuesValue = {
	key: string
	value: string
}

type UiBadgeValuesProps = {
	values: Array<UiBadgeValuesValue>
}

UiBadge.Values = function UiBadgeValues(props: UiBadgeValuesProps) {
	return (
		<>
			{props.values.map((kv, index) => (
				<KeyValueStyled key={`${index}_${kv.key}`}>
					<div className="key">{kv.key}</div>
					<div className="value">{kv.value}</div>
				</KeyValueStyled>
			))}
		</>
	);
}

const StatusStyle = styled.div`
	position: relative;
	z-index: 0;
	height: 24px;
	margin-bottom: 4px;

	.due-date {
		position: absolute;
		left: 0;
		bottom: 0;
		color: #9696A7;
		font-size: 12px;

		&::before {
			content: ' ';
			display: inline-block;
			margin-right: 8px;
			margin-bottom: -3px;
			background-image: url(/assets/img/calendar.svg);
			background-repeat: no-repeat;
			background-position: center;
			width: 16px;
			height: 16px;
		}
	}

	.status {
		position: absolute;
		right: 0;
		bottom: 0;
		font-size: 10px;
		font-weight: bold;
		padding: 2px 8px;
		border-radius: 16px;
		color: gray;
		background-color: #ddd;
		text-transform: uppercase;

		&.IN_PROGRESS, &.REJECTED {
			color: #FEC679;
			background-color: #FFF5E7;
		}

		&.DONE {
			color: #89BC96;
			background-color: #ECF5EF;
		}

		&.EXPIRED, &.REJECTED {
			color: #DC9091;
			background-color: #F6ECEC;
		}
	}
`;

type BadgeStatus = ChecklistTaskStatusEnum | TaskStatusEnum;

type UiBadgeDueDateAndStatusProps = {
	dueDate: Date       // срок выполнения
	status: BadgeStatus // статус
	statusText: string
}

UiBadge.DueDateAndStatus = function UiBadgeDueDateAndStatus(props: UiBadgeDueDateAndStatusProps) {
	return (
		<StatusStyle>
			<div className="due-date">{calcDueDateText(props.dueDate)}</div>
			<div className={`status ${props.status}`}>{props.statusText}</div>
		</StatusStyle>
	);
}

function calcDueDateText(dueDate: Date) {
	const mom = moment(dueDate);
	const date = mom.format('DD.MM.YYYY');
	const left = mom.lang('ru').fromNow();
	return `до ${date} (${left})`;
}

/**
 * UiBadge.DateTimeAndImportant
 */

const ImportantStyled = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	font-size: 12px;
	color: #9696A7;

	.date, .time, .is-important {
		&::before {
			content: ' ';
			display: inline-block;
			margin-right: 9px;
			margin-bottom: -5px;
			background-repeat: no-repeat;
			background-position: center;
			width: 18px;
			height: 18px;
		}
	}

	.date {
		display: inline-block;
		width: 105px;

		&::before {
			background-image: url(/assets/img/calendar.svg);
		}
	}

	.time {
		display: inline-block;

		&::before {
			background-image: url(/assets/img/clock.svg);
		}
	}

	.is-important {
		color: #F53737;
		font-weight: 500;

		&::before {
			background-image: url(/assets/img/red-important.svg);
		}
	}
`;

type UiBadgeDateTimeAndImportantProps = {
	date: Date
	isImportant?: boolean
}

UiBadge.DateTimeAndImportant = function UiBadgeDateTimeAndImportant(props: UiBadgeDateTimeAndImportantProps) {
	const moment1 = moment(props.date);
	const date = moment1.format('DD.MM.YYYY');
	const time = moment1.format('HH:mm');
	return (
		<ImportantStyled>
			<div>
				<span className="date">{date}</span>
				<span className="time">{time}</span>
			</div>
			{props.isImportant ? (
				<div className="is-important">Важное</div>
			) : null}
		</ImportantStyled>
	)
}

type UiBadgeTextWithStatusProps = {
	text: string
	status?: BadgeStatus // статус
	statusText: string
}

UiBadge.TextWithStatus = function UiBadgeTextWithStatus(props: UiBadgeTextWithStatusProps) {
	return (
		<StatusStyle>
			<div>{props.text}</div>
			<div className={`status ${props.status}`}>{props.statusText}</div>
		</StatusStyle>
	);
}


const DateTimePeriodStyled = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	font-size: 12px;
	color: #9696A7;

	.date, .time, .is-important {
		&::before {
			content: ' ';
			display: inline-block;
			margin-right: 2px;
			margin-bottom: -5px;
			background-repeat: no-repeat;
			background-position: center;
			width: 18px;
			height: 18px;
		}
	}

	.date {
		display: inline-block;
		width: 90px;

		&::before {
			background-image: url(/assets/img/calendar.svg);
		}
	}

	.time {
		display: inline-block;

		&::before {
			background-image: url(/assets/img/clock.svg);
		}
	}

	.is-important {
		color: #F53737;
		font-weight: 500;

		&::before {
			background-image: url(/assets/img/red-important.svg);
		}
	}
`;

type UiBadgeDateTimePeriod = {
	dateStart: Date
	dateFinish: Date
}

UiBadge.DateTimePeriod = function UiBadgeDateTimePeriod(props: UiBadgeDateTimePeriod) {
	const moment1 = moment(props.dateStart);
	const moment2 = moment(props.dateFinish);
	const dateStart = moment1.format('DD.MM.YYYY');
	const timeStart = moment1.format('HH:mm');
	const dateFinish = moment2.format('DD.MM.YYYY');
	const timeFinish = moment2.format('HH:mm');
	return (
		<DateTimePeriodStyled>
			<div>
				<span className="date">{dateStart}</span>
				<span className="time">{timeStart}</span>
				{' - '}
				<span className="date">{dateFinish}</span>
				<span className="time">{timeFinish}</span>
			</div>
		</DateTimePeriodStyled>
	)
}

type UiBadgeQuizCount = {
	leftCount: number;
	repeatCount: number;
}

UiBadge.QuizCount = function UiBadgeQuizCount(props: UiBadgeQuizCount) {
	const { leftCount, repeatCount } = props;
	return (
		<ImportantStyled>
			<div>
				<span style={{ marginRight: '2rem' }}>Осталось попыток: {leftCount}</span>
				<span>Всего попыток: {repeatCount}</span>
			</div>
		</ImportantStyled>
	)
}
