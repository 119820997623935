import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Button, Header, Icon, Input, Modal } from 'semantic-ui-react';
import AddresseeSelector, { CheckedSubordinatesValue } from '~components/ui/AddresseeSelector';
import * as ShopsApi from '~api/shopsApi';
import * as ConfigApi from '~api/featureConfigurationsApi';
import { fillSubordinateMap } from '../../lib/subordinateTools';
import { Spinner } from '~components/smallComponents';

const AdminConfigurationCreatePage = (props) => {
	const initialCheckedSubordinatesInfo: CheckedSubordinatesValue = {
		checkedShops: new Set<number>(),
		checkedUsers: new Set<number>(),
		checkedNotallUsers: new Set<number>(),
		openedBranches: new Set<number>(),
		branchSelectedCount: new Map<number, number>(),
	};

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [step, setStep] = React.useState<number>(1);
	const [featureTitle, setFeatureTitle] = React.useState<string>('');
	const [isShowConfirmCancel, setIsShowConfirmCancel] = React.useState<boolean>(false);
	const [enabledAll, setEnabledAll] = React.useState(false);

	const [subordinates, setSubordinates] = React.useState<any>({});
	const [checkedSubordinatesInfo, setCheckedSubordinatesInfo] = React.useState<CheckedSubordinatesValue>(
		initialCheckedSubordinatesInfo,
	);

	const configId = props.match.params.id;

	const history = useHistory();

	function loadSubordinateItems(): Promise<void> {
		return ShopsApi.loadSubordinateShops().then((res) => {
			setSubordinates(fillSubordinateMap(res));
		});
	}

	function loadConfig(id: number): void {
		ConfigApi.fetchFeatureConfiguration(id).then((res) => {
			setFeatureTitle(res.data.title);
			setEnabledAll(res.data.enabledAll);
			fillConfig(res.data);
			setIsLoading(false);
		});
	}

	function fillConfig(data: any): void {
		const filledCheckedSubordinatesInfo = { ...initialCheckedSubordinatesInfo };
		filledCheckedSubordinatesInfo.checkedShops = new Set<number>(data.shopIds);

		setCheckedSubordinatesInfo(filledCheckedSubordinatesInfo);
	}

	function changeShopSelection(checkedSubordinates: CheckedSubordinatesValue): void {
		setCheckedSubordinatesInfo({ ...checkedSubordinates });
	}

	function save(): void {
		const payload = {
			enabledAll,
			shopIds: !enabledAll ? Array.from(checkedSubordinatesInfo.checkedShops) : null,
		};

		ConfigApi.updateFeatureConfiguration(configId, payload).then(() => {
			history.push('/admin/configuration');
		});
	}

	function cancel(): void {
		setIsShowConfirmCancel(false);
		history.push('/admin/configuration');
	}

	React.useEffect(() => {
		setIsLoading(false);
		loadSubordinateItems().then(() => {
			if (configId) {
				loadConfig(configId);
			}
		});
		loadSubordinateItems();
		setIsLoading(true);
	}, []);

	return (
		<>
			{isLoading ? (
				<Spinner />
			) : (
				<>
					<Link style={{ display: 'inline-block', marginBottom: '0.3rem' }} to="/admin/configuration">
						<Icon name="arrow left" />
						Вернуться в конфигуратор бета-функционала
					</Link>
					<div style={{ marginBottom: '1rem' }}>
						<Header
							as="h3"
							icon="settings"
							content={`${configId && 'Редактирование фичи: ' + featureTitle}`}
						/>
					</div>
					<Breadcrumb>
						<Breadcrumb.Section
							active={step === 1}
							onClick={
								step !== 1
									? () => {
											setStep(1);
									  }
									: undefined
							}>
							Магазины
						</Breadcrumb.Section>
						<Breadcrumb.Divider content={<span>&nbsp;|&nbsp;</span>} />
						<Breadcrumb.Section active={step === 2} onClick={() => {}}>
							Настройки
						</Breadcrumb.Section>
					</Breadcrumb>
					<div style={{ margin: '1rem 0' }}>
						<label>
							<Input
								type="checkbox"
								onClick={() => setEnabledAll(!enabledAll)}
								defaultChecked={enabledAll}
							/>{' '}
							Включить для всех
						</label>
					</div>
					{enabledAll ? (
						''
					) : (
						/* Step 1 */
						<div hidden={step !== 1}>
							<AddresseeSelector
								style={{ marginTop: '1rem' }}
								subordinates={subordinates}
								treeInfo={checkedSubordinatesInfo}
								onChange={changeShopSelection}
							/>
						</div>
					)}
					<div className="actions">
						<Button
							icon="arrow left"
							labelPosition="left"
							content="Отмена"
							onClick={() => setIsShowConfirmCancel(true)}
						/>
						<Button positive icon="save" labelPosition="left" content="Сохранить" onClick={save} />
					</div>
					{isShowConfirmCancel ? (
						<Modal open>
							<Modal.Content>
								<p>Данные не будут сохранены.</p>
								<p>Вы действительно хотите выйти?</p>
							</Modal.Content>
							<Modal.Actions>
								<Button positive onClick={() => setIsShowConfirmCancel(false)} content="Нет" />
								<Button onClick={cancel} content="Да" />
							</Modal.Actions>
						</Modal>
					) : null}
				</>
			)}
		</>
	);
};

export default AdminConfigurationCreatePage;
