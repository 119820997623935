import { Subordinates, SubordinateShop, SubordinateUser } from '~components/ui/AddresseeSelector';
import { QuestionnaireExecutionStatusEnum } from 'common-lib';

interface IExecOrReportData {
	shopId?: number;
	respondingUserId?: number;
	status: QuestionnaireExecutionStatusEnum.RUNNING
		| QuestionnaireExecutionStatusEnum.FINISHED
		| QuestionnaireExecutionStatusEnum.REPORTED
		| QuestionnaireExecutionStatusEnum.STOPPED
		| QuestionnaireExecutionStatusEnum.CANCELED;
	quizResultHashCode: string;
	respondingResult: number | null;
}

function sum(arr: number[]) {
	return arr.reduce((s, v) => s + v, 0);
}

// заполняет список подчиненных сотрудников и магазинов
export function fillSubordinateMap(data: any, quizData?: any): Subordinates {
	const userMap = new Map<number, SubordinateUser>();
	const shopMap = new Map<number, SubordinateShop>();
	const answerData: IExecOrReportData[] = quizData?.reportData || quizData?.execData;
	const answerDataMap = new Map<number, any>();
	answerData?.forEach(item => {
		if (item.shopId) {
			if (!answerDataMap.has(item.shopId)) {
				answerDataMap.set(item.shopId, []);
			}
			answerDataMap.get(item.shopId).push(item);
		} else if (item.respondingUserId) {
			if (!answerDataMap.has(item.respondingUserId)) {
				answerDataMap.set(item.respondingUserId, []);
			}
			answerDataMap.get(item.respondingUserId).push(item);
		}
	});

	function fill(user: any, parent?: any) {
		// добавляем поля для пользователя
		const { subordinateUsers, subordinateShops } = user;
		subordinateShops?.forEach(shop => {
			shopMap.set(shop.id, shop);
		});
		subordinateUsers?.forEach(subUser => {
			subUser.shortName = subUser.fullName.split(' ')
				.map((s, i) => i > 0 ? s.substring(0, 1) + '.' : s)
				.join(' ');
			subUser.parent = parent;
			userMap.set(subUser.id, subUser);
			fill(subUser, subUser); // fixme fill(subUser, parent); ?
			const a = subUser.subordinateShops?.length || 0;
			const b = subUser.subordinateUsers?.map(i => i.shopCount);
			subUser.shopCount = a + sum(b || [0]);
			subUser.userCount = subUser.subordinateUsers?.length || 0;
		});
		// сортируем подчиненных
		user.subordinateUsers = user.subordinateUsers?.sort((a, b) => a.shortName > b.shortName ? 1
			: a.shortName < b.shortName ? -1 : 0);
		user.subordinateShops = user.subordinateShops
			?.sort((a, b) => a.city > b.city ? 1 : a.city < b.city ? -1 : a.address > b.address ? 1 : a.address < b.address ? -1 : 0)
			.map(shop => {
				shop.supervisorUser = user;
				return shop;
			});
	}

	fill(data);
	return { ...data, userMap, shopMap };
}

export function fillSubordinateMap2(data: any, quizData: any): Subordinates {
	const userMap = new Map<number, SubordinateUser>();
	const shopMap = new Map<number, SubordinateShop>();
	const managerIdsSet = new Set<number>();
	const answerData: IExecOrReportData[] = quizData.reportData || quizData.execData;
	const answerDataMap = new Map<number, any>();
	let checkSupervisor = false;
	answerData?.forEach(item => {
		if (item.shopId) {
			if (!answerDataMap.has(item.shopId)) {
				answerDataMap.set(item.shopId, []);
			}
			answerDataMap.get(item.shopId).push(item);
		} else if (item.respondingUserId) {
			checkSupervisor = true;
			if (!answerDataMap.has(item.respondingUserId)) {
				answerDataMap.set(item.respondingUserId, []);
			}
			answerDataMap.get(item.respondingUserId).push(item);
		}
	});

	function fill(user: any, parent?: any) {
		// добавляем поля для пользователя
		const { subordinateUsers, subordinateShops } = user;
		subordinateShops?.forEach(shop => {
			shopMap.set(shop.id, shop);
		});
		subordinateUsers?.forEach(subUser => {
			if (checkSupervisor) {
				// менеджеры
				subUser.shortName = subUser.fullName.split(' ')
					.map((s, i) => i > 0 ? s.substring(0, 1) + '.' : s)
					.join(' ');
				subUser.parent = parent;
				userMap.set(subUser.id, subUser);
				fill(subUser, subUser); // fixme fill(subUser, parent); ?

				subUser.userCount = subUser.subordinateUsers?.length || 0;
				subUser.shopCount = (subUser.subordinateShops?.length || 0)
					+ sum(subUser.subordinateUsers?.map(i => i.shopCount) || [0]);
				subUser.quizCount = sum(subUser.subordinateUsers?.map(i => i.quizCount) || [0]);
				subUser.answeredCount = sum(subUser.subordinateUsers?.map(i => i.answeredCount) || [0]);

				let allTestResult = 0;
				let allAnsweredCount = 0;
				subUser.subordinateUsers?.forEach(user1 => {
					if (user1.answeredCount) {
						allTestResult += user1.testResults * user1.answeredCount;
						allAnsweredCount += user1.answeredCount;
					}
				});
				subUser.testResults = allAnsweredCount ? allTestResult / allAnsweredCount : null;
			} else {
				// магазины
				subUser.shortName = subUser.fullName.split(' ')
					.map((s, i) => i > 0 ? s.substring(0, 1) + '.' : s)
					.join(' ');
				subUser.parent = parent;
				userMap.set(subUser.id, subUser);
				fill(subUser, subUser); // fixme fill(subUser, parent); ?

				subUser.userCount = subUser.subordinateUsers?.length || 0;
				subUser.shopCount = (subUser.subordinateShops?.length || 0)
					+ sum(subUser.subordinateUsers?.map(i => i.shopCount) || [0]);
				subUser.quizCount = sum(subUser.subordinateUsers?.map(i => i.quizCount) || [0])
					+ sum(subUser.subordinateShops?.map(i => i.quizCount) || [0]);
				subUser.answeredCount = sum(subUser.subordinateUsers?.map(i => i.answeredCount) || [0])
					+ sum(subUser.subordinateShops?.map(i => i.answeredCount) || [0]);

				let allTestResult = 0;
				let allAnsweredCount = 0;
				subUser.subordinateShops?.forEach(shop => {
					if (shop.answeredCount) {
						allTestResult += shop.testResults * shop.answeredCount;
						allAnsweredCount += shop.answeredCount;
					}
				});
				subUser.subordinateUsers?.forEach(user1 => {
					if (user1.answeredCount) {
						allTestResult += user1.testResults * user1.answeredCount;
						allAnsweredCount += user1.answeredCount;
					}
				});
				subUser.testResults = allAnsweredCount ? allTestResult / allAnsweredCount : null;
			}
		});
		if (checkSupervisor) {
			// сортируем подчиненных для менеджеров
			user.subordinateUsers = user.subordinateUsers
				?.sort((a, b) => a.shortName > b.shortName ? 1 : a.shortName < b.shortName ? -1 : 0)
				.map(manager => {
					manager.supervisorUser = user;
					manager.answeredData = answerDataMap.get(manager.id)[0];
					manager.quizCount = 1; // у менеджеров только одна попытка
					manager.answeredCount = manager.answeredData.status === QuestionnaireExecutionStatusEnum.FINISHED ? 1 : 0;
					if (!managerIdsSet.has(manager.id)) {
						managerIdsSet.add(manager.id);
					}
					manager.testResults = manager.answeredData.respondingResult;
					return manager;
				});
		} else {
			// сортируем подчиненных для магазинов
			user.subordinateUsers = user.subordinateUsers?.sort((a, b) => a.shortName > b.shortName ? 1
				: a.shortName < b.shortName ? -1 : 0);
			user.subordinateShops = user.subordinateShops
				?.sort((a, b) => a.city > b.city ? 1 : a.city < b.city ? -1 : a.address > b.address ? 1 : a.address < b.address ? -1 : 0)
				.map(shop => {
					shop.supervisorUser = user;
					shop.answeredData = answerDataMap.get(shop.id);
					shop.quizCount = shop.answeredData.length;
					shop.answeredCount = shop.answeredData.filter(i => i.status === QuestionnaireExecutionStatusEnum.FINISHED).length;
					shop.testResults = shop.answeredCount
						? sum(shop.answeredData.map(i => i.respondingResult)) / shop.answeredCount
						: null;
					return shop;
				});
		}
	}

	fill(data);
	return { ...data, userMap, shopMap };
}

export function fillSubordinateMapForStatisticManager(data: any, answerData: IExecOrReportData[]): Subordinates {
	const userMap = new Map<number, SubordinateUser>();
	const shopMap = new Map<number, SubordinateShop>();
	const answerDataMap = new Map<number, any>();
	answerData?.forEach(item => {
		if (item.shopId) {
			if (!answerDataMap.has(item.shopId)) {
				answerDataMap.set(item.shopId, []);
			}
			answerDataMap.get(item.shopId).push(item);
		}
	});

	function fill(user: any, parent?: any) {
		// добавляем поля для пользователя
		const { subordinateUsers, subordinateShops } = user;
		subordinateShops?.forEach(shop => {
			shopMap.set(shop.id, shop);
		});
		subordinateUsers?.forEach(subUser => {
			subUser.shortName = subUser.fullName.split(' ')
				.map((s, i) => i > 0 ? s.substring(0, 1) + '.' : s)
				.join(' ');
			subUser.parent = parent;
			userMap.set(subUser.id, subUser);
			fill(subUser, subUser);
			const a = subUser.subordinateShops?.length || 0;
			const b = subUser.subordinateUsers?.map(i => i.shopCount);
			subUser.shopCount = a + sum(b || [0]);
			subUser.userCount = subUser.subordinateUsers?.length || 0;
			subUser.quizCount = sum(subUser.subordinateUsers?.map(i => i.quizCount) || [0])
				+ sum(subUser.subordinateShops?.map(i => i.quizCount) || [0]);
			subUser.answeredCount = sum(subUser.subordinateUsers?.map(i => i.answeredCount) || [0])
				+ sum(subUser.subordinateShops?.map(i => i.answeredCount) || [0]);
			subUser.testResults = subUser.subordinateUsers?.every(i => i.testResults === null) || subUser.subordinateShops?.every(i => i.testResults === null)
				? null
				: Math.floor((sum(subUser.subordinateUsers?.map(i => i.testResults) || [0]) / subUser.subordinateUsers?.length || 0))
				+ Math.floor((sum(subUser.subordinateShops?.map(i => i.testResults) || [0]) / subUser.subordinateShops?.length || 0));
		});
		user.subordinateUsers = user.subordinateUsers?.sort((a, b) => a.shortName > b.shortName ? 1
			: a.shortName < b.shortName ? -1 : 0);
		user.subordinateShops = user.subordinateShops
			?.sort((a, b) => a.city > b.city ? 1 : a.city < b.city ? -1 : a.address > b.address ? 1 : a.address < b.address ? -1 : 0)
			.map(shop => {
				shop.supervisorUser = user;
				shop.answeredData = answerDataMap.get(shop.id);
				shop.quizCount = shop.answeredData.length;
				shop.answeredCount = shop.answeredData.filter(i => i.status === QuestionnaireExecutionStatusEnum.FINISHED).length;
				shop.testResults = checkEveryOnNull(shop)
					? null
					: Math.floor(sum(shop.answeredData.map(i => i.respondingResult || 0)) / shop.answeredData.length);
				return shop;
			});
	}

	fill(data);
	return { ...data, userMap, shopMap };
}



function checkEveryOnNull(arr): boolean {
	return arr.answeredData.every(i => i.respondingResult === null);
}
