import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import { copyText } from '~tools/copyText';
import { Nowrap } from '~components';

/**
 * Выполняет обогащение данных для таблицы.
 */
export const fillTableData = ({ data: rows = [], pageInfo }) => {
	return { rows, pageInfo };
};

export const TABLE_HEADERS = {
	id: {
		title: '#',
		style: { maxWidth: '150px' },
		viewFunc: (row, hl) => (
			<Popup
				content="Нажми, чтобы скопировать"
				trigger={
					<Nowrap
						style={{ cursor: 'pointer' }}
						onClick={(event) => {
							event.stopPropagation();
							copyText(row.id);
						}}>
						{hl(row.id)}
						<Icon name="copy" style={{ color: '#aaa', fontSize: '0.9em', float: 'right' }} />
					</Nowrap>
				}
			/>
		),
	},
	title: {
		title: 'Название фуникцонала',
		viewFunc: (row) => <Link to={`/admin/configuration/edit/${row.id}`}>{row.title}</Link>,
	},
	enabledAll: {
		title: 'Включено у всех',
		style: { maxWidth: '150px' },
		viewFunc: (row) => <Nowrap>{row.enabledAll ? 'да' : 'нет'}</Nowrap>,
	},
	$shopIds: {
		title: 'Включено в магазинах',
		style: { maxWidth: '200px' },
		viewFunc: (row) => {
			return <Nowrap>{row?.shopIds?.length || 0}</Nowrap>;
		},
	},
};
