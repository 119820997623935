import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdminUserLayout from '../layouts/AdminUserLayout';
import SupportUserLayout from '../layouts/SupportUserLayout';
import UserLayout2 from '../layouts/UserLayout2';
import {
	AdminDiagnosticsFileGalleryPage,
	AdminDiagnosticsIncidentMarkingPage,
	AdminDiagnosticsRequestTestingPage,
	AdminDiagnosticsPage,
	AdminIncidentImportPage,
	AdminIncidentsPage,
	AdminIncidentTypeEditPage,
	AdminIncidentTypesTablePage,
	AdminInfoMessageEditPage,
	AdminInfoMessagesTablePage,
	AdminShopEditPage,
	AdminShopsTablePage,
	AdminUserEditPage,
	AdminUsersTablePage,
	DashboardIncidentImportsTablePage,
	DashboardPage,
	DashboardStructureImportsTablePage,
	PageNotFound,
	UserIncidentSectionPage,
	UserInfoMessagesListPage,
	UserInfoMessageViewPage,
	UserTaskSectionPage,
	UserChecklistTaskSectionPage,
	UserTodoListPage,
	AdminQuizListPage,
	AdminQuizCreatePage,
	AdminTestingCreatePage,
	AdminQuizReportsPage,
	AdminQuizStatisticPage,
	UserQuizListPage,
	UserQuizManagersPage,
	UserQuizSubShopPage,
	UserQuizPage,
	UserTestingPage,
	AdminConfigurationListPage,
	AdminConfigurationEditPage,
	UserPhotoGalleryPage,
	NoauthUserQuizViewPage,
	NoauthUserTestViewPage,
} from '../pages';

const ADMIN_ROUTE = (
	<AdminUserLayout>
		<Switch>
			<Route exact path="/" component={DashboardPage} />
			<Route exact path="/admin/dashboard/incident/import" component={DashboardIncidentImportsTablePage} />
			<Route exact path="/admin/dashboard/structure/import" component={DashboardStructureImportsTablePage} />
			<Route exact path="/admin/incidents" component={AdminIncidentsPage} />
			<Route exact path="/admin/incidents/import" component={AdminIncidentImportPage} />
			<Route exact path="/admin/incidents/types" component={AdminIncidentTypesTablePage} />
			<Route exact path="/admin/incidents/types/:incidentTypeId" component={AdminIncidentTypeEditPage} />
			{/* Информационные сообщения */}
			<Route exact path="/admin/infomsg" component={AdminInfoMessagesTablePage} />
			<Route exact path="/admin/infomsg/:infoMessageId" component={AdminInfoMessageEditPage} />
			{/* Магазины */}
			<Route exact path="/admin/shops" component={AdminShopsTablePage} />
			<Route exact path="/admin/shops/:shopId" component={AdminShopEditPage} />
			{/* Пользователи */}
			<Route exact path="/admin/users" component={AdminUsersTablePage} />
			<Route exact path="/admin/users/:userId" component={AdminUserEditPage} />
			{/* Опросы */}
			<Route exact path="/admin/quiz" component={AdminQuizListPage} />
			<Route exact path="/admin/quiz/create" component={AdminQuizCreatePage} />
			<Route exact path="/admin/quiz/edit/:id" component={AdminQuizCreatePage} />
			<Route exact path="/admin/testing/create" component={AdminTestingCreatePage} />
			<Route exact path="/admin/testing/edit/:id" component={AdminTestingCreatePage} />
			<Route exact path="/admin/quiz/reports" component={AdminQuizReportsPage} />
			<Route exact path="/admin/quiz/statistic/:id" component={AdminQuizStatisticPage} />
			{/* Управление конфигурациями */}
			<Route exact path="/admin/configuration" component={AdminConfigurationListPage} />
			<Route exact path="/admin/configuration/edit" component={AdminConfigurationEditPage} />
			<Route exact path="/admin/configuration/edit/:id" component={AdminConfigurationEditPage} />
			{/* Диагностика */}
			<Route exact path="/admin/diagnostics" component={AdminDiagnosticsPage} />
			<Route exact path="/admin/diagnostics/file-gallery" component={AdminDiagnosticsFileGalleryPage} />
			<Route exact path="/admin/diagnostics/incident-marking" component={AdminDiagnosticsIncidentMarkingPage} />
			<Route exact path="/admin/diagnostics/request-testing" component={AdminDiagnosticsRequestTestingPage} />
			{/* Ошибка: страница не найдена */}
			<Route component={PageNotFound} />
		</Switch>
	</AdminUserLayout>
);

const SUPPORT_ROUTE = (
	<SupportUserLayout>
		<Switch>
			<Route exact path="/" component={DashboardPage} />
			<Route exact path="/admin/dashboard/incident/import" component={DashboardIncidentImportsTablePage} />
			<Route exact path="/admin/dashboard/structure/import" component={DashboardStructureImportsTablePage} />
			<Route exact path="/admin/incidents" component={AdminIncidentsPage} />
			<Route exact path="/admin/incidents/import" component={AdminIncidentImportPage} />
			<Route exact path="/admin/infomsg" component={AdminInfoMessagesTablePage} />
			<Route exact path="/admin/infomsg/:infoMessageId" component={AdminInfoMessageEditPage} />
			<Route exact path="/admin/shops" component={AdminShopsTablePage} />
			<Route exact path="/admin/shops/:shopId" component={AdminShopEditPage} />
			<Route exact path="/admin/users" component={AdminUsersTablePage} />
			<Route exact path="/admin/users/:userId" component={AdminUserEditPage} />
			<Route exact path="/admin/quiz" component={AdminQuizListPage} />
			<Route exact path="/admin/quiz/create" component={AdminQuizCreatePage} />
			<Route exact path="/admin/quiz/edit/:id" component={AdminQuizCreatePage} />
			<Route exact path="/admin/quiz/reports" component={AdminQuizReportsPage} />
			{/* Ошибка: страница не найдена */}
			<Route component={PageNotFound} />
		</Switch>
	</SupportUserLayout>
);

const SUPERVISOR_ROUTE = (
	<UserLayout2>
		<Switch>
			<Route exact path="/">
				<Redirect to="/incidents" />
			</Route>
			<Route exact path="/quiz-list" component={UserQuizListPage} />
			<Route path="/quiz-managers/:managerIds?" component={UserQuizManagersPage} />
			<Route exact path="/quiz-subshop/:shopId" component={UserQuizSubShopPage} />
			<Route exact path="/quiz/:id" component={UserQuizPage} />
			<Route exact path="/testing/:id" component={UserTestingPage} />
			<Route exact path="/incidents" component={UserIncidentSectionPage} />
			<Route exact path="/incidents/shop/:shopHashId" component={UserIncidentSectionPage} />
			<Route exact path="/incidents/shop/:shopHashId/type/:incidentTypeHashId" component={UserIncidentSectionPage} />
			<Route exact
			       path="/incidents/shop/:shopHashId/type/:incidentTypeHashId/incident/:incidentHashId"
			       component={UserIncidentSectionPage} />
			<Route exact path="/incidents/:managerHashId" component={UserIncidentSectionPage} />
			{/* Задачи */}
			<Route exact path="/tasks" component={UserTaskSectionPage} />
			<Route exact path="/tasks/create" component={UserTaskSectionPage} />
			<Route exact path="/tasks/shop/:shopHashId" component={UserTaskSectionPage} />
			<Route exact path="/tasks/shop/:shopHashId/:taskHashId" component={UserTaskSectionPage} />
			<Route exact path="/tasks/:managerHashId" component={UserTaskSectionPage} />
			{/* Задачи по ЧЛ */}
			<Route exact path="/checklist_tasks" component={UserChecklistTaskSectionPage} />
			<Route exact path="/checklist_tasks/shop/:shopHashId" component={UserChecklistTaskSectionPage} />
			<Route exact path="/checklist_tasks/shop/:shopHashId/:checklistTaskHashId" component={UserChecklistTaskSectionPage} />
			<Route exact path="/checklist_tasks/:managerHashId" component={UserChecklistTaskSectionPage} />
			{/* Список дел */}
			<Route exact path="/todos" component={UserTodoListPage} />
			{/* Информационные сообщения */}
			<Route exact path="/infomsg" component={UserInfoMessagesListPage} />
			<Route exact path="/infomsg/:infoMessageId" component={UserInfoMessageViewPage} />
			{/* Ошибка: страница не найдена */}
			<Route component={PageNotFound} />
		</Switch>
	</UserLayout2>
);

const DM_ZDM_ROUTE = (
	<UserLayout2>
		<Switch>
			<Route exact path="/">
				<Redirect to="/incidents" />
			</Route>
			{/* Опросы */}
			<Route exact path="/quiz-list" component={UserQuizListPage} />
			<Route exact path="/quiz/:id" component={UserQuizPage} />
			<Route exact path="/testing/:id" component={UserTestingPage} />
			{/* Инциденты */}
			<Route exact path="/incidents" component={UserIncidentSectionPage} />
			<Route exact path="/incidents/type/:incidentTypeHashId" component={UserIncidentSectionPage} />
			<Route exact path="/incidents/type/:incidentTypeHashId/incident/:incidentHashId" component={UserIncidentSectionPage} />
			{/* Задачи */}
			<Route exact path="/tasks" component={UserTaskSectionPage} />
			<Route exact path="/tasks/:taskHashId" component={UserTaskSectionPage} />
			{/* Задачи по ЧЛ */}
			<Route exact path="/checklist_tasks" component={UserChecklistTaskSectionPage} />
			<Route exact path="/checklist_tasks/:checklistTaskHashId" component={UserChecklistTaskSectionPage} />
			{/* Список дел */}
			<Route exact path="/todos" component={UserTodoListPage} />
			{/* Информационные сообщения */}
			<Route exact path="/infomsg" component={UserInfoMessagesListPage} />
			<Route exact path="/infomsg/:infoMessageId" component={UserInfoMessageViewPage} />
			{/* Фото галерея */}
			<Route exact path="/photo-gallery" component={UserPhotoGalleryPage} />
			{/* страница не найдена */}
			<Route component={PageNotFound} />
		</Switch>
	</UserLayout2>
);

const NOAUTH_ROUTE = (
	<UserLayout2>
		<Switch>
			{/* Отчеты по опросам */}
			<Route exact path="/noauth-user/quiz-reports/:quizReportHashToken?/:subRole?/:subPath?" component={NoauthUserQuizViewPage} />
			<Route exact path="/noauth-user/testing-reports/:testReportHashToken?/:subRole?/:subPath?" component={NoauthUserTestViewPage} />
			{/* страница не найдена */}
			<Route component={PageNotFound} />
		</Switch>
	</UserLayout2>
);

export default {
	ADMIN_ROUTE,
	SUPPORT_ROUTE,
	SUPERVISOR_ROUTE,
	DM_ZDM_ROUTE,
	NOAUTH_ROUTE,
};
