import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { InfoMessageToAddresseeEnum, PageInfo } from 'common-lib';
import { ListIsEmpty, Spinner, UiBadge } from '~components';
import InfoMessagesFetcher from '~api/InfoMessagesFetcher';
import { get, set } from '~tools/localConfig';
import { fillInfoMessagesListFromJson } from '../infoMessagesAttrs';
import { UserContentWrapper } from '../../../containers';

// ключ для хранения фильтров списка
const UInfoMsgLP_PAGE_INFO = 'UInfoMsgLP.pageInfo';
// значения "Все" для фильтра
const FILTER_ALL = 'all';

const fetcher = new InfoMessagesFetcher();

type ComponentStore = {
	checkQueryString?: string
}

type ItemsState = {
	items: any[]
	pageInfo: any
	isItemsRequesting: boolean
}

export default function UserInfoMessagesListPage() {
	const [store] = useState<ComponentStore>({
		checkQueryString: get(UInfoMsgLP_PAGE_INFO, ''),
	})
	const [{ items, pageInfo, isItemsRequesting }, setItems] = useState<Partial<ItemsState>>({});
	const history = useHistory();
	const location = useLocation();

	const curPageInfo = PageInfo.parseFromString(location.search || store.checkQueryString || '');
	curPageInfo.pageIndex = 0;
	curPageInfo.pageSize = 200;
	curPageInfo.orderColumn = 'createdAt';
	curPageInfo.orderDirection = 'desc';
	curPageInfo.meta = new Set(['infoMessageToAddressee']);

	if (items === undefined || curPageInfo.toQueryString() !== store.checkQueryString) {
		if (!isItemsRequesting) {
			loadItems();
		}
	}

	const filters = getFilters();
	const isFilterSelected = filters.some(i => i.clearable);

	return (
		<UserContentWrapper title="Сообщения" filterOptions={filters}>
			{isItemsRequesting ? <Spinner onpage />
				: !items?.length ? <ListIsEmpty filterSelectedInfo={isFilterSelected} />
					: items.map(renderItem)}
		</UserContentWrapper>
	);

	function loadItems() {
		store.checkQueryString = curPageInfo.toQueryString();
		set(UInfoMsgLP_PAGE_INFO, store.checkQueryString);
		fetcher.getList(curPageInfo).then(json => {
			setItems({
				items: fillInfoMessagesListFromJson(json),
				pageInfo: json.pageInfo,
			});
		});
		setItems({ items, pageInfo, isItemsRequesting: true });
	}

	function getFilters() {
		const selectedValue = curPageInfo.customParams?.status || FILTER_ALL;
		return [{
			title: 'СТАТУС',
			options: [
				{ text: 'Все', value: FILTER_ALL },
				{ text: 'Непрочитанные', value: InfoMessageToAddresseeEnum.CREATED },
				{ text: 'Прочитанные', value: InfoMessageToAddresseeEnum.VIEWED },
			],
			selected: selectedValue,
			clearable: selectedValue !== FILTER_ALL,
			onChange: value => value !== selectedValue ? onFilterChange(value) : undefined,
		}];
	}

	function onFilterChange(value: string) {
		if (!value) value = FILTER_ALL;
		curPageInfo.update({ status: value === FILTER_ALL ? undefined : value });
		history.push(`${location.pathname}?${curPageInfo.toQueryString()}`);
	}
}

const viewedStyle = { color: '#ABABB8', fontWeight: 500 };

function renderItem(infoMessage) {
	const { id, subject, status, visibleDate, isImportant } = infoMessage;
	return (
		<UiBadge key={id}
		         bigPaddings
		         linkTo={`/infomsg/${btoa(id)}`}
		>
			<h1 style={status === InfoMessageToAddresseeEnum.VIEWED ? viewedStyle : undefined}>
				{subject}
			</h1>
			<UiBadge.DateTimeAndImportant
				date={visibleDate}
				isImportant={isImportant ? true : undefined} />
		</UiBadge>
	)
}
