import React, { ReactNode, useState } from 'react';
import { Button, Dropdown, Modal, Segment } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

/**
 * ErrorSegment
 */
export function ErrorSegment(props) {
	return (
		<Segment inverted color="red" style={{ clear: 'both' }}>
			{props.children}
		</Segment>
	);
}

ErrorSegment.propTypes = {
	children: PropTypes.node.isRequired,
};

/**
 * Nowrap
 */
export const Nowrap = styled.span`
  white-space: nowrap;
`;

/**
 * Novalue
 */
export const Novalue = styled.span`
  font-style: italic;
  color: silver;
`;

/**
 * FlexGridItem
 */
const FlexGridItem = styled.div`
  ${props => css`
    width: ${props.width || '100%'};
    max-width: ${props.maxWidth || '600px'};
  `}
  padding: 0 1rem 2rem 1rem;
`;

/**
 * FlexGrid
 */
export const FlexGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -2rem;

  @media (max-width: 600px) {
    display: block;

    ${FlexGridItem} {
      max-width: none;
      width: 100%;
      margin: 1rem auto;
    }
  }
`;

FlexGrid.Item = FlexGridItem;

/**
 * Spinner
 */
interface SpinnerProps {
	text?: string | React.ReactNode;
	onpage?: boolean;
}

const PagedStyle = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, .8);
  z-index: ${p => p.onpage ? 2 : 9999};

  table {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;

    td {
      vertical-align: center;
      text-align: center;
    }
  }
`;

export function Spinner(props: SpinnerProps) {
	return (
		<PagedStyle onpage={props.onpage}>
			<table>
				<tbody>
					<tr>
						<td>
							<img src="/assets/img/circle.svg" className="trobble1" alt="" />
						</td>
					</tr>
				</tbody>
			</table>
			<table>
				<tbody>
					<tr>
						<td>
							<img src="/assets/img/circle.svg" className="trobble2" alt="" />
						</td>
					</tr>
				</tbody>
			</table>
			<table>
				<tbody>
					<tr>
						<td>
							<img src="/assets/img/circle.svg" className="trobble3" alt="" />
						</td>
					</tr>
				</tbody>
			</table>
			<table>
				<tbody>
					<tr>
						<td>
							{props.text || 'Загрузка...'}
						</td>
					</tr>
				</tbody>
			</table>
		</PagedStyle>
	);
}

/**
 * ListIsEmpty
 */
interface ListIsEmptyProps {
	text?: string;
	filterSelectedInfo?: boolean;
	description?: string | ReactNode;
	error?: string | ReactNode;
}

const ListIsEmptyStyled = styled.h1`
  font-weight: normal;

  div.description {
    margin-top: 16px;
    font-size: 12px;
  }

  div.error {
    margin-top: 16px;
    padding: 10px;
    border: 1px solid red;
    border-radius: 4px;
    border-left-width: 4px;
    font-size: 13px;
    background: white;
  }
`;

export function ListIsEmpty(props: ListIsEmptyProps) {
	return (
		<ListIsEmptyStyled>
			{props.text || 'Список пуст'}
			{props.error ? <div className="error"><div><b>Ошибка:</b></div>{props.error}</div> : null}
			{props.description ? (
				<div className="description">{props.description}</div>
			) : props.filterSelectedInfo ? (
				<div className="description">
					Попробуйте изменить фильтры, чтобы увидеть больше данных
				</div>
			) : null}
		</ListIsEmptyStyled>
	);
}

/**
 * MenuItemLabel
 */
export const MenuItemLabel = styled.span`
  @media (max-width: 650px) {
    display: none;
  }
`;

/**
 * ValueField
 */
export const ValueField = styled.div`
  margin: 0;

  div {
    margin: .2rem 0;
  }

  span {
    font-size: 1.2rem;
    margin-left: .5rem;
  }
`;

/**
 * FloatRight
 */
export const FloatRight = styled.div`
  float: right;
  margin-left: 1rem;
`;

type CancelSaveDeleteButtonsProps = {
	readOnly?: boolean
	canSave?: boolean
	onCancel?: any // FIXME to Function
	onSave?: any // FIXME to Function
	onDelete?: any // FIXME to Function
};

/**
 * CancelSaveDeleteButtons
 */
export function CancelSaveDeleteButtons(props: CancelSaveDeleteButtonsProps) {
	const [isShowConfirmDelete, setShowDeleteConfirm] = useState(false);
	const { readOnly, canSave, onCancel, onSave, onDelete } = props;
	if (!onCancel && !onSave && !onDelete) {
		return null;
	}
	return (
		<div>
			{onCancel ? (
				<Button
					icon="arrow left"
					labelPosition="left"
					content="Отмена"
					onClick={onCancel}
				/>
			) : null}
			{onSave && !readOnly ? (
				<Button
					positive
					disabled={!canSave}
					icon="save"
					labelPosition="left"
					content="Сохранить"
					onClick={onSave}
				/>
			) : null}
			{onDelete && !readOnly ? (
				<FloatRight>
					<Button
						negative
						icon="remove user"
						labelPosition="left"
						content="Удалить"
						onClick={() => setShowDeleteConfirm(true)}
					/>
				</FloatRight>
			) : null}
			{isShowConfirmDelete ? (
				<Modal open>
					<Modal.Content>
						<h2>Удаление записи</h2>
						<p>
							При удалении записи все данные по ней будут не доступны.
						</p>
						<p>
							Вы уверены, чтоб хотите удалить запись?
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={() => setShowDeleteConfirm(false)}
						        content="Отмена" />
						<Button onClick={onDelete} content="Да, удалить" />
					</Modal.Actions>
				</Modal>
			) : null}
		</div>
	);
}

type PagePaginatorProps = {
	pageIndex: number,
	pageSize: number,
	pageCount: number,
	sizeOptions: {
		key: string,
		text: string,
		value: string | number | boolean,
	}[],
	onChangePageSize: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void,
	onChangePageIndex: Function,
};

/**
 * PagePaginator
 */
export function PagePaginator(props: PagePaginatorProps) {
	const { pageIndex, pageSize, pageCount, sizeOptions } = props;
	const lIndex = Math.max(0, pageIndex - 3);
	const rIndex = Math.min(pageCount - 1, pageIndex + 3);
	const midCount = rIndex - lIndex;
	return (
		<>
			{sizeOptions ? (
				<Dropdown
					button
					className="icon"
					labeled
					options={sizeOptions}
					defaultValue={pageSize}
					onChange={props.onChangePageSize}
				/>
			) : null}
			<Button.Group>
				{lIndex > 0 && <Button content="1" onClick={() => props.onChangePageIndex?.(0)} />}
				{lIndex > 1 && <Button disabled content="..." />}
				{midCount ? Array(rIndex - lIndex + 1).fill(0)
					.map((_, i) => {
						const index = i + lIndex;
						return (
							<Button
								key={index}
								content={index + 1}
								active={index === pageIndex}
								onClick={index !== pageIndex ? () => props.onChangePageIndex(index) : undefined}
							/>
						);
					}) : null}
				{rIndex < pageCount - 2 && <Button disabled content="..." />}
				{rIndex < pageCount - 1 && (
					<Button content={pageCount}
					        onClick={() => props.onChangePageIndex(pageCount - 1)} />
				)}
			</Button.Group>
		</>
	);
}

/**
 * WideBackground
 */
const WideBackgroundStyled = styled.div`
    position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: ${p => p.hidden ? '' : 'flex'};
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, .95);
	z-index: 1000;

	table {
		position: fixed;
		top: 0;
		height: 100%;
		left: 0;
		width: 100%;

		td {
			vertical-align: center;
			text-align: center;
		}
	}
`;

interface WideBackgroundProps {
	children: ReactNode;
	hidden?: boolean;
}

export function WideBackground(props: WideBackgroundProps) {
	return <WideBackgroundStyled hidden={props.hidden}>
		<table>
			<tbody>
				<tr>
					<td>{props.children}</td>
				</tr>
			</tbody>
		</table>
	</WideBackgroundStyled>;
}
