import { PageInfo } from 'common-lib';
import BaseFetcher from './BaseFetcher';

export default class IncidentsFetcher extends BaseFetcher
{
	constructor() {
		super('incidents');
	}

	/**
	 * Запрос данных статистики для текущего менеджера.
	 * @param pageInfo
	 */
	getCurrentManagerStats(pageInfo?: PageInfo) {
		const query = pageInfo?.toQueryString();
		return this.fetchGetThenDataFromJson(`${this.basePath}/manager-stats${query ? `?${query}` : ''}`);
	}

	/**
	 * Запрос данных статистики по типам инцидентов для магазина.
	 * @param shopId
	 * @param pageInfo
	 */
	getIncidentTypeListByShopId(shopId: number, pageInfo?: PageInfo) {
		const query = pageInfo?.toQueryString();
		return this.fetchGetThenDataFromJson(`${this.basePath}/type-stats/${shopId}${query ? `?${query}` : ''}`);
	}

	/**
	 * Запрос инцидентов по товарам для типа инцидента.
	 * @param shopId
	 * @param incidentTypeId
	 * @param pageInfo
	 */
	getGoodListByShopIdAndIncidentTypeId(shopId: number, incidentTypeId: number, pageInfo?: PageInfo) {
		const query = pageInfo?.toQueryString();
		return this.fetchGetThenJson(`${this.basePath}/good/list/${shopId}/${incidentTypeId}${query ? `?${query}` : ''}`);
	}
}
