import React, { useEffect, useState } from 'react';
import assert from 'assert';
import { Spinner, UiBadge } from '~components';
import { getInfoMessageBodyByFileId } from '~api/infoMessagesApi';
import InfoMessagesFetcher from '~api/InfoMessagesFetcher';
import { UserContentWrapper } from '~containers';
import { PageNotFound } from '../../index';
import styled from 'styled-components';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';

const fetcher = new InfoMessagesFetcher();

const ScrollableStyled = styled.div`
  overflow: auto;
  padding: 10px 2px;
`;

export default function UserInfoMessageViewPage(props) {
	const [{ infoMessage, htmlBody, isRequesting }, setData] = useState<any>({})

	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	const infoMessageId = Number(atob(props.match.params.infoMessageId));
	if (!infoMessage) {
		if (!isRequesting) {
			setData({ isRequesting: true })
			fetcher.getOne(infoMessageId)
				.then(({ data: newInfoMessage, meta }: any) => {
					// получаем тело сообщения
					getInfoMessageBodyByFileId(newInfoMessage.fileId)
						.then(_htmlBody => {
							setData({ infoMessage: newInfoMessage, htmlBody: _htmlBody })
						})
					// уведомление о прочтении
					const status = meta?.infoMessageToAddresseeMap
						? (Object.values(meta.infoMessageToAddresseeMap) as any)[0].status
						: null;
					assert(status, 'В сообщении не указан статус прочтения');
					if (status === 'CREATED') {
						fetcher.setStatusToView(infoMessageId)
							.then();
					}
				});
		}
		return <UserContentWrapper title="Сообщение" backLinkTo="/infomsg">
			<Spinner onpage />
		</UserContentWrapper>;
	}

	if (!infoMessage) {
		return <UserContentWrapper title="Сообщение" backLinkTo="/infomsg">
			<PageNotFound />
		</UserContentWrapper>;
	}

	return (
		<UserContentWrapper title="Сообщение"
		                    isPreview
		                    backLinkTo="/infomsg">
			<UiBadge bigPaddings>
				<h1>{infoMessage.subject}</h1>
				<UiBadge.DateTimeAndImportant
					date={new Date(infoMessage.externalMessageDate || infoMessage.createdAt)}
					isImportant={infoMessage.isImportant ? true : undefined} />
				<ScrollableStyled dangerouslySetInnerHTML={{ __html: htmlBody }} />
			</UiBadge>
		</UserContentWrapper>
	)
}
