import React, { useEffect, useState } from 'react';
import { Spinner } from '~components';
import { Button, Modal } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import * as QuizApi from '~api/quizApi';
import { UserContentWrapper } from '~containers';
import { ITestingSettings, ITestingQuestion, UserTestingSolving } from './UserTestingSolving';
import { setSwipeRefreshLayoutEnabled } from '~tools/androidFunctions';
import { QuestionnaireTypeEnum } from 'common-lib';
import { useSelector } from 'react-redux';

export default function UserTestingPage(props) {
	const quizId: number = +props.match.params.id;

	useEffect(() => {
		setSwipeRefreshLayoutEnabled(false);
		return () => {
			setSwipeRefreshLayoutEnabled(true);
		};
	}, []);

	const meConfig: any = useSelector<any>(state => state.me.currentUser);
	const isDmManager = meConfig.shopManagerRoles !== undefined;

	const history = useHistory();

	const [quizName, setTestingName] = useState<string>('');
	const [isShowQuizStopped, setIsShowQuizStopped] = useState<boolean>(false);
	const [questions, setQuestions] = useState<ITestingQuestion[]>([]);
	const [questionsSettings, setQuestionsSettings] = useState<ITestingSettings>();
	const [resultModal, setResultModal] = useState(false);
	const [answeredPercent, setAnsweredPercent] = useState<number>(0);

	async function checkQuizAvailability(): Promise<boolean> {
		return await QuizApi.fetchRunningQuizList().then(res => {
			return res.data.findIndex(item => item.id === quizId) !== -1;
		}).catch(() => {
			return true;
		});
	}

	function closeModal(): void {
		setResultModal(false);
		history.push('/quiz-list');
	}

	function submit(payload: any[], result: number) {
		const updatedPayload = payload;
		setAnsweredPercent(result);
		QuizApi.answerTest(quizId, updatedPayload, result).then(() => {
			if (questionsSettings?.showTestResult) {
				setResultModal(true);
			} else {
				history.replace('/quiz-list');
			}
		}).catch(error => {
			if ([400].includes(error.getStatus())) {
				setIsShowQuizStopped(true);
			} else {
				throw new Error(error);
			}
		});
	}

	useEffect(() => {
		QuizApi.fetchQuiz(quizId).then(res => {
			setTestingName(res.data.title);
			setQuestionsSettings(res.data.questionnaireConfig.questionsSettings);
			setQuestions(res.data.questionnaireConfig.questions.map(item => {
				const question: ITestingQuestion = {
					text: item.text,
					type: item.type,
					startDate: res.data.startDate,
					startTime: res.data.startTime,
					photos: item.photos,
					...(item.correctAnswer && { correctAnswer: item.correctAnswer }),
					...(item.options && { options: item.options }),
				};

				return question;
			}));
		});

	}, []);

	if (!quizName || !questions?.length) {
		return <Spinner onpage />;
	}

	return (
		<UserContentWrapper isPreview title={`Тест: ${quizName}`} backLinkTo="/quiz-list">
			<UserTestingSolving questions={questions} quizType={QuestionnaireTypeEnum.TESTING} questionsSettings={questionsSettings} submit={submit} />
			{isShowQuizStopped ? (
				<Modal open>
					<Modal.Content>
						<p>
							Тест был остановлен.
						</p>
						<p>
							Данные не сохранены.
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={() => {
							history.replace('/quiz-list');
						}} content="OK" />
					</Modal.Actions>
				</Modal>
			) : null}
			{resultModal ? (
				<Modal open onClose={closeModal} size="small">
					<Modal.Header>
						<h3>Тест завершен</h3>
					</Modal.Header>
					<Modal.Content>
						<p>
							Ваш результат {answeredPercent} %
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={closeModal} content="OK" />
					</Modal.Actions>
				</Modal>
			) : null}
		</UserContentWrapper>
	);
}
