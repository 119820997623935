import { fetchGetThenJson, fetchPostThenJson } from '~tools/fetchBackend';
import { throwIfExcessKeys } from 'common-lib';

const LIST_AVAIL_KEYS = ['page', 'order', 'search', 'meta'];

export async function fetchFeatureConfigurationList(pageInfo): Promise<any> {
	throwIfExcessKeys(pageInfo.toQueryObject(), LIST_AVAIL_KEYS, 'fetchConfigList.pageInfo');
	const query = pageInfo.toQueryString();
	return fetchGetThenJson(`feature_configurations/list${query ? `?${query}` : ''}`);
}

export async function fetchFeatureConfiguration(id: number): Promise<any> {
	return fetchGetThenJson(`feature_configurations/one/${id}`);
}

interface IUpdateFeatureConfigurationPayload {
	enabledAll: boolean;
	shopIds: number[] | null;
}

export async function updateFeatureConfiguration(id: number, payload: IUpdateFeatureConfigurationPayload): Promise<any> {
	return fetchPostThenJson(`feature_configurations/edit/${id}`, payload);
}
