import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PageInfo } from 'common-lib';
import { DataTableWithPageInfo, Spinner } from '~components';
import * as QuizApi from '~api/quizApi';
import { TABLE_HEADERS } from './quizAttrs';

export default function AdminQuizReportsPage(props) {
	const [tableData, setTableData] = useState<any>();
	const [checkQueryString, setCheckQueryString] = useState<string>();
	const [isRequesting, setRequesting] = useState<boolean>();

	const pageInfo = PageInfo.parseFromString(props.location.search, { order: 'startAt:desc' });

	if (!tableData || pageInfo.toQueryString() !== checkQueryString) {
		if (!isRequesting) {
			setRequesting(true);
			QuizApi.fetchReportsList(pageInfo).then(json => {
				setTableData(json);
				setCheckQueryString(pageInfo.toQueryString);
				setRequesting(false);
			});
		}
	}

	return (
		<>
			<Link style={{ display: 'inline-block', marginBottom: '0.3rem' }} to="/admin/quiz">
				<Icon name="arrow left" />
				Вернуться в Опросы
			</Link>
			{tableData ? (
				<DataTableWithPageInfo
					icon="file excel outline"
					title="Список отчетов"
					headers={TABLE_HEADERS}
					rows={tableData.data}
					pageInfo={tableData.pageInfo}
					onChangePageOptions={options => {
						const pageInfo1 = PageInfo.parseFromString(props.location.search, { order: 'startAt:desc' });
						pageInfo1.update(options);
						props.history.push(`${props.location.pathname}?${pageInfo1.toQueryString()}`);
					}}
				/>
			) : null}
			{isRequesting ? <Spinner /> : null}
		</>
	);
}
