import { throwIfExcessKeys, ResponseError } from 'common-lib';
import { fetchDelete, fetchGetThenJson, fetchGetThenReadBody, fetchPostThenJson, fetchPutThenJson } from '~tools/fetchBackend';

const LIST_AVAIL_KEYS = ['page', 'order', 'search', 'meta'];

/**
 * Запрос списка сообщений.
 * @param pageInfo
 * @returns {Promise<unknown>}
 */
export async function getInfoMessagesList(pageInfo) {
	throwIfExcessKeys(pageInfo.toQueryObject(), LIST_AVAIL_KEYS, 'getInfoMessagesList.pageInfo');
	const query = pageInfo.toQueryString();
	return fetchGetThenJson(`infomessages/list${query ? `?${query}` : ''}`);
}

/**
 * Запрос одного сообщения.
 * @param id
 * @returns {Promise<unknown>}
 */
export async function getInfoMessageById(id) {
	if (!id) {
		throw new Error('Параметр id не может быть пустым при вызове getInfoMessageById');
	}
	return fetchGetThenJson(`infomessages/one/${id}`);
}

/**
 * Запрос одного сообщения с пользователями.
 * @param id
 * @returns {Promise<unknown>}
 */
export async function getInfoMessageByIdWithUsers(id) {
	if (!id) {
		throw new Error('Параметр id не может быть пустым при вызове getInfoMessageByIdWithUsers');
	}
	return fetchGetThenJson(`infomessages/one/${id}?meta=users`);
}

/**
 * Запрос тела сообщения.
 * @param fileId
 * @returns {Promise<T>}
 */
export async function getInfoMessageBodyByFileId(fileId) {
	if (!fileId) {
		throw new Error('Идентификатор fileId для сообщения не может быть пустым при вызове getInfoMessageBodyByFileId');
	}
	return fetchGetThenReadBody(`file/download/${fileId}`)
		.catch(error => {
			if (error instanceof ResponseError && error.getStatus() === 404) {
				throw new Error('Тело сообщения не  на сервере');
			} else {
				throw error;
			}
		});
}

/**
 * Выполняет поиск пользователей.
 * @param search
 * @returns {Promise<unknown>}
 */
export async function searchUsers(search) {
	return fetchGetThenJson(`search/users?search=${encodeURI(search)}`);
}

/**
 * Запрос на создание сообщения.
 * @param itemData
 * @returns {Promise<unknown>}
 */
export async function createInfoMessage(itemData) {
	return fetchPostThenJson('infomessages/one', itemData);
}

/**
 * Запрос на обновление сообщения.
 * @param id
 * @param itemData
 * @returns {Promise<unknown>}
 */
export async function updateInfoMessage(id, itemData) {
	return fetchPutThenJson(`infomessages/one/${id}`, itemData);
}

/**
 * Запрос на удаление сообщения.
 * @param id
 * @returns {Promise<*>}
 */
export async function deleteInfoMessage(id) {
	return fetchDelete(`infomessages/one/${id}`);
}
