import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Dimmer, Form, Header, Icon, Loader } from 'semantic-ui-react';
import { validateIsTimeoffset, validateString, UserRoles, arrayToMapById } from 'common-lib';
import { createShop, deleteShop, fetchRegionList, fetchShop, fetchShopRoles, fetchUserList, updateShop } from '~store/actions/structureActions';
import { EditForm, ErrorSegment, FlexGrid } from '~components';

type Props = {
	history: any,
	match: any,
	me: any,
	structure: any,
	createShop: Function,
	fetchShop: Function,
	updateShop: Function,
	deleteShop: Function,
	fetchUserList: Function,
	fetchShopRoles: Function,
	fetchRegionList: Function,
}

type State = {
	error?: any,
	newShopManagers?: any[],
	hasManagerRolesChanges?: boolean,
	isRequesting: boolean,
	isNewItem: boolean,
	editId: number,
	itemData: any,
	metaFields: any,
}

class AdminShopEditPage extends React.Component<Props, Partial<State>>
{
	constructor(props) {
		super(props);
		let editId = props.match.params.shopId;
		const isNewItem = editId === '+';
		if (!isNewItem) {
			editId = Number(editId);
			if (String(editId) !== props.match.params.shopId) {
				this.state = { error: 'Номер записи указан с ошибкой' };
				return;
			}
		}
		this.state = {
			isRequesting: true,
			isNewItem,
			editId,
		}
	}

	async componentDidMount() {
		if (this.state.error) return undefined
		await Promise.all([
			// загружаем магазин, всех пользователей и регионы
			this.state.isNewItem
				? null
				: this.props.fetchShop(this.state.editId, { users: true }),
			this.props.fetchUserList(),
			this.props.fetchShopRoles(),
			this.props.fetchRegionList(),
		])
			.then(() => {
				this.setState({
					isRequesting: false,
					...this.initMeta(),
				})
			})
	}

	initMeta = () => {
		const { me, structure: { shops, regions } } = this.props;
		const readOnly = ![UserRoles.ADMIN, UserRoles.SUPPORT].includes(me.currentUser.role);
		const { editId, isNewItem } = this.state;
		let itemData: any = { shopManagers: [] }; // empty for new one
		if (!isNewItem) {
			const savedData = shops.find(i => i.id === editId);
			if (!savedData || savedData.id !== editId) {
				this.setState({ error: 'Запись для редактирования не существует' });
				return;
			}
			if (savedData.meta) {
				// запоминаем менеджеров магазина
				for (const {
					roleId,
					userId
				} of Object.values(savedData.meta.shopManagerMap) as any) {
					itemData.shopManagers.push({ roleId, userId });
				}
			}
			Object.assign(itemData, {
				id: savedData.id,
				externalId: savedData.externalId,
				address: savedData.address,
				city: savedData.city,
				name: savedData.name,
				email: savedData.email,
				phone: savedData.phone,
				regionId: savedData.regionId,
				timeOffset: savedData.timeOffset,
			});
		}
		const metaFields: any[] = [];
		if (!isNewItem) {
			metaFields.push({
				key: 'id',
				title: 'Id (только чтение)',
				readOnly: true,
			});
		}
		return {
			itemData,
			metaFields: [
				...metaFields,
				{
					key: 'externalId',
					title: 'Внешний Id',
					readOnly,
				},
				{
					key: 'name',
					title: <label>Название <small>(обязательное)</small></label>,
					validate: validateString(1, 50),
					readOnly,
				},
				{
					key: 'regionId',
					title: 'Регион',
					type: 'select',
					options: regions
						.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
						.map(i => ({
							text: i.name,
							value: i.id,
						})),
					clearable: true,
					readOnly,
				},
				{
					key: 'city',
					title: 'Город',
					validate: validateString(0, 50),
					readOnly,
				},
				{
					key: 'address',
					title: 'Адрес',
					validate: validateString(0, 100),
					readOnly,
				},
				{
					key: 'timeOffset',
					title: <label>Часовой пояс (мин) <small>(обязательное)</small></label>,
					type: 'number',
					validate: validateIsTimeoffset(),
					readOnly,
				},
			],
		}
	}

	onChangeShopManagers = managerLinks => {
		console.debug('%c*** this.state.itemData=', 'background: #eee; color: blue', this.state.itemData);
		console.debug('%c*** managerLinks=', 'background: #eee; color: blue', managerLinks);
		const o1 = JSON.stringify(this.state.itemData.shopManagers?.map(i => `${i.roleId}_${i.userId}`).sort() || '');
		const o2 = JSON.stringify(managerLinks.map(i => `${i.roleId}_${i.userId}`).sort());
		const hasChanges = o1 !== o2;
		if (this.state.hasManagerRolesChanges !== hasChanges || hasChanges) {
			this.setState({
				hasManagerRolesChanges: hasChanges,
				newShopManagers: managerLinks.map(({ roleId, userId }) => ({ roleId, userId })),
			});
		}
		console.debug('%c*** o1, o2=', 'background: #eee; color: blue', o1, o2, o1 === o2);
	}

	handleSave = formData => {
		const { isNewItem, itemData } = this.state;
		const newItemData = Object.assign({}, itemData, formData);
		console.log('%c*** this.state.newShopManagers=', 'background: #eee; color: blue', this.state.newShopManagers);
		if (this.state.newShopManagers) {
			newItemData.shopManagers = this.state.newShopManagers;
		}
		console.debug('%c*** newItemData=', 'background: #eee; color: blue', newItemData);
		// TODO: need validate formData
		this.setState({ isRequesting: true }, async () => {
			if (isNewItem) {
				await this.props.createShop(newItemData)
			} else {
				await this.props.updateShop(itemData.id, newItemData)
			}
			this.gotoShops()
		})
	}

	handleDelete = async () => {
		const { itemData } = this.state;
		await this.props.deleteShop(itemData.id);
		this.gotoShops();
	}

	gotoShops = () => this.props.history.push('/admin/shops')

	render() {
		const { isNewItem } = this.state;
		const title = isNewItem ? 'Создание магазина' : 'Редактирование магазина';
		const hasError = !!this.state.error;
		if (hasError) {
			return (
				<>
					<Header as="h3" icon="warehouse" content={title} />
					{hasError && <ErrorSegment>{this.state.error}</ErrorSegment>}
				</>
			);
		}
		if (this.state.isRequesting) {
			return (
				<Dimmer active inverted>
					<Loader inverted>Загрузка данных...</Loader>
				</Dimmer>
			);
		}
		const { role } = this.props.me.currentUser;
		const readOnly = ![UserRoles.ADMIN, UserRoles.SUPPORT].includes(role);
		return (
			<FlexGrid>
				<FlexGrid.Item>
					<EditForm
						title={title}
						icon="warehouse"
						readOnly={readOnly}
						metaFields={this.state.metaFields}
						itemData={this.state.itemData}
						bottomComponent={<SelectManagers
							readOnly={readOnly}
							itemData={this.state.itemData}
							structure={this.props.structure}
							onChange={this.onChangeShopManagers}
						/>}
						otherSaveFlag={this.state.hasManagerRolesChanges}
						onSave={this.handleSave}
						onCancel={this.gotoShops}
						onDelete={!isNewItem ? this.handleDelete : undefined}
					/>
				</FlexGrid.Item>
			</FlexGrid>
		);
	}
}

const SelectManagersTable = styled.table`
  td.badge {
    padding-right: 5px;

    div {
      min-width: 30px;
      text-align: center;
      padding: 2px 4px;
      background: #ccc;
      border-radius: 3px;

      &.__new {
        background: #96c76e;
      }

      &.__deleted {
        background: #ce8c8c;
      }
    }
  }

  td.user-name {
    width: 100%;

    &.__deleted {
      text-decoration: line-through;
    }
  }

  td.remove-btn div {
    width: 20px;
    cursor: pointer;

    &:hover {
      color: #aaa;
    }
  }
`;

const AddLinkBtn = styled.button`
  display: inline-block;
  background: #ccc;
  cursor: pointer;
  padding: 4px 8px;
  margin: 8px 12px 8px 0;
  border: none;
  border-radius: 3px;

  &:hover {
    background: #aaa;
  }
`;

/**
 * Компонент для назначения ролей
 */
function SelectManagers(props: Readonly<any>) {
	const { readOnly, structure: { shopRoles }, onChange } = props;

	const [formData, setFormData] = useState<any>([]);
	const [managersMap, setManagersMap] = useState<any>();
	const [userOptions, setUserOptions] = useState([]);
	const [shopRolesMap, setShopRolesMap] = useState<any>();

	useEffect(() => {
		// init form
		console.debug('%c*** SelectManagers props=', 'background: #eee; color: blue', props);
		const { itemData: { shopManagers }, structure: { users } } = props;
		const managers = users.filter(i => i.role === 'MANAGER');
		const _userOptions = managers.map(i => ({
			text: `${i.externalId ? `(#${i.externalId}) ` : ''}${i.fullName}`,
			value: i.id,
		}));
		const _shopRolesMap = arrayToMapById(shopRoles);
		setShopRolesMap(_shopRolesMap);
		const _managerMap = arrayToMapById(managers);
		setManagersMap(_managerMap);
		setFormData(sortManagers(shopManagers.map(i => ({ index: Math.random(), ...i })), _shopRolesMap, _managerMap));
		setUserOptions(_userOptions);
	}, []);

	function updateFormData(newFormData) {
		if (onChange) onChange(newFormData.filter(i => !i.isDeleted && i.userId));
		setFormData(newFormData);
	}

	function removeLink(index) {
		updateFormData(formData.filter(i => {
			if (i.index !== index) return true;
			if (i.isNew) return false;
			i.isDeleted = true;
			return true;
		}));
	}

	function addLink(roleId) {
		setFormData(sortManagers([...formData, {
			index: Math.random(),
			isNew: true,
			roleId,
		}], shopRolesMap, managersMap));
	}

	function updateUserId(index, userId) {
		const item = formData.find(i => i.index === index);
		item.userId = userId;
		const same = formData.filter(i => i.index !== item.index && i.roleId === item.roleId && i.userId === item.userId);
		if (same.length) {
			// есть другие записи с теми же параметрами, удаляем связку
			same.forEach(i => {
				delete i.isDeleted;
			});
			updateFormData(formData.filter(i => i.index !== index));
		} else {
			updateFormData(sortManagers([...formData], shopRolesMap, managersMap));
		}
	}

	return <>
		<Header as="h4" content="Менеджеры" />
		<SelectManagersTable>
			<tbody>
				{formData.map((link: any) => {
					const manager = managersMap.get(link.userId);
					return <tr key={`${link.index}_${link.userId}`}>
						<td className="badge">
							<div className={link.isNew ? '__new' : link.isDeleted ? '__deleted' : undefined}>
								{shopRolesMap.get(link.roleId)?.shortTitle}
							</div>
						</td>
						<td className={`user-name ${link.isDeleted ? '__deleted' : ''}`}>
							{link.userId
								? (manager ? <Link to={`/admin/users/${link.userId}`}>
									{manager.fullName || 'не указано'}
								</Link> : <i>удален</i>)
								: <Form.Select
									fluid
									search
									options={userOptions}
									onChange={(_, { value }) => updateUserId(link.index, value)}
								/>}
						</td>
						{!readOnly && !link.isDeleted ? <td className="remove-btn">
							<div onClick={() => removeLink(link.index)}>
								<Icon name="close" />
							</div>
						</td> : null}
					</tr>;
				})}
			</tbody>
		</SelectManagersTable>
		{!readOnly ? <div style={{ marginBottom: '15px' }}>
			<div style={{ marginTop: '10px' }}><b>Добавить нового менеджера</b></div>
			<div style={{ paddingLeft: '10px' }}>
				{shopRoles.map((role: any) => (
					<AddLinkBtn key={role.id} onClick={() => addLink(role.id)}>
						{role.shortTitle}
					</AddLinkBtn>
				))}
			</div>
		</div> : null}
	</>;
}

function sortManagers(managers: any[], rolesMap, userMap): any[] {
	return managers.sort((a, b) => {
		const roleA = rolesMap.get(a.roleId);
		const roleB = rolesMap.get(b.roleId);
		if (roleA !== roleB) return roleB.subLevel - roleA.subLevel;
		if (!a.userId) return 1;
		if (!b.userId) return -1;
		const userA = userMap.get(a.userId) || { fullName: 'удален' };
		const userB = userMap.get(b.userId) || { fullName: 'удален' };
		return userA.fullName > userB.fullName ? 1 : -1;
	});
}

export default connect(
	(state: any) => ({ me: state.me, structure: state.structure }),
	{
		createShop,
		fetchShop,
		updateShop,
		deleteShop,
		fetchUserList,
		fetchShopRoles,
		fetchRegionList,
	},
)(AdminShopEditPage);
