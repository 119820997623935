import { throwIfExcessKeys } from 'common-lib';
import {
	fetchDelete,
	fetchGetThenJson,
	fetchPostThenJson,
	fetchPutThenJson,
} from '~tools/fetchBackend';

const LIST_AVAIL_KEYS = ['page', 'order', 'search', 'meta'];

/**
 * Запрос списка пользователей.
 * @param pageInfo
 * @returns {Promise<unknown>}
 */
export async function getUsersList(pageInfo) {
	throwIfExcessKeys(pageInfo.toQueryObject(), LIST_AVAIL_KEYS, 'getUsersList.pageInfo');
	const query = pageInfo.toQueryString();
	return fetchGetThenJson(`users${query ? `?${query}` : ''}`);
}

/**
 * Запрос одного пользователя.
 * @param id
 * @param meta
 * @returns {Promise<unknown>}
 */
export async function getUserById(id, meta) {
	if (!id) {
		throw new Error('Параметр id не может быть пустым при вызове getUserById');
	}
	const query = meta ? `?meta=${meta}` : '';
	return fetchGetThenJson(`user/${id}${query}`);
}

/**
 * Запрос на создание пользователя.
 * @param itemData
 * @returns {Promise<unknown>}
 */
export async function createUser(itemData) {
	return fetchPostThenJson('user', itemData);
}

/**
 * Запрос на обновление пользователя.
 * @param id
 * @param itemData
 * @returns {Promise<unknown>}
 */
export async function updateUser(id, itemData) {
	return fetchPutThenJson(`user/${id}`, itemData);
}

/**
 * Запрос на удаление пользователя.
 * @param id
 * @returns {Promise<*>}
 */
export async function deleteUser(id) {
	return fetchDelete(`user/${id}`);
}

/**
 * Запрос списка подчиненных менеджеров для статистики.
 */
export async function loadStatisticSubordinateManagers(managers: number[]) {
	return fetchPostThenJson('statistic/subordinate_managers/tree', managers);
}
