import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
	Breadcrumb,
	Button,
	ButtonGroup,
	Checkbox,
	Form,
	Header,
	Icon,
	Input,
	Modal,
	Segment,
	TextArea
} from 'semantic-ui-react';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import * as QuizApi from '~api/quizApi';
import { Spinner } from '~components/smallComponents';
import AddresseeSelector from '~components/ui/AddresseeSelector';
import moment from 'moment';
import { CheckedSubordinatesValue } from '~components/ui/AddresseeSelector';
import * as ShopsApi from '~api/shopsApi';
import generateId from '~tools/generateId';
import arrayMove from 'array-move';
import { QuestionnaireQuestionTypeEnum, QuestionnaireTypeEnum, validateIsEmail } from 'common-lib';
import { fillSubordinateMap } from '../../../lib/subordinateTools';
import styled from 'styled-components';
import { UiPhotoGallery2 } from '~components';


interface IOption {
	id: number;
	value: string;
	index?: number;
	correctAnswer?: boolean;
}

interface IQuestion {
	id: number;
	warning?: string;
	type: string;
	name: string;
	options?: IOption[];
	photos: any[];
	index?: number;
	correctAnswer? : string;
}

interface IQuestionsSettings {
	mixOptionAnswers: boolean;
	showTestResult: boolean;
}

const Knob = styled.div`
  position: absolute;
  width: 22px;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: move;
  background: url(/assets/img/ui/drag.svg) no-repeat center;
`;

const WarnBubble = styled.div`
  color: darkorange;
  font-size: 0.9em;
`;

const PhotoSection = styled.div`
	width: 25%;
	display: flex;
  	flex-direction: column;
  	justify-content: flex-start;
`;

const QuestionList = styled.div`
	& > div {
		margin-bottom: 1rem;

		&.invalid {
			background: rgba(255, 220, 220, 0.5);
			border-radius: 8px;
			padding: 8px;
			border: 2px solid pink;
		}
	}

	span.__spark {
		margin-left: 0.3rem;
		color: red;
	}

	div.__radioList {
		div {
			cursor: default;
			padding: 0.3rem 0 0.3rem 2rem;
			background-image: url(/assets/img/ui/radio_empty.svg);
			background-repeat: no-repeat;
			background-size: initial;
			background-position: left center;

			&.active {
				background-image: url(/assets/img/ui/radio_checked.svg);
			}
		}
	}

	div.__checkboxList {
		div {
			cursor: default;
			padding: 0.3rem 0 0.3rem 2rem;
			background-image: url(/assets/img/ui/checkbox_empty.svg);
			background-repeat: no-repeat;
			background-size: initial;
			background-position: left center;

			&.active {
				background-image: url(/assets/img/ui/checkbox_checked.svg);
			}
		}
	}
`;

const TitleTestStyle = styled.div`
    display: flex;
	justify-content: space-between;

	.header_input {
        width: 100%;
        padding-right: 20px;
	}

	.test_settings {
        display: flex;
		flex-direction: column;
		justify-content: center;
		white-space: nowrap;
        user-select: none;

        .question-item__actions {
            width: 100%;
        }
	}

    @media(max-width: 650px) {
		flex-direction: column;

	    .header_input {
		    padding-right: 0;
	    }

	    .test_settings {
		    white-space: normal;

		    .question-item__actions {
			    padding: 0;
		    }
	    }
    }
`;

const AnswerOptionStyle = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
`;

const ShopAndManagersStyle = styled.div`
	display: flex;
	align-items: center;
	margin-left: 5px;
	margin-top: 10px;
`;

export default function AdminTestingCreatePage(props) {
	function genCleanTreeInfo(): any {
		return {
			checkedShops: new Set(),
			checkedUsers: new Set(),
			checkedNotallUsers: new Set(),
			openedBranches: new Set(),
			branchSelectedCount: new Map(),
			checkedManagers: new Set(),
		};
	}
	const quizId = props.match.params.id;

	const history = useHistory();

	const [isLoading, setLoading] = useState<boolean>(false);
	const [step, setStep] = useState<number>(1);
	const [quizName, setQuizName] = useState<string>('');
	const [questions, setQuestions] = useState<IQuestion[]>([]);
	const [scheduleStartAtDate, setScheduleStartAtDate] = useState<string>('');
	const [scheduleStartAtTime, setScheduleStartAtTime] = useState<string>('06:00');
	const [scheduleFinishAtDate, setScheduleFinishAtDate] = useState<string>('');
	const [scheduleFinishAtTime, setScheduleFinishAtTime] = useState<string>('12:00');
	const [scheduleIsRepeatable, setScheduleIsRepeatable] = useState<boolean>(false);
	const [scheduleRepeatEveryDays, setScheduleRepeatEveryDays] = useState<number>(1);
	const [scheduleCountQuiz, setScheduleCountQuiz] = useState<number>(1);
	const [reportAddresses, setReportAddresses] = useState<string>('');
	const [isShowConfirmCancel, setIsShowConfirmCancel] = useState<boolean>(false);
	const [isSaving, setSaving] = useState<boolean>(false);
	const [isMixOptionAnswers, setMixOptionAnswers] = useState<boolean>(false);
	const [isShowTestResult, setShowTestResult] = useState<boolean>(true);
	const [recipientsFilter, setRecipientsFilter] = useState<number>(0);
	const [originalSubordinates, setOriginalSubordinates] = useState<any>([]);

	const [subordinates, setSubordinates] = useState<any>({});
	const [treeInfo, setTreeInfo] = useState<any>(genCleanTreeInfo);

	const [validationsErrors, setValidationErrors] = useState<string[]>([]);

	useEffect(() => {
		if (isSaving) {
			save();
		}
	}, [isSaving]);

	const buttons = [
		{ title: 'Магазины'},
		{ title: 'Менеджеры'},
	];

	function loadSubordinateItems(): Promise<void> {
		return ShopsApi.loadSubordinateShops().then(res => {
			const subs = fillSubordinateMap(res);
			setOriginalSubordinates(subs);
			updateSubordinatesByFilter(subs, 0);
		});
	}

	function updateSubordinatesByFilter(subs: any, _recipientsFilter: number): void {
		if (_recipientsFilter === 0) {
			setSubordinates(subs);
			setTreeInfo(genCleanTreeInfo());
			return;
		} else {
			const res = filterSubs(subs) || {};

			setSubordinates(res);
			setTreeInfo(genCleanTreeInfo());
		}

		function filterSubs(user: any): any {
			user = { ...user };
			user.subordinateUsers = user.subordinateUsers?.map(filterSubs).filter(Boolean);
			user.subShopSet = new Set();
			user.subordinateUsers?.forEach(u =>  Array.from(u.subShopSet).forEach(n => user.subShopSet.add(n)));
			return user;
		}
	}

	function loadQuiz(id: number): void {
		setLoading(true);
		QuizApi.fetchQuiz(id).then(res => {
			fillQuiz(res.data);
			setLoading(false);
		});
	}

	function fillQuiz(data: any): void {
		setQuizName(data.title);

		const questions: IQuestion[] = [];
		const questSettings: IQuestionsSettings = data.questionnaireConfig.questionsSettings;

		data.questionnaireConfig.questions.map((item, index) => {
			const question: IQuestion = {
				id: generateId(),
				type: item.type,
				name: item.text,
				photos: item.photos,
				...(item.correctAnswer && { correctAnswer: item.correctAnswer }),
				index,
			};

			if (['RADIO', 'CHECKERS'].includes(item.type)) {
				question.options = item.options.map((item, index) => {
					return {
						id: generateId(),
						value: item.value,
						index,
						correctAnswer: item.correctAnswer,
					};
				});
			}

			if (item.type) {
				question.photos = item.photos?.map((item, index) => {
					return {
						id: item,
						src: `/api/file/download/${item}?size=middle`,
						index,
					};
				});
			}

			questions.push(question);
		});

		setQuestions(questions);

		setMixOptionAnswers(questSettings.mixOptionAnswers);

		setShowTestResult(questSettings.showTestResult);

		setScheduleStartAtDate(data.executionConfig.startAtDate);

		setScheduleFinishAtDate(data.executionConfig.finishAtDate);

		if (data.executionConfig.startAtTime) {
			setScheduleStartAtTime(data.executionConfig.startAtTime);
		}

		if (data.executionConfig.finishAtTime) {
			setScheduleFinishAtTime(data.executionConfig.finishAtTime);
		}

		if (data.executionConfig.repeatEveryDays) {
			setScheduleIsRepeatable(true);
			setScheduleRepeatEveryDays(data.executionConfig.repeatEveryDays);
		}

		if (data.executionConfig.repeatCount) {
			setScheduleCountQuiz(data.executionConfig.repeatCount);
		}

		setReportAddresses(data.reportConfig.addressees.join('\n'));

		const filledCheckedSubordinatesInfo = genCleanTreeInfo();
		if (data.addresseesShopIds?.length) {
			filledCheckedSubordinatesInfo.checkedShops = new Set(data.addresseesShopIds);
		} else {
			filledCheckedSubordinatesInfo.checkedManagers = new Set(data.addresseesManagerIds);
			setRecipientsFilter(1);
		}

		setTreeInfo(filledCheckedSubordinatesInfo);
	}

	function addQuestion(type: string): void {
		const question: IQuestion = {
			id: generateId(),
			type,
			name: '',
			photos: [],
		};

		if (['RADIO', 'CHECKERS'].includes(type)) {
			question.options = [];
		}

		setQuestions([...questions, question]);
	}

	function deleteQuestion(id: number): void {
		setQuestions(questions.filter((item) => item.id !== id).map(question => {
			return question;
		}));
	}

	function handleQuestionNameChange(id: number, name: string): void {
		setQuestions(questions.map(item => {
			if (item.id === id) {
				item.name = name;
			}
			return item;
		}));
	}

	function addQuestionOption(id: number): void {
		setQuestions(questions.map(item => {
			if (item.id === id) {
				item.options?.push({
					id: generateId(),
					value: ''
				});
			}
			return item;
		}));
	}

	function handleQuestionOptionChange(questionId: number, optionId: number, value: string): void {
		setQuestions(questions.map(question => {
			if (question.id === questionId) {
				question.options?.map(option => {
					if (option.id === optionId) {
						option.value = value;
					}
				});
			}
			return question;
		}));
	}

	function handleQuestionFloatChange(questionId: number, value: string): void {
		setQuestions(questions.map(question => {
			if (question.id === questionId) {
				question.correctAnswer = value;
			}
			return question;
		}));
	}

	// Перемешивать варианты ответов
	function handleMixOptionAnswers(): void {
		setMixOptionAnswers(!isMixOptionAnswers);
	}

	// Показывать реузультаты теста после прохождения
	function handleShowTestResult(): void {
		setShowTestResult(!isShowTestResult);
	}

	function deleteQuestionOption(questionId: number, optionId: number): void {
		setQuestions(questions.map(question => {
			if (question.id === questionId) {
				question.options = question.options?.filter(option => option.id !== optionId);
			}

			return question;
		}));
	}

	function selectCorrectAnswerOption(questionId: number, optionId: number): void {
		setQuestions(questions.map(question => {
			if (question.id === questionId) {
				if (question.type === QuestionnaireQuestionTypeEnum.RADIO) {
					question.options?.map(option => {
						if (option.id === optionId) {
							option.correctAnswer = true;
						} else {
							option.correctAnswer = false;
						}
					});
				} else {
					question.options?.map(option => {
						if (option.id === optionId) {
							option.correctAnswer = !option.correctAnswer;
						}
					});
				}
			}

			return question;
		}));
	}

	function handleScheduleStartAtDate(value: string): void{
		setScheduleStartAtDate(value);
	}

	function handleScheduleStartAtTime(value: string): void{
		setScheduleStartAtTime(value);
	}

	function handleScheduleFinishAtDate(value: string): void{
		setScheduleFinishAtDate(value);
	}

	function handleScheduleFinishAtTime(value: string): void{
		setScheduleFinishAtTime(value);
	}

	function handleScheduleRepeatEveryDays(value: string): void{
		setScheduleRepeatEveryDays(+value);
	}

	function handleScheduleCoutQuiz(value: string): void{
		setScheduleCountQuiz(+value);
	}

	function handleScheduleIsRepeatable(value: boolean): void{
		setScheduleIsRepeatable(value);
	}

	function handleReportAddresses(value: string): void{
		setReportAddresses(value);
	}

	function changeShopSelection(checkedSubordinates: CheckedSubordinatesValue): void {
		setTreeInfo({...checkedSubordinates});
	}

	function onSortEnd(oldIndex: number, newIndex: number): void {
		setQuestions((value) => arrayMove(value, oldIndex, newIndex));
	}

	function validate(): any {
		const errors: any = {};

		if (!quizName) {
			errors.title = 'Введите название теста';
		}

		if (!questions.length) {
			errors.emptyQuestions = 'Тест должен содержать хотя бы 1 вопрос';
		}

		questions.forEach(question => {
			delete question.warning;

			if (!question.name) {
				question.warning = 'Заполните текст вопроса';
				errors.name = 'Заполните все данные в вопросах';
			}

			if (question.type === QuestionnaireQuestionTypeEnum.FLOAT) {
				if (!question.correctAnswer?.length) {
					question.warning = 'Добавьте правильный ответ';
					errors.hasEmptyFloatInput = 'Правильный ответ не должен быть пустым';
				}
			}

			if (['RADIO', 'CHECKERS'].includes(question.type)) {
				if (question.type === QuestionnaireQuestionTypeEnum.RADIO) {
					if (!question.options?.length || question.options.length < 2) {
						question.warning = 'Добавьте варианты ответов';
						errors.hasRadioOptions = 'Добавьте хотя бы 2 ответа для каждого вопроса с одиночным выбором';
					}
				}

				if (question.type === QuestionnaireQuestionTypeEnum.CHECKERS) {
					if (!question.options?.length || question.options.length < 1) {
						question.warning = 'Добавьте варианты ответов';
						errors.hasCheckersOptions = 'Добавьте хотя бы 1 ответ для каждого вопроса с множественным выбором';
					}
				}

				question.options?.forEach(option => {
					if (!option.value) {
						question.warning = 'Заполните варианты ответов';
						errors.name = 'Заполните все данные в вопросах';
					}
					if (question.options?.every(o => !o.correctAnswer)) {
						question.warning = 'Добавьте правильные варианты ответов';
						errors.emptyCorrectAnswer = 'Необходимо указать хотя бы 1 правильный вариант ответа';
					}
				});
			}
		});

		if (recipientsFilter === 0) {
			if (!treeInfo.checkedShops.size) {
				errors.recipients = 'Выберите получателей';
			}
		} else {
			if (!treeInfo.checkedUsers.size) {
				errors.recipients = 'Выберите получателей';
			}
		}

		if (
			!scheduleStartAtDate ||
			!scheduleFinishAtDate ||
			!scheduleStartAtTime ||
			!scheduleFinishAtTime ||
			moment(`${scheduleStartAtDate} ${scheduleStartAtTime}`).isSameOrAfter(
				moment(`${scheduleFinishAtDate} ${scheduleFinishAtTime}`)
			)
		) {
			errors.time = 'Измените время начала теста';
		}
		if (scheduleIsRepeatable) {
			if (scheduleRepeatEveryDays < 1) {
				errors.repeatEveryDays = 'Некорректное значение для повтора теста';
			}
		}
		if (
			!scheduleStartAtDate ||
			!scheduleStartAtTime ||
			moment(`${scheduleStartAtDate} ${scheduleStartAtTime}`).isSameOrBefore(new Date())
		) {
			errors.time = 'Измените время начала теста';
		} else if (
			!scheduleFinishAtDate ||
			!scheduleFinishAtTime ||
			moment(`${scheduleFinishAtDate} ${scheduleFinishAtTime}`).isSameOrBefore(
				moment(`${scheduleStartAtDate} ${scheduleStartAtTime}`)
			)
		) {
			errors.time = 'Измените время окончания теста';
		}

		if (recipientsFilter === 0) {
			if (scheduleCountQuiz < 1 || scheduleCountQuiz > 10) {
				errors.number = 'Количество прохождений теста может быть от 1 до 10';
			}
		}

		const diff = moment(`${scheduleFinishAtDate}`).diff(moment(`${scheduleStartAtDate}`));
		const days = diff / 86400_000;

		if(days >= 7) {
			errors.time = 'Тест можно запускать максимум на 7 суток';
		}

		if (reportAddresses) {
			const emails = reportAddresses.trim().split('\n');

			for (let i in emails) {
				const error = validateIsEmail()(emails[i]);

				if (error) {
					errors.reportAddresses = 'Введите валидные адреса для получателей отчетов';
					break;
				}
			}
		}

		return errors;
	}

	function validationErrors(): ReactElement | null {
		if (!validationsErrors.length) {
			return null;
		}

		return (
			<>
				<ul className="validation-errors">
					{validationsErrors.map(error => {
						return (
							<li key={error}>{error}</li>
						);
					})}
				</ul>
			</>
		);
	}

	function save(): void {
		const errors = validate();

		if (Object.keys(errors).length) {
			setValidationErrors(Object.values(errors));
			setSaving(false);
			return;
		}

		const payload: any = {
			title: quizName,
			quizType: QuestionnaireTypeEnum.TESTING,
			questionnaireConfig: {
				questions: questions.map((question: IQuestion) => {
					const allPhotos = question.photos.map(p => {
						const result: any[] = [];
						if (p.isNew) {
							result.push(p);
						} else if (!p.isNew) {
							result.push(p.id);
						}
						return result;
					}).flat(1);
					const item: any = {
						text: question.name,
						type: question.type,
						photos: allPhotos,
					};

					if (question.type === QuestionnaireQuestionTypeEnum.FLOAT) {
						item.correctAnswer = question.correctAnswer;
					}

					if (['RADIO', 'CHECKERS'].includes(question.type)) {
						item.options = question.options?.map((option, optionIndex) => ({ index: optionIndex, value: option.value, correctAnswer: option.correctAnswer || false }));
					}

					return item;
				}),
				questionsSettings: {
					mixOptionAnswers: isMixOptionAnswers,
					showTestResult: isShowTestResult
				}
			},
			...(recipientsFilter === 0 && { addresseesShopIds: Array.from(treeInfo.checkedShops) }),
			...(recipientsFilter === 1 && { addresseesManagerIds: Array.from(treeInfo.checkedUsers) }),
			reportConfig: {
				messageTitle: 'Отчет по тесту',
				addressees: reportAddresses.split('\n').map(s => s.trim()).filter(Boolean),
			},
			executionConfig: {
				startAtDate: scheduleStartAtDate,
				startAtTime: scheduleStartAtTime,
				finishAtDate: scheduleFinishAtDate,
				finishAtTime: scheduleFinishAtTime,
				repeatCount: scheduleCountQuiz
			}
		};

		if (scheduleIsRepeatable) {
			payload.executionConfig.repeatEveryDays = scheduleRepeatEveryDays;
		}

		QuizApi.createQuiz(payload).then(() => {
			if (quizId) {
				QuizApi.deleteQuiz(quizId).then(() => {
					history.push('/admin/quiz');
				});
			}
			else {
				history.push('/admin/quiz');
			}
		});
	}

	function cancel(): void {
		setIsShowConfirmCancel(false);
		history.push('/admin/quiz');
	}

	useEffect(() => {
		loadSubordinateItems()
			.then(() => {
				if (quizId) {
					loadQuiz(quizId);
				}
			});
	}, []);

	useEffect(() => {
		const options: IOption[] = [];

		questions.map(question => {
			if (['RADIO', 'CHECKERS'].includes(question.type)) {
				options.push({
					id: question.id,
					value: question.name
				});
			}
		});

	}, [questions]);

	if (isLoading) return <Spinner />;


	const PHOTO_LIMIT_UPLOAD = 3;

	// добавление фото
	function onAddPhoto(newPhoto, id) {
		setQuestions(questions.map((q, index) => {
			if (q.id === id) {
				q.photos.push({
					id: generateId(),
					src: newPhoto,
					index,
					isNew: true
				});
			}
			return q;
		}));
	}

	// удаление фото
	function onRemovePhoto(index, id) {
		setQuestions(questions.map(q => {
			if (q.id === id) {
				q.photos.splice(index, 1);
			}
			return q;
		}));
	}

	function filterChange (newRecipientsFilter) {
		updateSubordinatesByFilter(originalSubordinates, newRecipientsFilter);
		setRecipientsFilter(newRecipientsFilter);
	}

	return (
		<>
			<Link style={{ display: 'inline-block', marginBottom: '0.3rem' }} to="/admin/quiz">
				<Icon name="arrow left" />
				Вернуться в Опросы
			</Link>
			<div style={{ marginBottom: '1rem' }}>
				<Header as="h3" icon="edit outline" content={`${quizId ? 'Редактирование' : 'Создание'} теста${quizId ? ` #${quizId}` : ''}`} />
			</div>
			<Breadcrumb>
				<Breadcrumb.Section active={step === 1} onClick={step !== 1 ? () => {setStep(1);} : undefined}>Список вопросов</Breadcrumb.Section>
				<Breadcrumb.Divider content={<span>&nbsp;|&nbsp;</span>} />
				<Breadcrumb.Section active={step === 2} onClick={step !== 2 ? () => {setStep(2);} : undefined}>Получатели</Breadcrumb.Section>
				<Breadcrumb.Divider content={<span>&nbsp;|&nbsp;</span>} />
				<Breadcrumb.Section active={step === 3} onClick={step !== 3 ? () => {setStep(3);} : undefined}>Настройки</Breadcrumb.Section>
			</Breadcrumb>
			<br/>

			{/* Step 1 */}
			<Segment hidden={step !== 1}>
				<TitleTestStyle>
					<div className="header_input">
						<Header as="h4" content="Название теста" />
						<Input
							value={quizName}
							fluid
							onChange={event => {setQuizName(event.target.value);}}
						/>
					</div>
					<div className="test_settings">
						<div className="question-item__actions">
							<label>
								<Input
									className="question-item__checkbox"
									type='checkbox'
									onChange={handleMixOptionAnswers}
									checked={isMixOptionAnswers}
								/>
								Перемешать варианты ответов
							</label>
							<label>
								<Input
									className="question-item__checkbox"
									type='checkbox'
									onChange={handleShowTestResult}
									checked={isShowTestResult}
								/>
								Показать результаты после прохождения
							</label>
						</div>
					</div>
				</TitleTestStyle>
				<br/>

				<SortableList
					onSortEnd={onSortEnd}
					className="questions"
					lockAxis="y"
				>
					{questions.map((question: IQuestion, questIndex: number) => {
						const isLimitPhoto = question.photos?.length < PHOTO_LIMIT_UPLOAD;

						const photoSrsc = question.photos;
						return (
							<SortableItem key={question.id}>
								<div className={`question-item ${question.warning ? '__warning' : ''}`}>
									<div className="question-item__content">
										<p>
											{question.type === QuestionnaireQuestionTypeEnum.FLOAT && 'Числовой вопрос'}
											{question.type === QuestionnaireQuestionTypeEnum.RADIO && 'Одиночный выбор'}
											{question.type === QuestionnaireQuestionTypeEnum.CHECKERS && 'Множественный выбор'}
										</p>

										{question.warning ? (
											<WarnBubble>{question.warning}</WarnBubble>
										) : null}

										<div>
											<Input fluid placeholder="Введите вопрос"
												   value={question.name}
												   onChange={event => {handleQuestionNameChange(question.id, event.target.value);}}
												   style={{ marginBottom: '0.5rem' }}
											/>
										</div>

										{question.type === QuestionnaireQuestionTypeEnum.FLOAT && (
											<label>
												<span style={{ marginRight: '5px' }}>Правильный ответ:</span>
												<Input
													type="number"
													placeholder="100.00"
													value={question.correctAnswer}
													onChange={event => handleQuestionFloatChange(question.id, event.target.value)}
												/>
											</label>
										)}
										{['RADIO', 'CHECKERS'].includes(question.type) && (
                                            <>
												{question.options?.map((option: IOption, i: number) => {
													return (
														<AnswerOptionStyle key={option.id}>
															{question.type === QuestionnaireQuestionTypeEnum.CHECKERS ? (
																<Checkbox
																	style={{
																		transform: 'scale(1.2)',
																		marginRight: '10px'
																	}}
																	type="checkbox"
																	name={`radioGroup-${question.id}`}
																	checked={option.correctAnswer}
																	onClick={() => selectCorrectAnswerOption(question.id, option.id)}
																/>
															) : null}
															{question.type === QuestionnaireQuestionTypeEnum.RADIO ? (
																<Checkbox
																	style={{
																		transform: 'scale(1.2)',
																		marginRight: '10px'
																	}}
																	type="radio"
																	radio
																	name={`radioGroup-${question.id}`}
																	checked={option.correctAnswer}
																	onClick={() => selectCorrectAnswerOption(question.id, option.id)}
																/>
															) : null}
															<Input
																placeholder="Вариант ответа"
																value={option.value}
																onChange={event => handleQuestionOptionChange(question.id, option.id, event.target.value)}
															/>
															<Icon name="remove circle"
																  onClick={() => {deleteQuestionOption(question.id, option.id);}}
																  style={{ cursor: 'pointer', marginLeft: '0.3rem' }} />
														</AnswerOptionStyle>
													);
												})}
                                                <div onClick={() => {addQuestionOption(question.id);}} style={{ display: 'inline-block', cursor: 'pointer' }}>
                                                    <Icon name="plus circle" />
                                                    Добавить
                                                </div>
                                            </>
										)}
									</div>

									<PhotoSection>
										<UiPhotoGallery2 photos={photoSrsc}
														 onAddPhoto={isLimitPhoto ? (newPhoto) => { onAddPhoto(newPhoto, question.id); } : undefined}
														 readOnly={!isLimitPhoto}
														 isLimit={!isLimitPhoto}
														 onRemovePhoto={(index) => onRemovePhoto(index, question.id)} />
									</PhotoSection>

									<Icon name="remove circle"
									      className="question-item__remove"
									      onClick={() => { deleteQuestion(question.id); }} />

									<SortableKnob>
										<Knob />
									</SortableKnob>

								</div>
							</SortableItem>
						);
					})}
				</SortableList>

				<div className="question-actions">
					<Button onClick={() => { addQuestion(QuestionnaireQuestionTypeEnum.FLOAT); }}>Вопрос "Число"</Button>
					<Button onClick={() => { addQuestion(QuestionnaireQuestionTypeEnum.RADIO); }}>Вопрос "Одиночный выбор"</Button>
					<Button onClick={() => { addQuestion(QuestionnaireQuestionTypeEnum.CHECKERS); }}>Вопрос "Множественный выбор"</Button>
				</div>
			</Segment>

			{/* Step 2 */}
			<div hidden={step !== 2}>
				<ShopAndManagersStyle>
					<ButtonGroup size="tiny">
						{buttons.map((b, i) => (
							<Button key={i} active={i === recipientsFilter} color={i === recipientsFilter ? 'grey' : undefined} onClick={() => filterChange(i)} content={b.title}/>
						))}
					</ButtonGroup>
				</ShopAndManagersStyle>
				<p style={{ margin: '0.5rem 0 0 0.5rem'}}>Тест будет назначен для всех выбранных {recipientsFilter === 0 ? 'магазинов' : 'менеджеров'}</p>
				<AddresseeSelector
					style={{ marginTop: '1rem' }}
					filter={recipientsFilter === 1 ? recipientsFilter : undefined}
					subordinates={subordinates}
					treeInfo={treeInfo}
					onChange={changeShopSelection}
				/>
			</div>

			{/* Step 3 */}
			<Segment hidden={step !== 3}>
				<Header as="h4" content="Расписание теста" />

				<div className="schedule__container">
					<div className="schedule__start">
						<div className="schedule__title">
							Начало теста
						</div>
						<Input
							className="schedule__date"
							type="date"
							value={scheduleStartAtDate}
							onChange={event => handleScheduleStartAtDate(event.target.value)}
						/>
							<Input
								className="schedule__start-time"
								type="time"
								value={scheduleStartAtTime}
								onChange={event => handleScheduleStartAtTime(event.target.value)}
							/>
					</div>

					<div className="schedule__finish">
						<div className="schedule__title">
							Окончание теста
						</div>
						<Input
							className="schedule__date"
							type="date"
							value={scheduleFinishAtDate}
							onChange={event => handleScheduleFinishAtDate(event.target.value)}
						/>
								<Input
								className="schedule__finish-time"
								type="time"
								value={scheduleFinishAtTime}
								onChange={event => handleScheduleFinishAtTime(event.target.value)}
							/>
					</div>
				</div>



				<div className="schedule__repeatable">
					<label>
						<Input
							className="schedule__radio"
							type="radio"
							name="schedule"
							checked={!scheduleIsRepeatable}
							onChange={() => handleScheduleIsRepeatable(false)}
						/>
						Запустить единоразово
					</label>
				</div>

				<div className="schedule__repeatable">
					<label>
						<Input
							className="schedule__radio"
							type="radio"
							name="schedule"
							checked={scheduleIsRepeatable}
							onChange={() => handleScheduleIsRepeatable(true)}
						/>
						Повторять через каждые
						<Input
							className="schedule__repeat-days"
							type="number"
							min="1"
							step="1"
							value={scheduleRepeatEveryDays || ''}
							disabled={!scheduleIsRepeatable}
							onChange={event => handleScheduleRepeatEveryDays(event.target.value)}
						/>
						дней
					</label>
				</div>

				{recipientsFilter === 0 ? (
					<div className="schedule__counter">
						<label>
							Количество прохождений теста
							<Input
								className="schedule__counter-count"
								type="number"
								min="1"
								max="10"
								step="1"
								value={scheduleCountQuiz || ''}
								onChange={event => handleScheduleCoutQuiz(event.target.value)}
							/>
						</label>
					</div>
				) : null}

				<div className="report">
					<Header as="h4" content="Получатели отчета" />
					<Form>
						<TextArea value={reportAddresses}
						          style={{ width: '300px', height: '100px' }}
					              onChange={event => handleReportAddresses(event.target.value)} />
					</Form>
				</div>

			</Segment>

			{validationErrors()}

			<div className="actions">
				<Button
					icon="arrow left"
					labelPosition="left"
					disabled={isSaving}
					content="Отмена"
					onClick={() => setIsShowConfirmCancel(true)}
				/>
				<Button
					positive
					icon="save"
					disabled={isSaving}
					labelPosition="left"
					content="Сохранить"
					onClick={() => setSaving(true)}
				/>
			</div>

			{isSaving ? (
				<Spinner text='Сохранение...' />
			) : null}

			{isShowConfirmCancel ? (
				<Modal open>
					<Modal.Content>
						<p>
							Данные не будут сохранены.
						</p>
						<p>
							Вы действительно хотите выйти?
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={() => setIsShowConfirmCancel(false)} content="Нет" />
						<Button onClick={cancel} content="Да" />
					</Modal.Actions>
				</Modal>
			) : null}
		</>
	);
}
