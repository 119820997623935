import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { FileMarkerType, PageInfo } from 'common-lib';
import { get, set } from '~tools/localConfig';
import { Pagination, Spinner } from '~components';
import DiagnosticsIncidentMarkingFetcher from '~api/DiagnosticsIncidentMarkingFetcher';

const GalleryStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(${o => o.cols || 5}, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  margin: 1rem 0;
`;

const ItemStyle = styled.div`
  border: 1px solid #ccc;
  padding: 1px;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(50, 50, 0, .3);
  }

  .image-block {
    position: relative;

    .cloud {
      position: absolute;
      left: 2px;
      top: 2px;
      width: 16px;
      height: 16px;
      background-image: url("/assets/img/ui/cloud.png");
      z-index: 2;
    }

    .good {
      position: absolute;
      left: 22px;
      top: 2px;
      width: 16px;
      height: 16px;
      background-image: url("/assets/img/ui/good.png");
      z-index: 2;
    }

    .bad {
      position: absolute;
      left: 22px;
      top: 2px;
      width: 16px;
      height: 16px;
      background-image: url("/assets/img/ui/bad.png");
      z-index: 2;
    }

    .pred {
      position: absolute;
      left: 40px;
      top: 0;
      padding: 1px 3px;
      font-size: 11px;
      line-height: 11px;
      color: white;
      background-color: rgba(20, 20, 20, .8);
    }
  }
`;

const ItemImageStyle = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  min-width: 120px;
  min-height: 120px;
`;

const ImageHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .hover-bad, .hover-match, .hover-later {
    padding-top: 10%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    color: #eee;
    text-align: center;

    .icon {
      font-size: 20px;
    }

    &:hover {
      opacity: 0.8;
      background: #222;
    }
  }

  .hover-bad {
    top: 0;
    left: 0;
    right: 50%;
    bottom: 50%;

    &.active {
      opacity: 0.9;
      background-color: rgba(200, 100, 100, .7);
    }
  }

  .hover-match {
    top: 0;
    left: 50%;
    right: 0;
    bottom: 50%;

    &.active {
      opacity: 0.9;
      background-color: rgba(100, 200, 100, .7);
    }
  }

  .hover-later {
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;

    &.active {
      opacity: 0.9;
      background-color: rgba(100, 100, 200, .7);
    }
  }
`;

const PAGE_SIZE_OPTIONS = [24, 40, 65, 90, 126, 160, 189, 230].map(size => ({
	key: String(size),
	text: String(size),
	value: size,
}));

const FILTER_FILE_MARKER_ALL = 'all';

const FILTER_FILE_MARKERS = [
	{ key: 'all', value: FILTER_FILE_MARKER_ALL, text: 'Все' },
	{ key: 'unmarked', value: 'unmarked', text: 'Метка: отсутствует' },
	{ key: 'marked', value: 'marked', text: 'Метка: любая' },
	{ key: FileMarkerType.BAD, value: FileMarkerType.BAD, text: 'Метка: BAD' },
	{ key: FileMarkerType.MATCH, value: FileMarkerType.MATCH, text: 'Метка: MATCH' },
	{ key: FileMarkerType.LATER, value: FileMarkerType.LATER, text: 'Метка: LATER' },
	{ key: 'scored', value: 'scored', text: 'Оценка: есть' },
	{ key: 'unscored', value: 'unscored', text: 'Оценка: отсутствует' },
];

export const DIM_PAGE_INFO = 'DIAGNOSTICS.INCIDENT_MARKING.PAGE_INFO';

const dimPageInfo = get(DIM_PAGE_INFO, '');

const fetcher = new DiagnosticsIncidentMarkingFetcher();

type Props = {
	location: any
	history: any
}

export default function AdminDiagnosticsIncidentMarkingPage(props: Props) {
	const [{ items, pageInfo, checkQueryString, isItemsRequesting }, setItems] = useState<any>({});

	const curPageInfo = PageInfo.parseFromString(props.location.search || dimPageInfo,
		{ pageSize: 40 });

	if (items === undefined || curPageInfo.toQueryString() !== checkQueryString) {
		if (!isItemsRequesting) {
			setItems({ items, pageInfo, checkQueryString, isItemsRequesting: true });
			fetcher.getList(curPageInfo).then(json => {
				setItems({
					items: fillItems(json),
					pageInfo: json.pageInfo,
					checkQueryString: curPageInfo.toQueryString(),
				});
			});
		}
		return <Spinner />;
	}

	const updatePageInfo = obj => {
		curPageInfo.update(obj);
		const search = curPageInfo.toQueryString();
		set(DIM_PAGE_INFO, search);
		props.history.push(`${props.location.pathname}?${search}`);
	}

	const updatePageInfoFileMarker = fileMarker => {
		if (fileMarker === FILTER_FILE_MARKER_ALL) {
			delete curPageInfo.customParams?.fileMarker;
		} else if (curPageInfo.customParams) {
			curPageInfo.customParams.fileMarker = fileMarker;
		}
		curPageInfo.pageIndex = 0;
		console.debug('%c*** curPageInfo=', 'background: #eee; color: blue', curPageInfo);
		const search = curPageInfo.toQueryString();
		set(DIM_PAGE_INFO, search);
		props.history.push(`${props.location.pathname}?${search}`);
	}

	// 24, 40, 65, 90, 126, 160, 189, 230
	const cols = pageInfo.pageSize <= 24 ? 3
		: pageInfo.pageSize <= 40 ? 4
			: pageInfo.pageSize <= 65 ? 5
				: pageInfo.pageSize <= 90 ? 6
					: pageInfo.pageSize <= 126 ? 7
						: pageInfo.pageSize <= 160 ? 8
							: pageInfo.pageSize <= 189 ? 9 : 10;

	const imageSize = cols < 5 ? 'middle' : 'small';

	const updateMarker = (id, marker) => {
		const item = items.find(i => i.id === id);
		if (item.fileMarker === marker) {
			item.fileMarker = null;
			fetcher.updateOne(item.fileId, { marker: null });
		} else {
			if (!item.fileMarker) {
				fetcher.createOne({ fileId: item.fileId, marker });
			} else {
				fetcher.updateOne(item.fileId, { marker });
			}
			item.fileMarker = marker;
		}
		setItems({ items, pageInfo, checkQueryString });
	}

	return (
		<>
			<h1>
				<Link to="/admin/diagnostics">
					<Icon name="arrow left" size="small" />
				</Link>
				Маркировка инцидентов
			</h1>
			<Dropdown inline size="small" options={FILTER_FILE_MARKERS}
			          defaultValue={curPageInfo.customParams?.fileMarker || FILTER_FILE_MARKER_ALL}
			          onChange={(_, { value: fileMarker }) => updatePageInfoFileMarker(fileMarker)} />
			<div>Всего: {pageInfo.allCount} файлов, {pageInfo.pageCount} страниц</div>
			<GalleryStyle cols={cols}>
				{items.map(i => renderItem(i, imageSize, updateMarker.bind(null, i.id)))}
			</GalleryStyle>
			<Pagination pageInfo={pageInfo}
			            onChangeIndex={pageIndex => updatePageInfo({ pageIndex })}
			            sizeOptions={PAGE_SIZE_OPTIONS}
			            onChangeSize={pageSize => updatePageInfo({ pageSize })} />
		</>
	);
}

function fillItems(json) {
	const { data } = json;
	return data;
}

function renderItem(item, imageSize, onMark) {
	const { id, fileId, fileMarker, goodName, fileLocation, filePathName, fileScore } = item;
	// по типу хранения фотографии определяем откуда будем грузить
	const isOnCloud = fileLocation === 'CDN';
	const url = isOnCloud
		? filePathName
		: `/api/file/download/${fileId}?size=${imageSize}`;
	const mark = (fileScore === undefined || fileScore === null) ? undefined
		: fileScore >= 0.9 ? 'good' : 'bad';
	return (
		<ItemStyle key={id}>
			<div className="image-block">
				<ItemImageStyle src={url} alt="" />
				<ImageHover>
					{isOnCloud ? <div className="cloud" /> : null}
					{mark ? (
						<>
							<div className={mark} />
							<div className="pred">{Math.floor(fileScore * 1e6) / 1e6}</div>
						</>
					) : null}
					<div
						className={`hover-bad ${fileMarker === FileMarkerType.BAD ? 'active' : ''}`}
						onClick={() => onMark(FileMarkerType.BAD)}>
						<Icon name="thumbs down outline" />
						<br />
						Плохо
					</div>
					<div
						className={`hover-match ${fileMarker === FileMarkerType.MATCH ? 'active' : ''}`}
						onClick={() => onMark(FileMarkerType.MATCH)}>
						<Icon name="check circle outline" />
						<br />
						Совпадает
					</div>
					<div
						className={`hover-later ${fileMarker === FileMarkerType.LATER ? 'active' : ''}`}
						onClick={() => onMark(FileMarkerType.LATER)}>
						<Icon name="clock outline" />
						<br />
						На потом
					</div>
				</ImageHover>
			</div>
			<div>fid {fileId}</div>
			<div>{goodName}</div>
		</ItemStyle>
	);
}
