import React, { ReactNode } from 'react';
import { Accordion, Button, Checkbox, Dropdown, Form, Header, Input, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import merge from 'deepmerge';
import { IncidentType, IncidentTypeCardTemplateValue, IncidentTypeDto, IncidentTypeErrors,
	IncidentTypeInputDataValue, IncidentTypeInputDataValue2, IncidentTypeInputDataValue2Id,
	IncidentTypeOutputDataValue, IncidentTypeSolutionDataCentralPhotoValue,
	IncidentTypeSolutionDataRequiredFormula, IncidentTypeSolutionDataValue,
	IncidentTypeValueFormat, isDev } from 'common-lib';
import { CancelSaveDeleteButtons, Spinner } from '~components';
import IncidentTypesFetcher from '~api/IncidentTypesFetcher';

const ICON_NAME = 'clipboard outline';

const FieldStyled = styled.div`
  margin: 10px 0;
  border: 1px solid silver;
  border-radius: 4px;

  .field-head, .field-value {
    margin: 5px 5px 5px 10px;
    display: flex;
    align-items: start;

    input {
      border: 0 !important;
      border-bottom: 2px solid #DEDEDF !important;
      border-radius: 0 !important;
      margin: 0 5px !important;
      padding: 5px !important;

      &:focus {
        border-bottom-color: #61AFD3 !important;
      }
    }
  }

  .field-value b {
    min-width: 50px;
    padding-right: 20px;
  }
`;

const IdItemStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 10px;
  padding: 5px;
  border: 1px solid silver;
  border-radius: 6px;

  input {
    border: 0 !important;
    border-bottom: 2px solid transparent !important;
    border-radius: 0 !important;
    margin: 0 5px !important;
    padding: 5px !important;
    min-width: 100px !important;
    width: inherit !important;

    &:focus {
      border-bottom-color: #61AFD3 !important;
    }
  }

  & > div:last-child {
    flex: 1;
    text-align: right;
  }
`;

type FormChanges = {
	hasChanges: boolean
	name: boolean
	type: boolean
	dataSeparator: boolean
	inputDataChanged: boolean
	cardTemplateChanged: boolean
	solutionDataChanged: boolean
	outputDataChanged: boolean
}

type Props = {
	match: {
		params: {
			incidentTypeId?: string
		}
	}
}

type State = {
	error?: Error
	savedData?: IncidentTypeDto | null
	formData: IncidentType
	showDebug: boolean
	openedInputData: boolean // открыт блок шаблона входных данных
	openedCardTemplate: boolean // открыт блок шаблона карточки
	openedSolutionData: boolean // открыт блок шаблона решения
	openedOutputData: boolean // открыт блок шаблона резлультата
}

const fetcher = new IncidentTypesFetcher();

class AdminIncidentTypeEditPage extends React.Component<Props, State>
{
	isRequesting: boolean = false;
	incidentTypeId: number | undefined;
	isNew: boolean;
	formChanges: FormChanges | undefined
	formErrors: IncidentTypeErrors | undefined
	formUptime: number = 0 // тег для принудительного обновления списков в форме

	state: State = {
		formData: new IncidentType(),
		showDebug: false,
		openedInputData: false,
		openedCardTemplate: false,
		openedSolutionData: false,
		openedOutputData: false,
	}

	constructor(props: Props) {
		super(props);
		const { incidentTypeId } = props.match.params;
		this.incidentTypeId = Number(incidentTypeId) || undefined;
		this.isNew = incidentTypeId === '+';
	}

	componentDidMount() {
		if (this.isNew) {
			// при создании шаблона используем savedData как объект для сравнения изменений
			this.setState({ savedData: this.state.formData.toDto() });
		} else {
			// загружаем модель
			this.loadIncidentTypeThenFillFormData();
		}
	}

	loadIncidentTypeThenFillFormData() {
		if (this.isRequesting) return;
		this.isRequesting = true;
		fetcher.getOne(this.incidentTypeId!)
			.finally(() => {
				this.isRequesting = false;
			})
			.then((json: any) => {
				const formData: IncidentType = IncidentType.fromDto(json.data);
				const savedData: IncidentTypeDto = formData.toDto();
				this.setState({ savedData, formData });
			})
			.catch(error => {
				this.setState({ savedData: null, error })
			});
	}

	//~~
	// переключение аккордеонов
	//~~

	switchInputData = () => this.setState({ openedInputData: !this.state.openedInputData })

	switchCardTemplate = () => this.setState({ openedCardTemplate: !this.state.openedCardTemplate });


	switchSolutionData = () => this.setState({ openedSolutionData: !this.state.openedSolutionData });


	switchOutputData = () => this.setState({ openedOutputData: !this.state.openedOutputData });

	//~~
	// функции добавления новых полей в блоках
	//~~

	addInputData1 = () => {
		const { data } = this.state.formData;
		data.inputData.push({
			headColumnText: '',
			format: IncidentTypeValueFormat.STRING,
			id: '',
			isRequired: false,
		});
		this.onFormChange({ data });
	}

	addInputData2 = () => {
		const { data } = this.state.formData;
		data.inputData.push({
			headColumnText: '',
			format: 'SPLIT',
			delimiter: '',
			ids: [],
			isRequired: false,
		});
		this.onFormChange({ data });
	}

	addCardTemplate = () => {
		const { data } = this.state.formData;
		data.cardTemplate.push({
			type: 'VALUE',
			title: '',
			from: {
				block: 'inputData',
				field: '',
			},
			format: IncidentTypeValueFormat.STRING,
			decimalSize: 0,
			devOnly: false,
		});
		this.onFormChange({ data });
	}

	addSolutionData1 = () => {
		const { data } = this.state.formData;
		data.solutionData.push({
			id: '',
			type: 'RADIO_OPTIONS',
			isRequired: false,
			options: [],
		});
		this.onFormChange({ data });
	}

	addSolutionData2 = () => {
		const { data } = this.state.formData;
		data.solutionData.push({
			id: '',
			type: 'CENTRAL_PHOTO',
			isRequired: false,
		});
		this.onFormChange({ data });
	}

	addSolutionData3 = () => {
		const { data } = this.state.formData;
		data.solutionData.push({
			id: '',
			type: 'CENTRAL_PHOTO',
			isRequired: false,
			requiredFormula: [],
		});
		this.onFormChange({ data });
	}

	addSolutionData4 = () => {
		const { data } = this.state.formData;
		data.solutionData.push({
			id: '',
			type: 'DOUBLE',
		})
	}

	addOutputData = () => {
		const { data } = this.state.formData;
		data.outputData.push({
			id: '',
			title: '',
			from: {
				block: 'inputData',
				field: '',
			},
		});
		this.onFormChange({ data });
	}

	//~~
	// обновление блока в шаблоне входных данных
	//~~

	onChangeInputDataValue = (inputData: Partial<IncidentTypeInputDataValue>, index: number) => {
		const { data } = this.state.formData;
		data.inputData[index] = merge(data.inputData[index], inputData);
		this.onFormChange({ data });
	}

	onMoveUpInputData = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		const item: IncidentTypeInputDataValue = data.inputData.splice(index, 1)[0];
		data.inputData.splice(index - 1, 0, item);
		this.onFormChange({ data });
	}

	onMoveDownInputData = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		const item: IncidentTypeInputDataValue = data.inputData.splice(index, 1)[0];
		data.inputData.splice(index + 1, 0, item);
		this.onFormChange({ data });
	}

	onDeleteInputData = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		data.inputData.splice(index, 1);
		this.onFormChange({ data });
	}

	onChangeInputDataFormat = (newFormat: string, index: number) => {
		this.formUptime = Date.now();
		// проверка
		if (!IncidentTypeValueFormat[newFormat]) {
			this.setState({ error: new Error(`Формат не соответствует типу IncidentTypeValueFormat`) });
		}
		const { data } = this.state.formData;
		data.inputData[index].format = newFormat as IncidentTypeValueFormat;
		this.onFormChange({ data });
	}

	onInputDataAddSplitId = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		const item = data.inputData[index] as IncidentTypeInputDataValue2;
		item.ids.push({
			index: item.ids.length,
			format: IncidentTypeValueFormat.STRING,
			id: '',
		});
		this.onFormChange({ data });
	}

	onChangeInputDataSplitId = (inputData: Partial<IncidentTypeInputDataValue2Id>, index: number, idIndex: number): void => {
		const { data } = this.state.formData;
		const item = data.inputData[index] as IncidentTypeInputDataValue2;
		item.ids[idIndex] = merge(item.ids[idIndex], inputData);
		this.onFormChange({ data });
	}

	onDeleteInputDataSplitId = (index: number, idIndex: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		const item = data.inputData[index] as IncidentTypeInputDataValue2;
		item.ids.splice(idIndex, 1);
		this.onFormChange({ data });
	}

	getInputDataFields = (): any[] => {
		const { data } = this.state.formData;
		const ids: string[] = [];
		data.inputData.forEach(item => {
			if (item.format === 'SPLIT') {
				item.ids.forEach(idItem => {
					ids.push(idItem.id);
				});
			} else {
				ids.push(item.id);
			}
		});
		return ids.map(s => ({ value: `inputData.${s}`, text: `(Входные) ${s}` }));
	}

	getOutputDataFields = (): any[] => {
		const { data } = this.state.formData;
		const ids: any[] = [];
		data.solutionData.forEach(item => {
			ids.push({ value: `report.${item.id}`, text: `(Отчет) ${item.id}` });
		});
		data.inputData.forEach(item => {
			if (item.format === 'SPLIT') {
				item.ids.forEach(idItem => {
					ids.push(idItem.id);
				});
			} else {
				ids.push({ value: `inputData.${item.id}`, text: `(Входные) ${item.id}` });
			}
		});
		return ids;
	}


	//~~
	// обновление блока в шаблоне карточки
	//~~

	onChangeCardTemplateValue = (inputData: Partial<IncidentTypeCardTemplateValue>, index: number) => {
		const { data } = this.state.formData;
		data.cardTemplate[index] = merge(data.cardTemplate[index], inputData);
		this.onFormChange({ data });
	}

	onMoveUpCardTemplate = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		const item: IncidentTypeCardTemplateValue = data.cardTemplate.splice(index, 1)[0];
		data.cardTemplate.splice(index - 1, 0, item);
		this.onFormChange({ data });
	}

	onMoveDownCardTemplate = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		const item: IncidentTypeCardTemplateValue = data.cardTemplate.splice(index, 1)[0];
		data.cardTemplate.splice(index + 1, 0, item);
		this.onFormChange({ data });
	}

	onDeleteCardTemplate = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		data.cardTemplate.splice(index, 1);
		this.onFormChange({ data });
	}

	//~~
	// обновление блока в шаблоне решения
	//~~

	onChangeSolutionDataValue = (inputData: Partial<IncidentTypeSolutionDataValue>, index: number) => {
		const { data } = this.state.formData;
		data.solutionData[index] = merge(data.solutionData[index], inputData);
		this.onFormChange({ data });
	}

	onChangeSolutionDataRequiredFormulaValue = (formulaIndex: number, inputData: Partial<IncidentTypeSolutionDataRequiredFormula>, index: number) => {
		const { data } = this.state.formData;
		const { requiredFormula } = data.solutionData[index] as IncidentTypeSolutionDataCentralPhotoValue;
		requiredFormula![formulaIndex] = merge(requiredFormula![formulaIndex], inputData)
		this.onFormChange({ data });
	}

	onMoveUpSolutionData = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		const item: IncidentTypeSolutionDataValue = data.solutionData.splice(index, 1)[0];
		data.solutionData.splice(index - 1, 0, item);
		this.onFormChange({ data });
	}

	onMoveDownSolutionData = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		const item: IncidentTypeSolutionDataValue = data.solutionData.splice(index, 1)[0];
		data.solutionData.splice(index + 1, 0, item);
		this.onFormChange({ data });
	}

	onDeleteSolutionData = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		data.solutionData.splice(index, 1);
		this.onFormChange({ data });
	}

	//~~
	// обновление блока в шаблоне результата
	//~~

	onChangeOutputDataValue = (inputData: Partial<IncidentTypeOutputDataValue>, index: number) => {
		const { data } = this.state.formData;
		data.outputData[index] = merge(data.outputData[index], inputData);
		this.onFormChange({ data });
	}

	onMoveUpOutputData = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		const item: IncidentTypeOutputDataValue = data.outputData.splice(index, 1)[0];
		data.outputData.splice(index - 1, 0, item);
		this.onFormChange({ data });
	}

	onMoveDownOutputData = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		const item: IncidentTypeOutputDataValue = data.outputData.splice(index, 1)[0];
		data.outputData.splice(index + 1, 0, item);
		this.onFormChange({ data });
	}

	onDeleteOutputData = (index: number) => {
		this.formUptime = Date.now();
		const { data } = this.state.formData;
		data.outputData.splice(index, 1);
		this.onFormChange({ data });
	}

	// обновление одного из полей
	onFormChange(newFormData: Partial<IncidentTypeDto>) {
		const { formData } = this.state;
		// обновляем поля формы
		if (newFormData.name !== undefined) formData.name = newFormData.name;
		if (newFormData.type !== undefined) formData.type = newFormData.type;
		if (newFormData.data?.separator !== undefined) formData.data.separator = newFormData.data.separator;
		if (newFormData.data?.description !== undefined) formData.data.description = newFormData.data.description;
		if (newFormData.data?.inputData !== undefined) formData.data.inputData = newFormData.data.inputData;
		if (newFormData.data?.cardTemplate !== undefined) formData.data.cardTemplate = newFormData.data.cardTemplate;
		if (newFormData.data?.solutionData !== undefined) formData.data.solutionData = newFormData.data.solutionData;
		if (newFormData.data?.outputData !== undefined) formData.data.outputData = newFormData.data.outputData;
		// после обновления проверяем что изменилось и ищем ошибки
		this.updateFormChanges(formData);
		this.formErrors = formData.validate();
		this.setState({ formData });
	}

	// проверяет, есть ли изменения
	updateFormChanges(newData: IncidentTypeDto): void {
		const savedData = this.state.savedData!;
		const formChanges: FormChanges = {
			hasChanges: false, // будем перезаписано
			name: savedData.name === newData.name,
			type: savedData.type === newData.type,
			dataSeparator: savedData.data.separator === newData.data.separator,
			inputDataChanged: JSON.stringify(savedData.data.inputData) === JSON.stringify(newData.data.inputData),
			cardTemplateChanged: JSON.stringify(savedData.data.cardTemplate) === JSON.stringify(newData.data.cardTemplate),
			solutionDataChanged: JSON.stringify(savedData.data.solutionData) === JSON.stringify(newData.data.solutionData),
			outputDataChanged: JSON.stringify(savedData.data.outputData) === JSON.stringify(newData.data.outputData),
		}
		formChanges.hasChanges = Object.keys(formChanges).some(key => !!formChanges[key]);
		this.formChanges = formChanges;
	}

	//~~
	// кнопки отмены, сохранения и удаления
	//~~

	onCancelClick() {
	}

	onSaveClick() {
	}

	onDeleteClick() {
	}

	//~~
	// рендер
	//~~

	renderForm() {
		const { formData } = this.state;
		return <>
			{formData.id ? (
				<Form.Input readOnly={true}
				            label="Идентификатор (только чтение)"
				            type="text"
				            defaultValue={formData.id} />
			) : null}
			{formData.version ? (
				<Form.Input readOnly={true}
				            label="Текущая версия шаблона (будет увеличена при сохранении)"
				            type="text"
				            defaultValue={formData.version} />
			) : null}
			<Form.Input
				label="Название *"
				placeholder="Пользовательское название"
				type="text"
				error={this.formErrors?.name && formData.name ? this.formErrors.name : undefined}
				defaultValue={formData.name}
				onChange={(_, { value }) => this.onFormChange({ name: value })}
			/>
			<Form.Input
				label="Техническое название *"
				placeholder="Используется в API"
				type="text"
				error={this.formErrors?.type && formData.type ? this.formErrors.type : undefined}
				defaultValue={formData.type}
				onChange={(_, { value }) => this.onFormChange({ type: value })}
			/>
			<Form.Input
				label="Разделитель столбцов в CSV *"
				placeholder="Обычно это ; или ,"
				type="text"
				error={this.formErrors?.dataSeparator && formData.data.separator ? this.formErrors.dataSeparator : undefined}
				defaultValue={formData.data.separator}
				onChange={(_, { value }) => this.onFormChange({
					data: { ...formData.data, separator: value },
				})}
			/>
			<div style={{ fontWeight: 'bold', marginBottom: '1em' }}>
				<Checkbox size="mini"
				          label="Разрешить повторное редактирование задачи"
				          defaultChecked={formData.data.enableChangeAfterSave}
				          onChange={(_, { value }) => this.onFormChange({
					          data: { ...formData.data, enableChangeAfterSave: !!value },
				          })} />
			</div>
			{isDev ? (
				<div style={{ fontWeight: 'bold', color: 'red', marginBottom: '1em' }}>
					<Checkbox size="mini"
					          label="Показать debug"
					          defaultChecked={false}
					          onChange={() => {
						          this.setState({ showDebug: !this.state.showDebug });
					          }} />
				</div>
			) : null}
		</>;
	}

	// рендер одного поля входных данных
	renderInputDataValue = (value: IncidentTypeInputDataValue, index: number): ReactNode => {
		return <FieldStyled key={index}>
			<div className="field-head">
				<div style={{ flex: 1 }}>
					<div>{value.format === 'SPLIT' ? 'Поле с множеством значений' : 'Поле с одним значением'}</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<b>Поле:</b>
						<input type="text"
						       defaultValue={value.headColumnText}
						       placeholder="Название поля во входном CSV"
						       onChange={e => this.onChangeInputDataValue({ headColumnText: e.target.value }, index)} />
					</div>
					<div className="field-value">
						<Checkbox size="mini"
						          label="Обязательное поле"
						          defaultChecked={value.isRequired}
						          onChange={(_, { checked }) => this.onChangeInputDataValue({ isRequired: checked }, index)} />
					</div>
				</div>
				<div>
					{index > 0 ? <Button size="mini"
					                     icon="angle up"
					                     onClick={() => this.onMoveUpInputData(index)} /> : null}
				</div>
				<div>
					{index < this.state.formData.data.inputData.length - 1 ? (
						<Button size="mini"
						        icon="angle down"
						        onClick={() => this.onMoveDownInputData(index)} />
					) : null}
				</div>
				<div>
					<Button size="mini"
					        icon="trash alternate outline"
					        onClick={() => this.onDeleteInputData(index)} />
				</div>
			</div>
			{value.format === 'SPLIT' ? (
				<>
					<div className="field-value">
						<b>Разделитель:</b>
						<Input size="small"
						       defaultValue={value.delimiter}
						       onChange={(_, { value }) => this.onChangeInputDataValue({ delimiter: value }, index)} />
					</div>
					<div className="field-value">
						<b style={{ flex: '1' }}>Идентифкаторы:</b>
						<Button size="mini"
						        icon="plus"
						        onClick={() => this.onInputDataAddSplitId(index)} />
					</div>
					<div>
						{value.ids.map((idItem, idIndex) => (
							<IdItemStyled key={idIndex}>
								<Input type="number"
								       placeholder="Индекс в поле"
								       min={0}
								       max={10}
								       defaultValue={idItem.index}
								       onChange={(_, { value }) => this.onChangeInputDataSplitId({ index: Number(value) }, index, idIndex)} />
								<Dropdown size="mini"
								          options={IncidentTypeValueFormatOptions}
								          defaultValue={idItem.format}
								          onChange={(_, { value }) => this.onChangeInputDataSplitId({ format: value as IncidentTypeValueFormat }, index, idIndex)} />
								<Input type="text"
								       placeholder="ID в шаблоне"
								       defaultValue={idItem.id}
								       onChange={(_, { value }) => this.onChangeInputDataSplitId({ id: value }, index, idIndex)} />
								<div>
									<Button size="mini"
									        icon="trash alternate outline"
									        onClick={() => this.onDeleteInputDataSplitId(index, idIndex)} />
								</div>
							</IdItemStyled>
						))}
					</div>
				</>
			) : (
				<>
					<div className="field-value">
						<b>Тип:</b>
						<Dropdown size="mini"
						          options={IncidentTypeValueFormatOptions}
						          defaultValue={value.format}
						          onChange={(_, { value }) => this.onChangeInputDataFormat(value as string, index)} />
					</div>
					<div className="field-value">
						<b>ID:</b>
						<Input size="small"
						       defaultValue={value.id}
						       onChange={(_, { value }) => this.onChangeInputDataValue({ id: value }, index)} />
					</div>
				</>
			)}
			{this.state.showDebug ? <pre style={{ border: '1px solid silver', margin: '0 5px' }}>
				{JSON.stringify(value, null, '  ')}
			</pre> : null}
		</FieldStyled>;
	}

	// рендер одного поля шаблона карточки
	renderCardTemplateValue = (value: IncidentTypeCardTemplateValue, index: number): ReactNode => {
		return <FieldStyled key={index}>
			<div className="field-head">
				<b>Поле:</b>
				<input type="text"
				       defaultValue={value.title}
				       placeholder="Название поля на карточке"
				       onChange={e => this.onChangeCardTemplateValue({ title: e.target.value }, index)} />
				<div>
					{index > 0 ? <Button size="mini"
					                     icon="angle up"
					                     onClick={() => this.onMoveUpCardTemplate(index)} /> : null}
				</div>
				<div>
					{index < this.state.formData.data.cardTemplate.length - 1 ? (
						<Button size="mini"
						        icon="angle down"
						        onClick={() => this.onMoveDownCardTemplate(index)} />
					) : null}
				</div>
				<div>
					<Button size="mini"
					        icon="trash alternate outline"
					        onClick={() => this.onDeleteCardTemplate(index)} />
				</div>
			</div>
			<div className="field-value">
				<b>Источник:</b>
				<Dropdown size="mini"
				          options={this.getInputDataFields()}
				          defaultValue={`${value.from.block}.${value.from.field}`}
				          onChange={(_, { value }) => this.onChangeCardTemplateValue({ format: value as IncidentTypeValueFormat }, index)} />
			</div>
			<div className="field-value">
				<b>Тип:</b>
				<Dropdown size="mini"
				          options={IncidentTypeValueFormatOptions}
				          defaultValue={value.format}
				          onChange={(_, { value }) => this.onChangeCardTemplateValue({ format: value as IncidentTypeValueFormat }, index)} />
			</div>
			{value.format === IncidentTypeValueFormat.DOUBLE ? (
				<div className="field-value">
					<b>Знаков после запятой:</b>
					<Input type="number"
					       size="mini"
					       min={0}
					       max={10}
					       defaultValue={value.decimalSize}
					       onChange={(_, { value }) => this.onChangeCardTemplateValue({ decimalSize: Number(value) }, index)} />
				</div>
			) : null}
			{this.state.showDebug ? <pre style={{ border: '1px solid silver', margin: '0 5px' }}>
				{JSON.stringify(value, null, '  ')}
			</pre> : null}
		</FieldStyled>;
	}

	// рендер одного поля шаблона решения
	renderSolutionDataValue = (value: IncidentTypeSolutionDataValue, index: number): ReactNode => {
		let fieldLabel: string = 'Неизвестный тип поля';
		if(value.type === 'RADIO_OPTIONS') {
			fieldLabel = 'Поле с выбором значения';
		} else if(value.type === 'CENTRAL_PHOTO' && value['requiredFormula']) {
			fieldLabel = 'Фотография с условием';
		} else if (value.type === 'CENTRAL_PHOTO' && !value['requiredFormula']) {
			fieldLabel = 'Фотография';
		} else if (value.type === 'DOUBLE') {
			fieldLabel = 'Числовое поле';
		}
		return <FieldStyled key={index}>
			<div className="field-head">
				<div style={{ flex: 1 }}>
					<div>{fieldLabel}</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<b>Поле:</b>
						<input type="text"
						       defaultValue={value.id}
						       placeholder="ID в карточке решения"
						       onChange={e => this.onChangeSolutionDataValue({ id: e.target.value }, index)} />
					</div>
					{value.type === 'RADIO_OPTIONS' || !value.requiredFormula ? (
						<Checkbox size="mini"
						          label="Заполнение обязательно"
						          defaultChecked={value.isRequired}
						          onChange={(_, { checked }) => this.onChangeSolutionDataValue({ isRequired: checked }, index)} />
					) : null}
					{value.type === 'CENTRAL_PHOTO' && value.requiredFormula ? (
						<Checkbox size="mini"
						          label="Заполнение обязательно"
						          defaultChecked={value.requiredFormula[0]?.isRequired}
						          onChange={(_, { checked }) => this
							          .onChangeSolutionDataRequiredFormulaValue(0, { isRequired: !!checked }, index)} />
					) : null}
				</div>
				<div>
					{index > 0 ? <Button size="mini"
					                     icon="angle up"
					                     onClick={() => this.onMoveUpSolutionData(index)} /> : null}
				</div>
				<div>
					{index < this.state.formData.data.solutionData.length - 1 ? (
						<Button size="mini"
						        icon="angle down"
						        onClick={() => this.onMoveDownSolutionData(index)} />
					) : null}
				</div>
				<div>
					<Button size="mini"
					        icon="trash alternate outline"
					        onClick={() => this.onDeleteSolutionData(index)} />
				</div>
			</div>
			{value.type === 'CENTRAL_PHOTO' && value.requiredFormula ? (
				<div>
					<div className="field-value">
						<b>Проверяемое поле:</b>
						<Input size="small"
						       defaultValue={value.requiredFormula[0]?.key}
						       onChange={(_, { value }) => this.onChangeSolutionDataRequiredFormulaValue(0, { key: value }, index)} />
					</div>
					<div className="field-value">
						<b>Проверяемое значение:</b>
						<Input size="small"
						       defaultValue={value.requiredFormula[0]?.value}
						       onChange={(_, { value }) => this.onChangeSolutionDataRequiredFormulaValue(0, { value }, index)} />
					</div>
				</div>
			) : null}
			{this.state.showDebug ? <pre style={{ border: '1px solid silver', margin: '0 5px' }}>
					{JSON.stringify(value, null, '  ')}
						</pre> : null}
		</FieldStyled>;
	}

	// рендер одного поля шаблона результата
	renderOutputDataValue = (value: IncidentTypeOutputDataValue, index: number): ReactNode => {
		return <FieldStyled key={index}>
			<div className="field-head">
				<div style={{ flex: 1 }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<b>ID:</b>
						<input type="text"
						       defaultValue={value.id}
						       onChange={e => this.onChangeOutputDataValue({ id: e.target.value }, index)} />
					</div>
				</div>
				<div>
					{index > 0 ? <Button size="mini"
					                     icon="angle up"
					                     onClick={() => this.onMoveUpOutputData(index)} /> : null}
				</div>
				<div>
					{index < this.state.formData.data.solutionData.length - 1 ? (
						<Button size="mini"
						        icon="angle down"
						        onClick={() => this.onMoveDownOutputData(index)} />
					) : null}
				</div>
				<div>
					<Button size="mini"
					        icon="trash alternate outline"
					        onClick={() => this.onDeleteOutputData(index)} />
				</div>
			</div>
			<div className="field-value">
				<b>Заголовок:</b>
				<input type="text"
				       defaultValue={value.title}
				       onChange={e => this.onChangeOutputDataValue({ title: e.target.value }, index)} />
			</div>
			<div className="field-value">
				<b>Источник:</b>
				<Dropdown size="mini"
				          options={this.getOutputDataFields()}
				          defaultValue={`${value.from.block}.${value.from.field}`}
				          onChange={(_, { value }) => {
					          const [block, field] = (value as string).split('.');
					          if (block !== 'report' && block !== 'inputData') {
						          throw new Error('Не верно указан блок источника');
					          }
					          this.onChangeOutputDataValue({ from: { block, field } }, index)
				          }} />
			</div>
			{this.state.showDebug ? <pre style={{ border: '1px solid silver', margin: '0 5px' }}>
					{JSON.stringify(value, null, '  ')}
						</pre> : null}
		</FieldStyled>;
	}

	render() {
		console.debug('%c*** this.state=', 'background: #333; color: aqua', this.state);

		const title = this.isNew ? 'Создание шаблона инцидента' : 'Редактирование шаблона инцидента';

		if (this.state.error) {
			return <>
				<Header as="h3" icon={ICON_NAME} content={title} />
				{renderError(this.state.error)}
			</>;
		} else if (this.isRequesting) {
			return <Spinner />;
		} else if (this.state.savedData === null && !this.isNew) {
			return <>
				<Header as="h3" icon={ICON_NAME} content={title} />
				{renderError('Тип инцидента не найден')}
			</>;
		}

		return (
			<>
				<Header as="h3" icon={ICON_NAME} content={title} />
				<Form style={{ margin: '2rem 0', maxWidth: '600px' }} className="editform">
					{this.renderForm()}
					{/* скрываемый блок для входных данных */}
					<Accordion fluid styled style={{ marginBottom: '1rem' }}>
						<Accordion.Title active={this.state.openedInputData}
						                 icon="dropdown"
						                 content="Входные данные"
						                 onClick={this.switchInputData} />
						{this.state.openedInputData ? (
							<Accordion.Content active key={this.formUptime}>
								<Button content="+ Поле с одним значением"
								        onClick={this.addInputData1} />
								<Button content="+ Поле с несколькими значениями"
								        onClick={this.addInputData2} />
								{this.state.formData.data.inputData.map(this.renderInputDataValue)}
							</Accordion.Content>
						) : null}
					</Accordion>
					{/* скрываемый блок для шаблона карточки */}
					<Accordion fluid styled style={{ marginBottom: '1rem' }}>
						<Accordion.Title active={this.state.openedCardTemplate}
						                 icon="dropdown"
						                 content="Шаблон карточки"
						                 onClick={this.switchCardTemplate} />
						{this.state.openedCardTemplate ? (
							<Accordion.Content active key={this.formUptime}>
								<Button content="+ Поле шаблона" onClick={this.addCardTemplate} />
								{this.state.formData.data.cardTemplate.map(this.renderCardTemplateValue)}
							</Accordion.Content>
						) : null}
					</Accordion>
					{/* скрываемый блок для шаблона решения */}
					<Accordion fluid styled style={{ marginBottom: '1rem' }}>
						<Accordion.Title active={this.state.openedSolutionData}
						                 icon="dropdown"
						                 content="Шаблон решения"
						                 onClick={this.switchSolutionData} />
						{this.state.openedSolutionData ? (
							<Accordion.Content active key={this.formUptime}>
								<Button content="+ Поле выбора" onClick={this.addSolutionData1} />
								<Button content="+ Фото" onClick={this.addSolutionData2} />
								<Button content="+ Фото с условием"
								        onClick={this.addSolutionData3} />
								<Button content="+ Число"
								        onClick={this.addSolutionData4} />
								{this.state.formData.data.solutionData.map(this.renderSolutionDataValue)}
							</Accordion.Content>
						) : null}
					</Accordion>
					{/* скрываемый блок для шаблона результата */}
					<Accordion fluid styled style={{ marginBottom: '3rem' }}>
						<Accordion.Title active={this.state.openedOutputData}
						                 icon="dropdown"
						                 content="Шаблон результата"
						                 onClick={this.switchOutputData} />
						{this.state.openedOutputData ? (
							<Accordion.Content active key={this.formUptime}>
								<Button content="+ Поле очтета" onClick={this.addOutputData} />
								{this.state.formData.data.outputData.map(this.renderOutputDataValue)}
							</Accordion.Content>
						) : null}
					</Accordion>
					{/* кнопки */}
					<CancelSaveDeleteButtons
						canSave={!this.formErrors?.hasError && this.formChanges?.hasChanges}
						onCancel={this.onCancelClick}
						onSave={this.onSaveClick}
						onDelete={this.onDeleteClick}
					/>
				</Form>
			</>
		);
	}
}

export default AdminIncidentTypeEditPage;

// рендерит ошибку
function renderError(error: string | Error) {
	return <Segment inverted color="red" style={{ clear: 'both' }}>
		{error instanceof Error ? (
			<>
				<div><b>Ошибка обработки шаблона:</b></div>
				{String(error)}
			</>
		) : error}
	</Segment>;
}

const IncidentTypeValueFormatOptions = Object.keys(IncidentTypeValueFormat).map(key => ({
	value: key, text: key,
}))
