import React from 'react';
import PropTypes from 'prop-types';
import { ErrorPage } from './index';

class PageNotFound extends React.Component
{
	static propTypes = {
		children: PropTypes.node,
	}

	render() {
		return (
			<ErrorPage title="404: Страница не найдена">
				{this.props.children}
			</ErrorPage>
		);
	}
}


export default PageNotFound;
