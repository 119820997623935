import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
	Breadcrumb,
	Button,
	ButtonGroup,
	Form,
	Header,
	Icon,
	Input,
	Modal,
	Segment,
	TextArea
} from 'semantic-ui-react';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import * as QuizApi from '~api/quizApi';
import { Spinner } from '~components/smallComponents';
import AddresseeSelector from '~components/ui/AddresseeSelector';
import moment from 'moment';
import { CheckedSubordinatesValue } from '~components/ui/AddresseeSelector';
import * as ShopsApi from '~api/shopsApi';
import generateId from '~tools/generateId';
import arrayMove from 'array-move';
import { QuestionnaireQuestionTypeEnum, validateIsEmail } from 'common-lib';
import { fillSubordinateMap } from '../../../lib/subordinateTools';
import styled from 'styled-components';
import { UiPhotoGallery2 } from '~components';
import { TRANSPARENT_1PX } from '~components/ui/images';

type Option = {
	id: number,
	value: string,
	index?: number
};

type Question = {
	id: number,
	warning?: string;
	type: string,
	name: string,
	isHidden: boolean,
	isRequired: boolean,
	options?: Option[],
	photos: any[],
	hiddenQuestionId?: number,
	hiddenAnswerId?: number,
	index?: number
};

const Knob = styled.div`
  position: absolute;
  width: 22px;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: move;
  background: url(/assets/img/ui/drag.svg) no-repeat center;
`;

const WarnBubble = styled.div`
  color: darkorange;
  font-size: 0.9em;
`;

const PhotoSection = styled.div`
	width: 25%;
	display: flex;
  	flex-direction: column;
  	justify-content: flex-start;
`;

const ShopAndManagersStyle = styled.div`
	display: flex;
	align-items: center;
	margin-left: 5px;
	margin-top: 10px;
`;

export default function AdminQuizCreatePage(props) {

	function genCleanTreeInfo(): any {
		return {
			checkedShops: new Set(),
			checkedUsers: new Set(),
			checkedNotallUsers: new Set(),
			openedBranches: new Set(),
			branchSelectedCount: new Map(),
			checkedManagers: new Set(),
		};
	}
	const quizId = props.match.params.id;

	const history = useHistory();

	const [isLoading, setLoading] = useState<boolean>(false);
	const [step, setStep] = useState<number>(1);
	const [quizName, setQuizName] = useState<string>('');
	const [questions, setQuestions] = useState<Question[]>([]);
	const [hiddenSelectOptions, setHiddenSelectOptions] = useState<Option[]>([]);
	const [scheduleStartAtDate, setScheduleStartAtDate] = useState<string>('');
	const [scheduleStartAtTime, setScheduleStartAtTime] = useState<string>('06:00');
	const [scheduleFinishAtDate, setScheduleFinishAtDate] = useState<string>('');
	const [scheduleFinishAtTime, setScheduleFinishAtTime] = useState<string>('12:00');
	const [scheduleIsRepeatable, setScheduleIsRepeatable] = useState<boolean>(false);
	const [scheduleRepeatEveryDays, setScheduleRepeatEveryDays] = useState<number>(1);
	const [scheduleCountQuiz, setScheduleCountQuiz] = useState<number>(1);
	const [reportAddresses, setReportAddresses] = useState<string>('');
	const [isShowConfirmCancel, setIsShowConfirmCancel] = useState<boolean>(false);
	const [isSaving, setSaving] = useState<boolean>(false);
	const [recipientsFilter, setRecipientsFilter] = useState(0);
	const [originalSubordinates, setOriginalSubordinates] = useState<any>([]);

	const [subordinates, setSubordinates] = useState<any>({});
	const [treeInfo, setTreeInfo] = useState<any>(genCleanTreeInfo());

	const [validationsErrors, setValidationErrors] = useState<string[]>([]);

	useEffect(() => {
		if (isSaving) {
			save();
		}
	}, [isSaving]);

	const buttons = [
		{ title: 'Магазины'},
		{ title: 'Менеджеры'},
	];

	function loadSubordinateItems(): Promise<void> {
		return ShopsApi.loadSubordinateShops().then(res => {
			const subs = fillSubordinateMap(res);
			setOriginalSubordinates(subs);
			updateSubordinatesByFilter(subs, 0);
		});
	}

	function updateSubordinatesByFilter(subs: any, _recipientsFilter: number): void {
		if (_recipientsFilter === 0) {
			setSubordinates(subs);
			setTreeInfo(genCleanTreeInfo());
			return;
		} else {
			const res = filterSubs(subs) || {};

			setSubordinates(res);
			setTreeInfo(genCleanTreeInfo());
		}

		function filterSubs(user: any): any {
			user = { ...user };
			user.subordinateUsers = user.subordinateUsers?.map(filterSubs).filter(Boolean);
			user.subShopSet = new Set();
			user.subordinateUsers?.forEach(u =>  Array.from(u.subShopSet).forEach(n => user.subShopSet.add(n)));
			return user;
		}
	}

	function loadQuiz(id: number): void {
		setLoading(true);
		QuizApi.fetchQuiz(id).then(res => {
			fillQuiz(res.data);
			setLoading(false);
		});
	}

	function fillQuiz(data: any): void {
		setQuizName(data.title);

		const questions: Question[] = [];

		data.questionnaireConfig.questions.map((item, index) => {
			const question: Question = {
				id: generateId(),
				type: item.type,
				name: item.text,
				photos: item.photos,
				isHidden: !!item.visibleOptions,
				isRequired: item.isRequired,
				index,
			};

			if (['RADIO', 'CHECKERS'].includes(item.type)) {
				question.options = item.options.map((item, index) => {
					return {
						id: generateId(),
						value: item,
						index,
					};
				});
			}

			if (item.type) {
				question.photos = item.photos?.map((item, index) => {
					return {
						id: item,
						src: `/api/file/download/${item}?size=middle`,
						index,
					};
				});
			}

			questions.push(question);
		});

		data.questionnaireConfig.questions.map((question, index) => {
			if (question.visibleOptions) {
				const hiddenQuestion: Question | undefined = questions.find(item => item.index === index);
				const visibleQuestion: Question | undefined = questions.find(item => item.index === question.visibleOptions.questionIndex);

				if (!hiddenQuestion || !visibleQuestion) {
					return;
				}

				const visibleAnswer: Option | undefined = visibleQuestion.options?.find(item => item.index === question.visibleOptions.answerIndex);

				if (!visibleAnswer) {
					return;
				}

				hiddenQuestion.hiddenQuestionId = visibleQuestion.id;
				hiddenQuestion.hiddenAnswerId = visibleAnswer.id;
			}
		});

		setQuestions(questions);

		setScheduleStartAtDate(data.executionConfig.startAtDate);

		setScheduleFinishAtDate(data.executionConfig.finishAtDate);

		if (data.executionConfig.startAtTime) {
			setScheduleStartAtTime(data.executionConfig.startAtTime);
		}

		if (data.executionConfig.finishAtTime) {
			setScheduleFinishAtTime(data.executionConfig.finishAtTime);
		}

		if (data.executionConfig.repeatEveryDays) {
			setScheduleIsRepeatable(true);
			setScheduleRepeatEveryDays(data.executionConfig.repeatEveryDays);
		}

		if (data.executionConfig.repeatCount) {
			setScheduleCountQuiz(data.executionConfig.repeatCount);
		}

		setReportAddresses(data.reportConfig.addressees.join('\n'));

		const filledCheckedSubordinatesInfo = genCleanTreeInfo();
		if (data.addresseesShopIds?.length) {
			filledCheckedSubordinatesInfo.checkedShops = new Set(data.addresseesShopIds);
		} else {
			filledCheckedSubordinatesInfo.checkedManagers = new Set(data.addresseesManagerIds);
			setRecipientsFilter(1);
		}

		setTreeInfo(filledCheckedSubordinatesInfo);
	}

	function addQuestion(type: string): void {
		const question: Question = {
			id: generateId(),
			type,
			name: '',
			photos: [],
			isHidden: false,
			isRequired: true,
		};

		if (['RADIO', 'CHECKERS'].includes(type)) {
			question.options = [];
		}

		setQuestions([...questions, question]);
	}

	function deleteQuestion(id: number): void {
		setQuestions(questions.filter((item) => item.id !== id).map(question => {
			if (question.hiddenQuestionId === id) {
				delete question.hiddenQuestionId;
				delete question.hiddenAnswerId;
			}

			return question;
		}));
	}

	function handleQuestionNameChange(id: number, name: string): void {
		setQuestions(questions.map(item => {
			if (item.id === id) {
				item.name = name;
			}
			return item;
		}));
	}

	function addQuestionOption(id: number): void {
		setQuestions(questions.map(item => {
			if (item.id === id) {
				item.options?.push({
					id: generateId(),
					value: ''
				});
			}
			return item;
		}));
	}

	function handleQuestionOptionChange(questionId: number, optionId: number, value: string): void {
		setQuestions(questions.map(question => {
			if (question.id === questionId) {
				question.options?.map(option => {
					if (option.id === optionId) {
						option.value = value;
					}
				});
			}
			return question;
		}));
	}

	function handleQuestionIsHiddenChange(id: number): void {
		setQuestions(questions.map(question => {
			if (question.id === id) {
				question.isHidden = !question.isHidden;
			}
			return question;
		}));
	}

	function handleHiddenQuestionChange(id: number, value: string): void {
		setQuestions(questions.map(question => {
			if (question.id === id) {
				question.hiddenQuestionId = +value;
			}
			return question;
		}));
	}

	function handleHiddenAnswerChange(id: number, value: string): void {
		setQuestions(questions.map(question => {
			if (question.id === id) {
				question.hiddenAnswerId = +value;
			}
			return question;
		}));
	}

	function handleQuestionIsRequiredChange(id: number): void {
		setQuestions(questions.map(question => {
			if (question.id === id) {
				question.isRequired = !question.isRequired;
			}
			return question;
		}));
	}

	function deleteQuestionOption(questionId: number, optionId: number): void {
		setQuestions(questions.map(question => {
			if (question.id === questionId) {
				question.options = question.options?.filter(option => option.id !== optionId);
			}

			if (question.hiddenAnswerId === optionId) {
				delete question.hiddenAnswerId;
			}

			return question;
		}));
	}

	function handleScheduleStartAtDate(value: string): void{
		setScheduleStartAtDate(value);
	}

	function handleScheduleStartAtTime(value: string): void{
		setScheduleStartAtTime(value);
	}

	function handleScheduleFinishAtDate(value: string): void{
		setScheduleFinishAtDate(value);
	}

	function handleScheduleFinishAtTime(value: string): void{
		setScheduleFinishAtTime(value);
	}

	function handleScheduleRepeatEveryDays(value: string): void{
		setScheduleRepeatEveryDays(+value);
	}

	function handleScheduleCoutQuiz(value: string): void{
		setScheduleCountQuiz(+value);
	}

	function handleScheduleIsRepeatable(value: boolean): void{
		setScheduleIsRepeatable(value);
	}

	function handleReportAddresses(value: string): void{
		setReportAddresses(value);
	}

	function changeShopSelection(newTreeInfo: CheckedSubordinatesValue): void {
		setTreeInfo({...newTreeInfo});
	}

	function onSortEnd(oldIndex: number, newIndex: number): void {
		setQuestions((value) => arrayMove(value, oldIndex, newIndex));
	}

	function validate(): any {
		const errors: any = {
			visibleQuestion: 'Опрос должен иметь минимум 1 не скрытый вопрос'
		};

		if (!quizName) {
			errors.title = 'Введите название опроса';
		}

		questions.forEach(question => {
			delete question.warning;

			if (!question.name) {
				question.warning = 'Заполните текст вопроса';
				errors.name = 'Заполните все данные в вопросах';
			}

			if (['RADIO', 'CHECKERS'].includes(question.type)) {
				if (question.type === 'RADIO') {
					if (!question.options?.length || question.options.length < 2) {
						question.warning = 'Добавьте варианты ответов';
						errors.hasRadioOptions = 'Добавьте хотя бы 2 ответа для каждого вопроса с одиночным выбором';
					}
				}

				if (question.type === 'CHECKERS') {
					if (!question.options?.length || question.options.length < 1) {
						question.warning = 'Добавьте варианты ответов';
						errors.hasCheckersOptions = 'Добавьте хотя бы 1 ответ для каждого вопроса с множественным выбором';
					}
				}

				question.options?.forEach(option => {
					if (!option.value) {
						question.warning = 'Заполните варианты ответов';
						errors.name = 'Заполните все данные в вопросах';
					}
				});
			}

			if (!question.isHidden) {
				delete errors.visibleQuestion;
			}
			else {
				if (!question.hiddenQuestionId || !question.hiddenAnswerId) {
					question.warning = 'Заполните привязку к вопросу';
					errors.hiddenBinding = 'Для скрытых вопросов обязательно должен быть заполнен вопрос и ответ (привязка).';
				}
			}
		});

		if (recipientsFilter === 0) {
			if (!treeInfo.checkedShops.size) {
				errors.recipients = 'Выберите получателей';
			}
		} else {
			if (!treeInfo.checkedUsers.size) {
				errors.recipients = 'Выберите получателей';
			}
		}


		if (
			!scheduleStartAtDate ||
			!scheduleFinishAtDate ||
			!scheduleStartAtTime ||
			!scheduleFinishAtTime ||
			moment(`${scheduleStartAtDate} ${scheduleStartAtTime}`).isSameOrAfter(
				moment(`${scheduleFinishAtDate} ${scheduleFinishAtTime}`)
			)
		) {
			errors.time = 'Измените время начала опроса';
		}
		if (scheduleIsRepeatable) {
			if (scheduleRepeatEveryDays < 1) {
				errors.repeatEveryDays = 'Некорректное значение для повтора опроса';
			}
		}
		if (
			!scheduleStartAtDate ||
			!scheduleStartAtTime ||
			moment(`${scheduleStartAtDate} ${scheduleStartAtTime}`).isSameOrBefore(new Date())
		) {
			errors.time = 'Измените время начала опроса';
		} else if (
			!scheduleFinishAtDate ||
			!scheduleFinishAtTime ||
			moment(`${scheduleFinishAtDate} ${scheduleFinishAtTime}`).isSameOrBefore(
				moment(`${scheduleStartAtDate} ${scheduleStartAtTime}`)
			)
		) {
			errors.time = 'Измените время окончания опроса';
		}

		if (recipientsFilter === 0) {
			if(scheduleCountQuiz < 1 || scheduleCountQuiz > 1000) {
				errors.number = 'Количество прохождений опроса может быть от 1 до 1000';
			}
		}

		const diff = moment(`${scheduleFinishAtDate}`).diff(moment(`${scheduleStartAtDate}`));
		const days = diff / 86400_000;

		if(days >= 7) {
			errors.time = 'Опрос можно запускать максимум на 7 суток';
		}

		if (reportAddresses) {
			const emails = reportAddresses.trim().split('\n');

			for (let i in emails) {
				const error = validateIsEmail()(emails[i]);

				if (error) {
					errors.reportAddresses = 'Введите валидные адреса для получателей отчетов';
					break;
				}
			}
		}

		return errors;
	}

	function validationErrors(): ReactElement | null {
		if (!validationsErrors.length) {
			return null;
		}

		return (
			<>
				<ul className="validation-errors">
					{validationsErrors.map(error => {
						return (
							<li key={error}>{error}</li>
						);
					})}
				</ul>
			</>
		);
	}

	function save(): void {
		const errors = validate();

		if (Object.keys(errors).length) {
			setValidationErrors(Object.values(errors));
			setSaving(false);
			return;
		}

		const payload: any = {
			title: quizName,
			questionnaireConfig: {
				questions: questions.map(question => {
					const allPhotos = question.photos.map(p => {
						const result: any[] = [];
						if (p.isNew) {
							result.push(p);
						} else if (!p.isNew) {
							result.push(p.id);
						}
						return result;
					}).flat(1);
					const item: any = {
						text: question.name,
						type: question.type,
						photos: allPhotos,
						isRequired: question.isRequired
					};

					if (['RADIO', 'CHECKERS'].includes(question.type)) {
						item.options = question.options?.map(option => option.value);
					}

					if (question.isHidden) {
						item.visibleOptions = {
							questionIndex: questions.findIndex(item => item.id === question.hiddenQuestionId),
							answerIndex: questions.find(item => item.id === question.hiddenQuestionId)?.options?.findIndex(item => item.id === question.hiddenAnswerId)
						};
					}

					return item;
				})
			},
			...(recipientsFilter === 0 && { addresseesShopIds: Array.from(treeInfo.checkedShops) }),
			...(recipientsFilter === 1 && { addresseesManagerIds: Array.from(treeInfo.checkedUsers) }),
			reportConfig: {
				messageTitle: 'Отчет по опросу',
				addressees: reportAddresses.split('\n').map(s => s.trim()).filter(Boolean),
			},
			executionConfig: {
				startAtDate: scheduleStartAtDate,
				startAtTime: scheduleStartAtTime,
				finishAtDate: scheduleFinishAtDate,
				finishAtTime: scheduleFinishAtTime,
				repeatCount: scheduleCountQuiz
			}
		};

		if (scheduleIsRepeatable) {
			payload.executionConfig.repeatEveryDays = scheduleRepeatEveryDays;
		}

		QuizApi.createQuiz(payload).then(() => {
			if (quizId) {
				QuizApi.deleteQuiz(quizId).then(() => {
					history.push('/admin/quiz');
				});
			}
			else {
				history.push('/admin/quiz');
			}
		});
	}

	function cancel(): void {
		setIsShowConfirmCancel(false);
		history.push('/admin/quiz');
	}

	useEffect(() => {
		loadSubordinateItems()
			.then(() => {
				if (quizId) {
					loadQuiz(quizId);
				}
			});
	}, []);

	useEffect(() => {
		const options: Option[] = [];

		questions.map(question => {
			if (['RADIO', 'CHECKERS'].includes(question.type)) {
				options.push({
					id: question.id,
					value: question.name
				});
			}
		});

		setHiddenSelectOptions(options);
	}, [questions]);

	if (isLoading) return <Spinner />;


	const PHOTO_LIMIT_UPLOAD = 3;

	// добавление фото
	function onAddPhoto(newPhoto, id) {
		setQuestions(questions.map((q, index) => {
			if (q.id === id) {
				q.photos.push({
					id: generateId(),
					src: newPhoto,
					index,
					isNew: true
				});
			}
			return q;
		}));
	}

	// удаление фото
	function onRemovePhoto(index, id) {
		setQuestions(questions.map(q => {
			if (q.id === id) {
				q.photos.splice(index, 1);
			}
			return q;
		}));
	}

	function filterChange (newRecipientsFilter) {
		updateSubordinatesByFilter(originalSubordinates, newRecipientsFilter);
		setRecipientsFilter(newRecipientsFilter);
	}

	return (
		<>
			<Link style={{ display: 'inline-block', marginBottom: '0.3rem' }} to="/admin/quiz">
				<Icon name="arrow left" />
				Вернуться в Опросы
			</Link>
			<div style={{ marginBottom: '1rem' }}>
				<Header as="h3" icon="edit outline" content={`${quizId ? 'Редактирование' : 'Создание'} опроса${quizId ? ` #${quizId}` : ''}`} />
			</div>
			<Breadcrumb>
				<Breadcrumb.Section active={step === 1} onClick={step !== 1 ? () => {setStep(1);} : undefined}>Список вопросов</Breadcrumb.Section>
				<Breadcrumb.Divider content={<span>&nbsp;|&nbsp;</span>} />
				<Breadcrumb.Section active={step === 2} onClick={step !== 2 ? () => {setStep(2);} : undefined}>Получатели</Breadcrumb.Section>
				<Breadcrumb.Divider content={<span>&nbsp;|&nbsp;</span>} />
				<Breadcrumb.Section active={step === 3} onClick={step !== 3 ? () => {setStep(3);} : undefined}>Настройки</Breadcrumb.Section>
			</Breadcrumb>
			<br/>

			{/* Step 1 */}
			<Segment hidden={step !== 1}>
				<Header as="h4" content="Название опроса" />
				<Input value={quizName} fluid
					onChange={event => {setQuizName(event.target.value);}} />
				<br/>

				<SortableList
					onSortEnd={onSortEnd}
					className="questions"
					lockAxis="y"
				>
					{questions.map(question => {
						const isLimitPhoto = question.photos?.length < PHOTO_LIMIT_UPLOAD;

						const photoSrsc = question.photos;
						return (
							<SortableItem key={question.id}>
								<div className={`question-item ${question.warning ? '__warning' : ''}`}>
									<div className="question-item__content">
										<p>
											{question.type === QuestionnaireQuestionTypeEnum.FLOAT && 'Числовой вопрос'}
											{question.type === QuestionnaireQuestionTypeEnum.STRING && 'Текстовый вопрос'}
											{question.type === QuestionnaireQuestionTypeEnum.RADIO && 'Одиночный выбор'}
											{question.type === QuestionnaireQuestionTypeEnum.CHECKERS && 'Множественный выбор'}
											{question.type === QuestionnaireQuestionTypeEnum.PHOTO && 'Фото'}
										</p>

										{question.warning ? (
											<WarnBubble>{question.warning}</WarnBubble>
										) : null}

										<div>
											<Input fluid placeholder="Введите вопрос"
												   value={question.name}
												   onChange={event => {handleQuestionNameChange(question.id, event.target.value);}}
												   style={{ marginBottom: '0.5rem' }}
											/>
										</div>

										{question.type === QuestionnaireQuestionTypeEnum.FLOAT && <Input type="text" placeholder="100.00" readOnly />}
										{question.type === QuestionnaireQuestionTypeEnum.STRING && <Input type="text" placeholder="Тут будет ответ" readOnly />}
										{question.type === QuestionnaireQuestionTypeEnum.PHOTO &&  (
											<>
												<p style={{ color: '#BFBFBF' }}>Тут будут фото</p>
												{[...new Array(3)].map((_, index) => (
													<img key={index} src={TRANSPARENT_1PX} style={{
														backgroundImage: 'url(/assets/img/user-photo/load-error.svg)',
														width: '60px',
														height: '60px',
														border: '1px solid #BFBFBF',
														borderRadius: '16px',
														backgroundPosition: 'center',
														marginRight: '1vw',
														backgroundRepeat: 'no-repeat',
														backgroundSize: 'cover' }} alt={String(index)} />))}
											</>
										)}
										{['RADIO', 'CHECKERS'].includes(question.type) &&
                                            <>
												{question.options?.map(option => {
													return (
														<div key={option.id}>
															<Input
																placeholder="Вариант ответа"
																value={option.value}
																onChange={event => handleQuestionOptionChange(question.id, option.id, event.target.value)}
																style={{ marginBottom: '0.5rem' }}
															/>
															<Icon name="remove circle"
																  onClick={() => {deleteQuestionOption(question.id, option.id);}}
																  style={{ cursor: 'pointer', marginLeft: '0.3rem' }} />
														</div>
													);
												})}
                                                <div onClick={() => {addQuestionOption(question.id);}} style={{ display: 'inline-block', cursor: 'pointer' }}>
                                                    <Icon name="plus circle" />
                                                    Добавить
                                                </div>
                                            </>
										}
									</div>

									<PhotoSection>
										<UiPhotoGallery2 photos={photoSrsc}
														 onAddPhoto={isLimitPhoto ? (newPhoto) => { onAddPhoto(newPhoto, question.id); } : undefined}
														 readOnly={!isLimitPhoto}
														 isLimit={!isLimitPhoto}
														 onRemovePhoto={(index) => onRemovePhoto(index, question.id)} />
									</PhotoSection>
									<div className="question-item__actions" style={{ width: '25%' }}>
										<label>
											<Input
												className="question-item__checkbox"
												type='checkbox'
												onChange={() => handleQuestionIsRequiredChange(question.id)}
												checked={question.isRequired}
											/>
											Обязательное
										</label>

										<label>
											<Input
												className="question-item__checkbox"
												type='checkbox'
												onChange={() => handleQuestionIsHiddenChange(question.id)}
												checked={question.isHidden}
											/>
											Скрытый
										</label>

										<select
											className="question-item__hidden-question"
											hidden={!question.isHidden}
											value={question.hiddenQuestionId}
											onChange={event => handleHiddenQuestionChange(question.id, event.target.value)}
										>
											<option value="">Выберите вопрос...</option>
											{hiddenSelectOptions.map(option => {
												if (option.id !== question.id && option.value) {
													return (
														<option key={option.id} value={option.id}>
															{option.value}
														</option>
													);
												}
											})}
										</select>

										<select
											className="question-item__hidden-answer"
											hidden={!question.isHidden}
											disabled={!question.hiddenQuestionId}
											value={question.hiddenAnswerId}
											onChange={event => handleHiddenAnswerChange(question.id, event.target.value)}
										>
											<option value="">Выберите вариант ответа...</option>
											{questions.find(item => item.id === question.hiddenQuestionId)?.options?.map(option => {
												return (
													<option key={option.id} value={option.id}>
														{option.value}
													</option>
												);
											})}
										</select>
									</div>

									<Icon name="remove circle"
									      className="question-item__remove"
									      onClick={() => { deleteQuestion(question.id); }} />

									<SortableKnob>
										<Knob />
									</SortableKnob>

								</div>
							</SortableItem>
						);
					})}
				</SortableList>

				<div className="question-actions">
					<Button onClick={() => { addQuestion(QuestionnaireQuestionTypeEnum.FLOAT); }}>Вопрос "Число"</Button>
					<Button onClick={() => { addQuestion(QuestionnaireQuestionTypeEnum.RADIO); }}>Вопрос "Одиночный выбор"</Button>
					<Button onClick={() => { addQuestion(QuestionnaireQuestionTypeEnum.CHECKERS); }}>Вопрос "Множественный выбор"</Button>
					<Button onClick={() => { addQuestion(QuestionnaireQuestionTypeEnum.STRING); }}>Вопрос "Текст"</Button>
					<Button onClick={() => { addQuestion(QuestionnaireQuestionTypeEnum.PHOTO); }}>Вопрос "Фото"</Button>
				</div>
			</Segment>

			{/* Step 2 */}
			<div hidden={step !== 2}>
				<ShopAndManagersStyle>
					<ButtonGroup size="tiny">
						{buttons.map((b, i) => (
							<Button key={i} active={i === recipientsFilter} color={i === recipientsFilter ? 'grey' : undefined} onClick={() => filterChange(i)} content={b.title}/>
						))}
					</ButtonGroup>
				</ShopAndManagersStyle>
				<p style={{ margin: '0.5rem 0 0 0.5rem'}}>Опрос будет назначен для всех выбранных {recipientsFilter === 0 ? 'магазинов' : 'менеджеров'}</p>
				<AddresseeSelector
					style={{ marginTop: '1rem' }}
					filter={recipientsFilter === 1 ? recipientsFilter : undefined}
					subordinates={subordinates}
					treeInfo={treeInfo}
					onChange={changeShopSelection}
				/>
			</div>

			{/* Step 3 */}
			<Segment hidden={step !== 3}>
				<Header as="h4" content="Расписание опроса" />

				<div className="schedule__container">
					<div className="schedule__start">
						<div className="schedule__title">
							Начало опроса
						</div>
						<Input
							className="schedule__date"
							type="date"
							value={scheduleStartAtDate}
							onChange={event => handleScheduleStartAtDate(event.target.value)}
						/>
							<Input
								className="schedule__start-time"
								type="time"
								value={scheduleStartAtTime}
								onChange={event => handleScheduleStartAtTime(event.target.value)}
							/>
					</div>

					<div className="schedule__finish">
						<div className="schedule__title">
							Окончание опроса
						</div>
						<Input
							className="schedule__date"
							type="date"
							value={scheduleFinishAtDate}
							onChange={event => handleScheduleFinishAtDate(event.target.value)}
						/>
								<Input
								className="schedule__finish-time"
								type="time"
								value={scheduleFinishAtTime}
								onChange={event => handleScheduleFinishAtTime(event.target.value)}
							/>
					</div>
				</div>



				<div className="schedule__repeatable">
					<label>
						<Input
							className="schedule__radio"
							type="radio"
							name="schedule"
							checked={!scheduleIsRepeatable}
							onChange={() => handleScheduleIsRepeatable(false)}
						/>
						Запустить единоразово
					</label>
				</div>

				<div className="schedule__repeatable">
					<label>
						<Input
							className="schedule__radio"
							type="radio"
							name="schedule"
							checked={scheduleIsRepeatable}
							onChange={() => handleScheduleIsRepeatable(true)}
						/>
						Повторять через каждые
						<Input
							className="schedule__repeat-days"
							type="number"
							min="1"
							step="1"
							value={scheduleRepeatEveryDays || ''}
							disabled={!scheduleIsRepeatable}
							onChange={event => handleScheduleRepeatEveryDays(event.target.value)}
						/>
						дней
					</label>
				</div>

				{recipientsFilter === 0 ? (
					<div className="schedule__counter">
						<label>
							Количество прохождений опроса
							<Input
								className="schedule__counter-count"
								type="number"
								min="1"
								max="1000"
								step="1"
								value={scheduleCountQuiz || ''}
								onChange={event => handleScheduleCoutQuiz(event.target.value)}
							/>
						</label>
					</div>
				) : null}

				<div className="report">
					<Header as="h4" content="Получатели отчета" />
					<Form>
						<TextArea value={reportAddresses}
						          style={{ width: '300px', height: '100px' }}
					              onChange={event => handleReportAddresses(event.target.value)} />
					</Form>
				</div>

			</Segment>

			{validationErrors()}

			<div className="actions">
				<Button
					icon="arrow left"
					labelPosition="left"
					disabled={isSaving}
					content="Отмена"
					onClick={() => setIsShowConfirmCancel(true)}
				/>
				<Button
					positive
					icon="save"
					disabled={isSaving}
					labelPosition="left"
					content="Сохранить"
					onClick={() => setSaving(true)}
				/>
			</div>

			{isSaving ? (
				<Spinner text='Сохранение...' />
			) : null}

			{isShowConfirmCancel ? (
				<Modal open>
					<Modal.Content>
						<p>
							Данные не будут сохранены.
						</p>
						<p>
							Вы действительно хотите выйти?
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button positive onClick={() => setIsShowConfirmCancel(false)} content="Нет" />
						<Button onClick={cancel} content="Да" />
					</Modal.Actions>
				</Modal>
			) : null}
		</>
	);
}
