import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TaskStatusEnum, TodoItemStatusEnum, TodoItemStatusTitles, PageInfo } from 'common-lib';
import { get, set } from '~tools/localConfig';
import { nl2br } from '~tools/html';
import TodosFetcher from '~api/TodosFetcher';
import { ListIsEmpty, Spinner, UiBadge } from '~components';
import { fillTodoListFromJson } from '../todosAttrs';
import { UserContentWrapper } from '../../../containers';

// ключ для хранения фильтров списка
const UTodosLP_PAGE_INFO = 'UTodosLP.pageInfo';
// значения "Все" для фильтра
const FILTER_ALL = 'all';

const fetcher = new TodosFetcher();

type ComponentStore = {
	checkQueryString?: string
}

type TodoItemsState = {
	items: any[]
	pageInfo: any
	isItemsRequesting: boolean
}

export default function UserTodoListPage() {
	const [store] = useState<ComponentStore>({
		checkQueryString: get(UTodosLP_PAGE_INFO, ''),
	});
	const [{
		items,
		pageInfo,
		isItemsRequesting,
	}, setItems] = useState<Partial<TodoItemsState>>({});
	const history = useHistory();
	const location = useLocation();

	const curPageInfo = PageInfo.parseFromString(location.search || store.checkQueryString || '');
	curPageInfo.pageIndex = 0;
	curPageInfo.pageSize = 200;
	curPageInfo.orderColumn = 'createdAt';
	curPageInfo.orderDirection = 'desc';

	if (items === undefined || curPageInfo.toQueryString() !== store.checkQueryString) {
		if (!isItemsRequesting) {
			loadItems();
		}
	}

	const filters = getFilters();
	const isFilterSelected = filters.some(i => i.clearable);

	return (
		<UserContentWrapper title="Список дел" filterOptions={filters}>
			{isItemsRequesting ? <Spinner onpage />
				: !items?.length ? <ListIsEmpty filterSelectedInfo={isFilterSelected} />
					: items.map(renderCard)}
		</UserContentWrapper>
	);

	function loadItems() {
		store.checkQueryString = curPageInfo.toQueryString();
		set(UTodosLP_PAGE_INFO, store.checkQueryString);
		fetcher.getList(curPageInfo).then(json => {
			setItems({
				items: fillTodoListFromJson(json),
				pageInfo: json.pageInfo,
			});
		});
		setItems({ items, pageInfo, isItemsRequesting: true });
	}

	function getFilters() {
		const selectedValues = curPageInfo.customParams?.statuses || FILTER_ALL;
		return [{
			title: 'СТАТУС',
			options: [
				{ text: 'Все', value: FILTER_ALL },
				...Object.keys(TodoItemStatusEnum).map(key => ({
					text: TodoItemStatusTitles[key],
					value: TodoItemStatusTitles[key],
				})),
			],
			selected: selectedValues,
			multiselect: true,
			clearable: selectedValues !== FILTER_ALL,
			onChange: value => value !== selectedValues ? onFilterChange(value) : undefined,
		}];
	}

	function onFilterChange(value: string) {
		if (!value) value = FILTER_ALL;
		// в фильтре используется мультиселект, поэтому проверяем на all в конце
		const values: string[] = value ? value.split(',') : [];
		const k = values.indexOf(FILTER_ALL);
		if (k === values.length - 1) {
			value = FILTER_ALL;
		} else if (k >= 0) {
			values.splice(k, 1);
			value = values.join(',');
		}
		curPageInfo.update({ statuses: value === FILTER_ALL ? undefined : value });
		history.push(`${location.pathname}?${curPageInfo.toQueryString()}`);
	}
}

function renderCard(item) {
	const { id, externalId, title, description, status, statusText } = item;
	const { NEW, IN_PROGRESS, DONE, STOPPED, CANCELED, NOT_DONE } = TodoItemStatusEnum;
	// маппинг статуса туду на статус задачи, чтобы отрисовать его в бейджике
	const statusName = [NEW, IN_PROGRESS].includes(status) ? TaskStatusEnum.IN_PROGRESS
		: [DONE].includes(status) ? TaskStatusEnum.DONE
			: [STOPPED, CANCELED].includes(status) ? TaskStatusEnum.REJECTED
				: [NOT_DONE].includes(status) ? TaskStatusEnum.REJECTED
					: undefined;
	return (
		<UiBadge key={id} bigPaddings>
			<div style={{ float: 'right', color: 'gray' }}>{`№${externalId}`}</div>
			<h1>{title}</h1>
			{description ? (
				<div className="description">{nl2br(description)}</div>
			) : null}
			<UiBadge.TextWithStatus text={''} status={statusName} statusText={statusText} />
		</UiBadge>
	);
}
