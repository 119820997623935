import React from 'react';
import styled from 'styled-components';
import UiBadge from './UiBadge';

const BadgeStyle = styled.div`
	padding: 8px;
	
	.title {
		font-size: 19px;
		margin: 0 0 16px 0;
	}
	
	.title2 {
		font-size: 16px;
		margin: 0 0 16px 0;
	}
`;

const KeyValueStyle = styled.div`
	&.dev-only::before {
		font-style: italic;
		content: '(dev) ';
	}

	.key {
		display: inline-block;
		min-width: 66px;
		margin-right: 10px;
		color: #9696A7;
		font-size: 13px;
		line-height: 16px;
	}
	
	.value {
		display: inline-block;
		font-size: 13px;
		line-height: 16px;
	}	
`;

type Props = {
	title: string         // заголовок
	subTitle?: string     // подзаголовок
	color?: StatusColor   // цвет статуса
	values?: Array<Value> //
	linkTo?: string       // ссылка для перехода
	onClick?: Function    // обработчик клика
}

type Value = {
	key: string
	value: string
	devOnly?: boolean
}

export enum StatusColor {
	green = 'green',
	orange = 'orange',
	blue = 'blue',
}

const COLOR_VALUE = {
	green: '#21BA45',
	orange: '#FF9900',
	blue: '#1bb5f8',
}

export default function UiGoodInfoBadge(props: Props) {
	return (
		<UiBadge color={props.color ? COLOR_VALUE[props.color] : undefined} linkTo={props.linkTo}
		         onClick={props.onClick}>
			<BadgeStyle>
				<div className="title">{props.title}</div>
				{props.subTitle ? <div className="title2">{props.subTitle}</div> : null}
				{props.values?.map((kv, index) => (
					<KeyValueStyle key={`${index}_${kv.key}`}
					               className={kv.devOnly ? 'dev-only' : undefined}>
						<div className="key">{kv.key}</div>
						<div className="value">{kv.value}</div>
					</KeyValueStyle>
				))}
			</BadgeStyle>
		</UiBadge>
	);
}
