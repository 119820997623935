import { PageInfo } from 'common-lib';
import { handleFetchError } from '~tools/fetchBackend';

export default class BaseFetcher
{
	/**
	 * Базовый путь к endpoint.
	 * ex: `/api/{basePath}`
	 */
	protected basePath: string;

	constructor(basePath: string) {
		this.basePath = basePath;
	}

	createAbortController(): AbortController {
		return new AbortController();
	}

	/*
	 * Базовые методы выполненяи запросов.
	 */

	/**
	 * Базовый метод обращения к API.
	 * @param method
	 * @param url
	 * @param body
	 * @param options
	 * @param aborter
	 * @protected
	 */
	protected async fetchBackend(method: string, url: string, body: any = undefined, options: any = undefined, aborter?: AbortController) {
		if (!options) {
			options = {};
		}
		// если указан AbortController, добавляем его signal в options
		if (aborter) {
			options.signal = aborter.signal;
		}
		const { headers: inputHeaders, ...inputOptions } = options || {};
		url = `/api/${url}`;
		method = method.toUpperCase();

		const headers = {
			Accept: 'application/json',
		};

		const isContentTypeJson = (body && !(body instanceof FormData));
		if (isContentTypeJson) {
			headers['Content-Type'] = 'application/json';
		}
		if (inputHeaders) {
			Object.assign(headers, inputHeaders);
		}

		const response = await fetch(url, {
			...inputOptions,
			method,
			headers,
			body: isContentTypeJson ? JSON.stringify(body) : body,
		});

		return await handleFetchError(response, method, url) || response;
	}

	/**
	 * Выполняет GET запрос и вытаскивает blob из ответа.
	 * @param url
	 * @param options
	 * @param aborter
	 */
	async fetchGetThenReadBody(url: string, options: any = undefined, aborter?: AbortController) {
		return this.fetchBackend('get', url, undefined, options, aborter)
			.then((response: any) => response.blob())
			.then(blob => blob.text());
	}

	/**
	 * Выполняет GET запрос и вытаскивает json из ответа.
	 * @param url
	 * @param options
	 * @param aborter
	 */
	async fetchGetThenJson(url: string, options: any = undefined, aborter?: AbortController) {
		return this.fetchBackend('get', url, undefined, options, aborter)
			.then((response: any) => response.json && response.json());
	}

	/**
	 * Выполняет GET запрос и вытаскивает json из ответа, затем поле data.
	 * @param url
	 * @param options
	 * @param aborter
	 */
	async fetchGetThenDataFromJson(url: string, options: any = undefined, aborter?: AbortController) {
		return this.fetchGetThenJson(url, options, aborter)
			.then(({ data }) => data);
	}

	/**
	 * Выполняет POST запрос.
	 * @param url
	 * @param body
	 * @param options
	 * @param aborter
	 */
	async fetchPost(url: string, body: any = undefined, options: any = undefined, aborter?: AbortController) {
		return this.fetchBackend('post', url, body, options, aborter);
	}

	/**
	 * Выполняет POST запрос и вытаскивает json из ответа.
	 * @param url
	 * @param body
	 * @param options
	 * @param aborter
	 */
	async fetchPostThenJson(url: string, body: any = undefined, options: any = undefined, aborter?: AbortController) {
		return this.fetchBackend('post', url, body, options, aborter)
			.then((response: any) => response.json && response.json());
	}

	/**
	 * Выполняет PUT запрос.
	 * @param url
	 * @param body
	 * @param options
	 * @param aborter
	 */
	async fetchPut(url: string, body: any = undefined, options: any = undefined, aborter?: AbortController) {
		return this.fetchBackend('put', url, body, options, aborter);
	}

	/**
	 * Выполняет PUT запрос и вытаскивает json из ответа.
	 * @param url
	 * @param body
	 * @param options
	 * @param aborter
	 */
	async fetchPutThenJson(url: string, body: any = undefined, options: any = undefined, aborter?: AbortController) {
		return this.fetchBackend('put', url, body, options, aborter)
			.then((response: any) => response.json && response.json());
	}

	/**
	 * Выполняет DELETE запрос.
	 * @param url
	 * @param options
	 * @param aborter
	 */
	async fetchDelete(url: string, options: any = undefined, aborter?: AbortController) {
		return this.fetchBackend('delete', url, options, aborter);
	}

	/*
	 * Расширенные методы выполнения запросов, основанные на basePath.
	 */

	/**
	 * Запрос одной записи.
	 * @param id
	 * @param pageInfo
	 * @param aborter
	 */
	async getOne(id: number, pageInfo: PageInfo | undefined = undefined, aborter?: AbortController) {
		if (!id) {
			throw new Error(`Параметр id не может быть пустым при вызове ${this.constructor.name}.getOne`);
		}
		const query = pageInfo?.toQueryString();
		return this.fetchGetThenJson(`${this.basePath}/one/${id}${query ? `?${query}` : ''}`, aborter);
	}

	/**
	 * Запрос списка записей.
	 * @param pageInfo
	 * @param aborter
	 */
	async getList(pageInfo?: PageInfo, aborter?: AbortController) {
		const query = pageInfo?.toQueryString();
		return this.fetchGetThenJson(`${this.basePath}/list${query ? `?${query}` : ''}`, aborter);
	}

	/**
	 * Запрос на создание одной записи.
	 * @param itemData
	 * @param aborter
	 */
	async createOne(itemData: any, aborter?: AbortController) {
		if (!itemData) {
			throw new Error(`Параметр itemData не может быть пустым при вызове ${this.constructor.name}.createOne`);
		}
		return this.fetchPostThenJson(`${this.basePath}/one`, itemData, aborter);
	}

	/**
	 * Запрос на создание списка записей.
	 * @param itemsData
	 * @param date
	 * @param aborter
	 */
	async createList(itemsData: any, date: string, aborter?: AbortController) {
		if (!itemsData?.length) {
			throw new Error(`Параметр itemsData не может быть пустым при вызове ${this.constructor.name}.createList`);
		}
		if (!date) {
			throw new Error(`Параметр date не может быть пустым при вызове ${this.constructor.name}.createList`);
		}
		return this.fetchPostThenJson(`${this.basePath}/list?date=${date}`, itemsData, aborter);
	}

	/**
	 * Запрос на обновление одной записи.
	 * @param id
	 * @param itemData
	 * @param aborter
	 */
	async updateOne(id: number, itemData: any, aborter?: AbortController) {
		if (!id) {
			throw new Error(`Параметр id не может быть пустым при вызове ${this.constructor.name}.updateOne`);
		}
		if (!itemData) {
			throw new Error(`Параметр itemData не может быть пустым при вызове ${this.constructor.name}.updateOne`);
		}
		return this.fetchPutThenJson(`${this.basePath}/one?id=${id}`, itemData, aborter);
	}

	/**
	 * Запрос на удаление одной записи.
	 * @param id
	 * @param aborter
	 */
	async deleteOne(id: number, aborter?: AbortController) {
		if (!id) {
			throw new Error(`Параметр id не может быть пустым при вызове ${this.constructor.name}.deleteOne`);
		}
		return this.fetchDelete(`${this.basePath}/one?id=${id}`, aborter);
	}
}
