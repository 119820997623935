import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IncidentStateEnum, IncidentStateTitle, PageInfo, getRoleName, isDev, isTestServer } from 'common-lib';
import IncidentsFetcher from '~api/IncidentsFetcher';
import { loadSubordinateShops } from '~api/shopsApi';
import { ListIsEmpty, Spinner } from '~components';
import { get, set } from '~tools/localConfig';
import { Subordinates, SubordinateShop, SubordinateUser } from '~components/ui/AddresseeSelector';
import { UiContainerContentBreadCrumbs } from '~components/ui/UiContainer';
import { UserContentWrapper } from '~containers';
import { SearchWizardContentItem } from '~components/ui/SearchAndFiltersWizard';
import IncidentTypeView from './IncidentTypeView';
import IncidentView from './IncidentView';
import ManagerIncidentListView from './ManagerIncidentListView';
import ShopIncidentTypeListView from './ShopIncidentTypeListView';
import { PageNotFound } from '../../index';
import { IncidentTypeData, IncidentTypeRenderData, IncidentTypeStat, ManagerStatisticsData,
	UserTree, UserTreeShop } from '../incidentsAttrs';
import { fillSubordinateMap } from '../../../lib/subordinateTools';

// ключ для хранения фильтров списка
const UIncidentsSP_PAGE_INFO = 'UIncidentsSP.pageInfo';
// значения "Все" для фильтра
const FILTER_ALL = 'all';

const fetcher = new IncidentsFetcher();

type NumberOrNo = number | undefined;

function getIdFromHash(hash?: string): NumberOrNo {
	try {
		return Number(atob(hash || '')) || undefined;
	} catch (_) {
		return undefined;
	}
}

type IncidentTypeStatJson = {
	incidentTypeId: number,
	incidentTypeName: string,
	completed: number,
	allCount: number,
};

type ComponentStore = {
	checkManagerQueryString?: string,
	checkShopQueryString?: string,
	checkIncidentTypeQueryString?: string,
	isSubordinatesRequesting: boolean,
	isManagerDataRequesting: boolean,
	isShopDataRequesting: boolean,
	isIncidentTypeDataRequesting: boolean,
	isIncidentTypeDataQuietRequesting: boolean,
	isIncidentDataRequesting: boolean,
	searchString: string,
};

type Params = {
	managerHashId: string,
	shopHashId: string,
	incidentTypeHashId: string,
	incidentHashId: string,
};

export default function UserIncidentSectionPage() {
	const [store] = useState<ComponentStore>({
		checkManagerQueryString: get(UIncidentsSP_PAGE_INFO, ''),
		checkShopQueryString: get(UIncidentsSP_PAGE_INFO, ''),
		checkIncidentTypeQueryString: get(UIncidentsSP_PAGE_INFO, ''),
		isSubordinatesRequesting: false,
		isManagerDataRequesting: false,
		isShopDataRequesting: false,
		isIncidentTypeDataRequesting: false,
		isIncidentTypeDataQuietRequesting: false,
		isIncidentDataRequesting: false,
		searchString: '',
	});
	const [subordinatesData, setSubordinatesData] = useState<Subordinates | null>();
	const [managerData, setManagerData] = useState<ManagerStatisticsData>();
	const [shopData, setShopData] = useState<IncidentTypeData>();
	const [savedShopId, setSavedShopId] = useState<number>();
	const [incidentTypeData, setIncidentTypeData] = useState<IncidentTypeRenderData>();
	const [savedIncidentTypePath, setSavedIncidentTypePath] = useState<string>();
	const [searchedContent, setSearchedContent] = useState<SearchWizardContentItem[] | null>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const history = useHistory();
	const { managerHashId, shopHashId, incidentTypeHashId, incidentHashId } = useParams<Params>();
	const { currentUser: { shops }, isShopUser } = useSelector((state: any) => {
		return state.me;
	});

	// сначала загружаем список подчиненных
	// этот список нужен для менеджеров: отображаение инцидентов и крошек
	if (subordinatesData === undefined) {
		if (isShopUser) {
			setSubordinatesData(null);
		} else if (!store.isSubordinatesRequesting) {
			loadSubordinateItems();
		}
		return renderLoading();
	}

	if (isLoading) {
		return renderLoading();
	}

	const managerId: NumberOrNo = getIdFromHash(managerHashId);
	const shopIds: number[] = isShopUser
		? shops.map(s => s.id)
		: [getIdFromHash(shopHashId)].filter(Boolean);
	const incidentTypeId: NumberOrNo = getIdFromHash(incidentTypeHashId);
	const incidentId: NumberOrNo = getIdFromHash(incidentHashId);

	// определяем title, ссылку назад и крошки
	const { title, subTitle } = calculateTitle();
	const backLinkTo = calculateBackLinkTo();
	const breadCrumbs = calculateBreadCrumbs();

	const curPageInfo = PageInfo.parseFromString(location.search || get(UIncidentsSP_PAGE_INFO, '') || '');
	curPageInfo.pageIndex = 0;
	curPageInfo.pageSize = 200;
	curPageInfo.orderColumn = 'createdAt';
	curPageInfo.orderDirection = 'desc';

	const filters = incidentId ? undefined : getFilters();

	// иначе, если загружаем страницу типа инцидента
	if (incidentTypeId || incidentId) {
		const shopId = shopIds[0]; // FIXME может быть несколько магазинов
		if (incidentTypeData === undefined || savedIncidentTypePath !== `${shopId}/${incidentTypeId}`) {
			if (!store.isIncidentTypeDataRequesting) {
				loadIncidentTypeGoodList(shopId!, incidentTypeId!);
			}
			if (!store.isIncidentTypeDataQuietRequesting) {
				return renderLoading();
			}
		}
		if (incidentId) {
			const incidentData = incidentTypeData!.incidents.find(i => i.id === incidentId);
			if (!incidentData) {
				// информация об инциденте не найдена
				return render(<PageNotFound />);
			} else {
				// рисуем страницу типа инцидента
				return render(<IncidentView data={incidentData} onSaveSuccess={onIncidentSaved} />);
			}
		} else {
			if (!incidentTypeData) {
				// информация о типе инцидента не найдена
				return render(<PageNotFound />);
			} else {
				// рисуем страницу типа инцидента
				return render(<IncidentTypeView isShopUser={isShopUser}
				                                data={incidentTypeData} />);
			}
		}
	}

	const isFilterSelected = filters?.some(i => i.clearable);

	// для менеджеров всегда грузим статы даже если сейчас в магазине
	if (!isShopUser) {
		if (managerData === undefined || curPageInfo.toQueryString() !== store.checkManagerQueryString) {
			if (!store.isManagerDataRequesting) {
				loadManagerStatistics();
			}
		}
	}

	// выбран магазин или текущий пользователь это ДМ
	if (shopIds.length || isShopUser) {
		const shopId = shopIds[0]; // FIXME может быть несколько магазинов
		if (shopData === undefined || savedShopId !== shopId || curPageInfo.toQueryString() !== store.checkShopQueryString) {
			if (!store.isShopDataRequesting) {
				loadShopIncidentTypeList(shopId!);
			}
			return renderLoading();
		} else if (!shopData?.stats?.length) {
			// список пустой
			return render(<ListIsEmpty filterSelectedInfo={isFilterSelected}
			                           error={shopData?.error || undefined} />);
		} else {
			// рисуем страницу списка инцидента для магазина
			return render(<ShopIncidentTypeListView isShopUser={isShopUser}
			                                        shopId={shopId!}
			                                        data={shopData.stats} />);
		}
	}

	// иначе обрабатываем данные как страницу менеджера
	if (managerData === undefined || curPageInfo.toQueryString() !== store.checkManagerQueryString) {
		return renderLoading();
	} else if (!managerData?.stats?.users?.length && !managerData?.stats?.shops?.length) {
		// список пустой
		return render(<ListIsEmpty filterSelectedInfo={isFilterSelected}
		                           error={managerData?.error || undefined} />);
	} else {
		// ищем уровень менеджера для рендера списка подчиненных
		const root = findManagerTree(managerData.stats);
		if (!root) {
			// список пустой
			return render(<ListIsEmpty filterSelectedInfo={isFilterSelected}
			                           error={managerData?.error || undefined} />);
		}
		// рисуем страницу менеджера
		return render(<ManagerIncidentListView data={root} />);
	}

	// вызывается когда инцидент обновляется
	function onIncidentSaved() {
		// TODO данные по инцидентам долго загружаюся (около 8 секунд)
		//  сбрасываем все данные верхнего уровня, которые быстро подгружаются
		setManagerData(undefined);
		setShopData(undefined);
		const incident = incidentTypeData!.incidents.find(i => i.id === incidentId);
		if (incident) {
			// TODO подменяем статус на фронте, чтобы пользователь не ждал
			incident.state = 'PROCESSING';
			incidentTypeData!.incidents.sort((a, b) =>
				a.state < b.state ? 1 : a.state > b.state ? -1 : 0);
		}
		const shopId = shopIds[0]; // FIXME может быть несколько магазинов
		loadIncidentTypeGoodList(shopId!, incidentTypeId!, true);
		history.push(calculateBackLinkTo() || `/incidents`);
	}

	// вычисляем заголовок для страницы
	function calculateTitle(): { title: string, subTitle?: string } {
		const shop = shops?.[0]; // FIXME может быть несколько магазинов
		const shopId = shopIds[0]; // FIXME может быть несколько магазинов
		if (isShopUser) {
			if (incidentId) {
				return { title: shop.city, subTitle: shop.address };
			} else {
				return { title: 'Инциденты' };
			}
		} else {
			if (shopId) {
				const shop = subordinatesData?.shopMap.get(shopId);
				if (shop) {
					return { title: shop.city, subTitle: shop.address };
				} else {
					return { title: 'Инциденты' };
				}
			} else {
				if (managerId) {
					const manager = subordinatesData?.userMap.get(managerId);
					return {
						title: manager?.fullName || 'xx1',
						subTitle: manager?.shopManagerRole?.shortTitle || getRoleName(manager?.role),
					};
				} else {
					return { title: 'Инциденты' };
				}
			}
		}
	}

	// вычисляем ссылку для перехода назад
	function calculateBackLinkTo(): string | undefined {
		if (isShopUser) {
			if (incidentId) {
				const itid = btoa(String(incidentTypeData?.incidentType.id));
				return `/incidents/type/${itid}`;
			} else {
				return incidentTypeId ? '/incidents' : undefined;
			}
		} else {
			const shopId = shopIds[0]; // FIXME может быть несколько магазинов
			if (incidentId) {
				const shop = shopId ? subordinatesData?.shopMap.get(shopId) : undefined;
				return shop ? `/incidents/shop/${btoa(String(shop.id))}/type/${btoa(String(incidentTypeId))}` : '<shop>';
			} else if (shopId) {
				const shop = subordinatesData?.shopMap.get(shopId);
				const manId = shop?.upfId || shop?.tmId || shop?.dfId;
				return incidentTypeId ? `/incidents/shop/${btoa(shopId.toString())}`
					: manId ? `/incidents/${btoa(manId.toString())}` :
						'/incidents';
			} else if (managerId) {
				const manager = subordinatesData?.userMap.get(managerId);
				const parent = manager?.parent;
				return parent ? `/incidents/${btoa(parent.id.toString())}` : '/incidents';
			} else {
				return undefined;
			}
		}
	}

	// вычисляем хлебные крошки
	function calculateBreadCrumbs(): UiContainerContentBreadCrumbs | undefined {
		if (incidentId) {
			return undefined;
		}
		const shopId = shopIds[0]; // FIXME может быть несколько магазинов
		if (!managerId && !shopId && !incidentTypeId) return undefined;
		if (isShopUser && !incidentTypeId) return undefined;
		const res: UiContainerContentBreadCrumbs = [];
		let manager = managerId ? subordinatesData?.userMap.get(managerId) : undefined;
		if (isShopUser) {
			if (incidentTypeData?.incidentType) {
				res.push({ title: 'Магазин', linkTo: '/incidents' });
				res.push({ title: incidentTypeData?.incidentType.name });
			}
		} else {
			if (shopId) {
				const shop = subordinatesData?.shopMap.get(shopId);
				const isid = btoa(String(shopId));
				res.unshift({
					title: shop?.address || '<address>',
					linkTo: shop ? `/incidents/shop/${isid}` : undefined,
				});
				manager = shop?.supervisorUser;
				if (incidentTypeId && incidentTypeData?.incidentType) {
					const itid = btoa(String(incidentTypeData.incidentType.id));
					res.push({
						title: incidentTypeData.incidentType.name,
						linkTo: `/incidents/shop/${isid}/type/${itid}`,
					});
				}
			}
			while (manager?.id) {
				res.unshift({
					title: `${manager.shopManagerRole?.shortTitle || getRoleName(manager.role)} ${manager.shortName}`,
					linkTo: manager.id !== managerId ? `/incidents/${btoa(manager.id.toString())}` : undefined,
				});
				manager = manager.parent;
			}
			res.unshift({
				title: `Все магазины`,
				linkTo: `/incidents`,
			});
		}
		return res;
	}

	function findManagerTree(userTree: UserTree): UserTree {
		if (!managerId || userTree.user?.id === managerId) return userTree;
		let found;
		userTree.users?.some((i: UserTree) => {
			if (found) return true;
			found = findManagerTree(i);
		});
		return found;
	}

	// метод следит за тем, чтобы все части textParts были найдены хотя бы раз, иначе вернет false
	// в положительном кейсе вернет массив [минимальная позиция в слове, номер слова, длина строки поиска]
	function searchPartsInTexts(textParts, strings): [number, number, number] | false {
		strings = strings.map(s => (s || '').trim().toLowerCase()).filter(s => !!s);
		if (!strings.length) return false;
		let min: [number, number, number] | false = false;
		for (const part of textParts) {
			const k = strings
				.map((s, index) => [s.indexOf(part), index, part.length])
				.filter(i => i[0] >= 0)
				// ищем миниальное вхождение с позицией 0 или выше
				.reduce((v, i) => v === false || i[0] < v[0] ? i : v, false);
			if (k === false) return false;
			if (min === false || k[0] < min[0] || (k[0] === min[0] && k[1] < min[1])) min = k;
		}
		return min;
	}

	// поиск подчиненных из строки поиска
	function searchSubordinates(text: string) {
		const searchParts = String(text || '')
			.replace(/[^\w А-Яа-яёË]+/ig, '')
			.split(' ')
			.map(s => s.trim().toLowerCase())
			.filter(s => !!s && s.length >= 2); // не будем искать короткие слова
		const { shopMap, userMap } = subordinatesData!;
		const managers = Array.from(userMap.values())
			.filter((u: any) => {
				if (!u?.stats?.all) return false;
				// TODO xxx: return [USER_ROLE_DF, USER_ROLE_TM, USER_ROLE_UPF].includes(u.role);
				return false;
			})
			.map((user: any) => {
				// сложная логика рассчета баллов:
				// чем ближе найденная подстрока к началу, тем выше балл
				// совпадение в фамилии стоит = 3000, в имени = 200, в отчестве = 100
				// чсло выбрано большим, чтобы балл не ушел в минус
				const findScore = searchPartsInTexts(searchParts,
					[user.lastName, user.firstName, user.middleName]);
				if (findScore === false) return undefined;
				const score = 300 - findScore[1] * 100 - findScore[0] + findScore[2];
				const percent = user.stats.completed / user.stats.all * 100;
				const statClass = percent < 30 ? 'red' : percent < 80 ? 'orange' : 'green';
				return {
					score,
					text: (
						<span>
							{user.shopManagerRole?.shortTitle || getRoleName(user.role)} {user.fullName}
							{(isDev || isTestServer) ? (
								<span className={`stats stats-${statClass}`}>
									{Math.floor(percent)}%
									(выполнено {user.stats.completed} из {user.stats.all})
								</span>
							) : null}
						</span>
					),
					fullName: user.fullName,
					linkTo: `/incidents/${btoa(user.id.toString())}`,
				};
			})
			.filter(i => !!i)
			// ранжируем по релеванстности поиска
			.sort((a: any, b: any) => a.score !== b.score ? b.score - a.score
				: a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0);
		const shops = Array.from(shopMap.values())
			.filter((shop: any) => !!shop?.stats?.all)
			.map((shop: any) => {
				// сложная логика рассчета баллов:
				// чем ближе найденная подстрока к началу, тем выше балл
				// совпадение в адресе стоит = 1000, в городе = 500
				// чсло выбрано большим, чтобы балл не ушел в минус
				const findScore = searchPartsInTexts(searchParts,
					[shop.address, shop.city]);
				if (findScore === false) return undefined;
				const score = 1000 - findScore[1] * 500 - findScore[0] + findScore[2];
				const percent = shop.stats.completed / shop.stats.all * 100;
				const statClass = percent < 30 ? 'red' : percent < 80 ? 'orange' : 'green';
				return {
					score,
					text: (
						<span>
						{shop.city}, {shop.address}
							{(isDev || isTestServer) ? (
								<span className={`stats stats-${statClass}`}>
									{Math.floor(percent)}%
									(выполнено {shop.stats.completed} из {shop.stats.all})
								</span>
							) : null}
					</span>
					),
					linkTo: `/incidents/shop/${btoa(shop.id.toString())}`,
				};
			})
			.filter(i => !!i)
			// ранжируем по релеванстности поиска
			.sort((a: any, b: any) => a.score !== b.score ? b.score - a.score
				: a.text > b.text ? 1 : b.text > a.text ? -1 : 0);
		const searchedContent1: SearchWizardContentItem[] = [];
		if (managers.length) {
			searchedContent1.push({
				title: 'СОТРУДНИКИ',
				items: managers.map((i: any) => ({
					text: i.text,
					linkTo: i.linkTo,
				})),
				hasMore: managers.length > 50,
			});
		}
		if (shops.length) {
			searchedContent1.push({
				title: 'МАГАЗИНЫ',
				items: shops.map((i: any) => ({
					text: i.text,
					linkTo: i.linkTo,
				})),
				hasMore: shops.length > 50,
			});
		}
		setSearchedContent(searchedContent1);
	}

	function onSearchChanged(event) {
		const value = event.target.value.trim();
		store.searchString = value;
		if (value.length >= 3) {
			searchSubordinates(value);
		} else {
			setSearchedContent(null);
		}
	}

	function onSearchClear() {
		store.searchString = '';
		setSearchedContent(null);
	}

	// рендерит страницу
	function render(children: ReactNode) {
		return <UserContentWrapper
			title={title}
			subTitle={subTitle}
			isPreview={!!incidentId}
			backLinkTo={backLinkTo}
			breadCrumbs={breadCrumbs}
			searchOptions={isShopUser ? undefined : {
				searchString: store.searchString,
				onSearchChanged,
				onSearchClear,
				isLoading: false,
				content: searchedContent || null,
			}}
			filterOptions={filters}
		>
			{children}
		</UserContentWrapper>;
	}

	function renderLoading() {
		return <UserContentWrapper title="Инциденты">
			<Spinner onpage />
		</UserContentWrapper>;
	}

	function loadSubordinateItems() {
		store.isSubordinatesRequesting = true;
		loadSubordinateShops().then(data => {
			store.isSubordinatesRequesting = false;
			setSubordinatesData(fillSubordinateMap(data));
		});
	}

	// обновление списка инцидентов по типу инцидента
	// quiet отвечает за фоновое обновление
	function loadIncidentTypeGoodList(shopId: number, incidentTypeId: number, quiet?: boolean) {
		if (!store.isIncidentTypeDataRequesting) {
			store.isIncidentTypeDataRequesting = true;
			store.isIncidentTypeDataQuietRequesting = !!quiet;
			store.checkIncidentTypeQueryString = curPageInfo.toQueryString();
			set(UIncidentsSP_PAGE_INFO, store.checkIncidentTypeQueryString);
			fetcher.getGoodListByShopIdAndIncidentTypeId(shopId, incidentTypeId, curPageInfo)
				.then(data => {
					store.isIncidentTypeDataRequesting = false;
					setIncidentTypeData(fillIncidentTypeRenderData(data));
					setSavedIncidentTypePath(`${shopId}/${incidentTypeId}`);
				});
		}
	}

	function loadShopIncidentTypeList(shopId: number, tryCount: number = 1) {
		store.isShopDataRequesting = true;
		store.checkShopQueryString = curPageInfo.toQueryString();
		set(UIncidentsSP_PAGE_INFO, store.checkShopQueryString);
		fetcher.getIncidentTypeListByShopId(shopId, curPageInfo).then(data => {
			setLoading(true);
			if (!data?.length && tryCount <= 2) {
				// FIXME допускаем, что куб мог еще не собраться, поэтому предпримем еще одну попытку загрузки
				console.log(`Попытка #${tryCount}. Пришел пустой куб. Пробуем еще раз загрузить.`);
				setTimeout(() => {
					loadShopIncidentTypeList(shopId, tryCount + 1);
				}, tryCount * 1000);
			} else {
				store.isShopDataRequesting = false;
				setShopData(fillShopRenderData(data));
				setSavedShopId(shopId);
				setLoading(false);
			}
		});
	}

	function loadManagerStatistics(tryCount: number = 1) {
		store.isManagerDataRequesting = true;
		store.checkManagerQueryString = curPageInfo.toQueryString();
		set(UIncidentsSP_PAGE_INFO, store.checkManagerQueryString);
		fetcher.getCurrentManagerStats(curPageInfo).then(data => {
			if (!data.users?.length && !data.shops?.length && tryCount <= 3) {
				// FIXME допускаем, что куб мог еще не собраться, поэтому предпримем еще одну попытку загрузки
				console.log(`Попытка #${tryCount}. Пришел пустой куб. Пробуем еще раз загрузить.`);
				setTimeout(() => {
					loadManagerStatistics(tryCount + 1);
				}, tryCount * 1000);
			} else {
				store.isManagerDataRequesting = false;
				const managerData = fillManagerStatisticsData(subordinatesData!, data);
				setManagerData(managerData);
			}
		});
	}

	function getFilters() {
		const selectedValues = curPageInfo.customParams?.statuses || FILTER_ALL;
		return [{
			title: 'СТАТУС',
			options: [
				{ text: 'Все', value: FILTER_ALL },
				...Object.keys(IncidentStateEnum).map(key => ({
					text: IncidentStateTitle[key],
					value: key,
				})),
			],
			selected: selectedValues,
			multiselect: true,
			clearable: selectedValues !== FILTER_ALL,
			onChange: value => value !== selectedValues ? onFilterChange(value) : undefined,
		}];
	}

	function onFilterChange(value: string) {
		if (!value) value = FILTER_ALL;
		// в фильтре используется мультиселект, поэтому проверяем на all в конце
		const values: string[] = value ? value.split(',') : [];
		const k = values.indexOf(FILTER_ALL);
		if (k === values.length - 1) {
			value = FILTER_ALL;
		} else if (k >= 0) {
			values.splice(k, 1);
			value = values.join(',');
		}
		curPageInfo.update({ statuses: value === FILTER_ALL ? undefined : value });
		history.push(`${location.pathname}?${curPageInfo.toQueryString()}`);
		if (incidentTypeId) {
			// Прокидывание фильтров в крошки
			const shopId = shopIds[0];
			loadIncidentTypeGoodList(shopId, incidentTypeId);
		}
	}
}

type IncidentTypeRenderJson = {
	data: {
		id: number
		shopId: number
		incidentTypeId: number
		goodId: number
		state: string
		inputData: string
		report: string | null
		isReported: boolean
	}[]
	meta: {
		goodMap: {
			[key: string]: {
				id: number
				externalId: string
				name: string
			}
		}
		incidentTypeMap: {
			[key: string]: {
				id: number
				data: string
			}
		}
	}
};

function fillIncidentTypeRenderData(json: IncidentTypeRenderJson): IncidentTypeRenderData {
	const { data, meta: { goodMap, incidentTypeMap } = {} } = json;
	if (!incidentTypeMap || !goodMap) {
		return {
			incidentType: undefined,
			incidents: [],
		};
	}
	const incidentType = Object.values(incidentTypeMap)[0];
	return {
		incidentType,
		incidents: data
			.map(i => ({
				id: i.id,
				shopId: i.shopId,
				incidentTypeId: i.incidentTypeId,
				incidentType: JSON.parse(incidentTypeMap[String(i.incidentTypeId)].data),
				goodExternalId: goodMap[String(i.goodId)].externalId,
				goodName: goodMap[String(i.goodId)].name,
				state: i.state,
				inputData: JSON.parse(i.inputData),
				report: JSON.parse(i.report || 'null'),
			}))
			.sort((a, b) => a.state > b.state ? -1 : a.state < b.state ? 1 : 0),
	};
}

// строим список инцидентов для магазина
function fillShopRenderData(data: IncidentTypeStatJson[]): IncidentTypeData {
	// если выбрано несколько фильтров, то сервер может прислать несколько строк с одинаковым id
	// в этом случае будем суммировать статы
	const statsMap = new Map<number, IncidentTypeStat>();
	try {
		data.forEach(it => {
			const stats = statsMap.get(it.incidentTypeId) || {
				key: Math.random().toString(36),
				id: it.incidentTypeId,
				name: it.incidentTypeName,
				completed: 0,
				all: 0,
			};
			stats.completed += it.completed;
			stats.all += it.allCount;
			statsMap.set(it.incidentTypeId, stats);
		});
		return {
			stats: Array.from(statsMap.values())
				.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0),
		};
	} catch (e: any) {
		console.error(e);
		return { error: e instanceof Error ? e.message : String(e) };
	}
}

// строим статистику по дереву подчиненных
function fillManagerStatisticsData(subordinates: Subordinates, data: any): ManagerStatisticsData {
	// если указано несколько фильтров, сервер может прислать несколько объектов с одинаковым userId и shopId
	const userStatMap = data.users.reduce((map, item) => {
		const stats = map.get(item.userId) || { userId: item.userId, completed: 0, allCount: 0 };
		stats.completed += item.completed;
		stats.allCount += item.allCount;
		return map.set(item.userId, stats);
	}, new Map());
	const shopStatMap = data.shops.reduce((map, item) => {
		const stats = map.get(item.shopId) || { shopId: item.shopId, completed: 0, allCount: 0 };
		stats.completed += item.completed;
		stats.allCount += item.allCount;
		return map.set(item.shopId, stats);
	}, new Map());
	try {
		const { subordinateUsers, subordinateShops } = subordinates;
		return {
			stats: {
				all: 0,
				completed: 0,
				users: subordinateUsers?.map(calcUser).filter(i => i.all),
				shops: subordinateShops?.map(calcShop).filter(i => i.all),
			},
		};
	} catch (e: any) {
		console.error(e);
		return { error: e instanceof Error ? e.message : String(e) };
	}

	// заполняет статистикой менеджеров
	function calcUser(user: SubordinateUser & { stats?: any }): UserTree {
		const userStat = userStatMap.get(user.id);
		const all = userStat?.allCount || 0;
		const completed = userStat?.completed || 0;
		user.stats = { all, completed };
		return {
			all, completed, user,
			users: user.subordinateUsers?.map(calcUser).filter(i => i.all),
			shops: user.subordinateShops?.map(calcShop).filter(i => i.all),
		};
	}

	// заполняет статистикой магазины
	function calcShop(shop: SubordinateShop & { stats?: any }): UserTreeShop {
		const shopStat = shopStatMap.get(shop.id);
		const all = shopStat?.allCount || 0;
		const completed = shopStat?.completed || 0;
		shop.stats = { all, completed };
		return { all, completed, shop };
	}
}
