import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { UserRoles, PageInfo } from 'common-lib';
import { getInfoMessagesList } from '~api/infoMessagesApi';
import { DataTableWithPageInfo, Spinner } from '~components';
import { fillTableData, TABLE_HEADERS } from '../infoMessagesAttrs';

export default function AdminInfoMessagesTablePage(props) {
	const [{
		tableData,
		checkQueryString,
		isTableDataRequesting,
	}, setTableData] = useState<any>({});
	const meConfig = useSelector((state: any) => state.me.currentUser);
	const history = useHistory();
	const location = useLocation();

	const pageInfo = PageInfo.parseFromString(location.search, { order: 'id:desc' });

	if (!tableData || pageInfo.toQueryString() !== checkQueryString) {
		if (!isTableDataRequesting) {
			setTableData({ tableData, checkQueryString, isTableDataRequesting: true });
			getInfoMessagesList(pageInfo).then(json => {
				const newTableData = fillTableData(json);
				setTableData({
					tableData: newTableData,
					checkQueryString: pageInfo.toQueryString(),
				});
			})
		}
	}

	return (
		<>
			{tableData ? (
				<DataTableWithPageInfo
					searchable
					icon="envelope"
					title="Информационные сообщения"
					headers={TABLE_HEADERS}
					rows={tableData.rows}
					pageInfo={tableData.pageInfo}
					onClickItem={itemId => history.push(`${location.pathname}/${itemId}`)}
					onAddItem={meConfig.role !== UserRoles.ADMIN ? null : () => history.push(`${location.pathname}/+`)}
					onChangePageOptions={options => {
						const pageInfo1 = PageInfo.parseFromString(location.search)
						pageInfo1.update(options)
						history.push(`${location.pathname}?${pageInfo1.toQueryString()}`)
					}}
				/>
			) : null}
			{isTableDataRequesting ? <Spinner /> : null}
		</>
	)
}
